.bazis-menu {
    --multiple: 4;
    --margin-bottom: 8px;
    --padding-top: calc(var(--bazis-multiple-px) * var(--multiple));
    --padding-bottom: calc(var(--bazis-multiple-px) * var(--multiple));
    --padding-start: calc(var(--bazis-multiple-px) * var(--multiple));
    --padding-end: calc(var(--bazis-multiple-px) * var(--multiple));
    --border-radius: 0;
    --background-hover: var(--bazis-background-accent);
    --color-hover: var(--color);
    --list-style: none;
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 20px;

    bazis-icon {
        flex-shrink: 0;
        margin-right: calc(var(--bazis-multiple-px) * var(--multiple));
    }

    bazis-button {
        --color: var(--bazis-text-color);
        color: var(--color);

        &:not(.bazis-item) {
            --padding-start: calc(var(--bazis-multiple-px) * var(--multiple));
            --padding-end: calc(var(--bazis-multiple-px) * var(--multiple));
            --padding-top: calc(var(--bazis-multiple-px) * var(--multiple));
            --padding-bottom: calc(var(--bazis-multiple-px) * var(--multiple));
        }
    }

    // subtype
    &_rounded {
        --multiple: 2;
        --border-radius: var(--bazis-border-radius);
        --margin-bottom-list: 32px;

        .bazis-item {
            --font-size: 14px;
            --font-weight: 500;
            display: flex;
            align-items: center;

            &:hover,
            &.active {
                bazis-icon {
                    background: transparent;
                }
            }
        }

        bazis-icon {
            padding: 10px;
            background: var(--bazis-background-medium);
            margin-right: calc(var(--bazis-multiple-px) * 3);
        }
    }
}

.sl-menu_profile {
    @media (max-width: 1024px) {
        --background-hover: transparent;
        --color-hover: var(--bazis-color-primary);
        --line-height: 12px;

        display: flex;
        justify-content: space-between;
        margin: 0;

        .bazis-item {
            --font-size: 10px;
            width: 100%;
            flex-direction: column;
            padding: 0;
            margin: 0;
            text-align: center;
        }

        bazis-icon {
            margin: 0 0 var(--bazis-margin-1x);
            padding: 0;
            background: transparent;
            border-radius: 0;
        }
    }
}
