bazis-chip {
    --border-radius: 16px;
    --background: rgba(var(--bazis-text-color-secondary-rgb, 0, 0, 0), 0.2);
    --color: var(--bazis-text-color-secondary);

    --height: 24px;
    --padding-top: calc((var(--height) - var(--line-height)) / 2 );
    --padding-bottom: calc((var(--height) - var(--line-height)) / 2 );

    --margin-start: 8px;
    --margin-end: 8px;
    --margin-start-slotted: 8px;
    --margin-end-slotted: 8px;

    --font-size: 12px;
    --font-weight: 500;
}

.bazis-chip_small {
    --border-radius: 12px;
    --height: 20px;
}

.sl-status-save {
    --margin-start: 0;
    --margin-end: 0;
    --margin-bottom: 0;
    --font-size: 13px;
    --font-weight: 400;
    --color: var(--bazis-text-color);

    bazis-icon {
        margin-right: 8px;
    }
}

bazis-chip.bazis-status{
    --margin-end: 0;
    --margin-start: 0;
    --margin-bottom: 0;
    --margin-top: 0;

    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

bazis-chip.bazis-status:not(.bazis-chip_small) {
    --font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;

    &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: var(--color);
        margin-right: var(--margin-start-slotted);
    }

    span {
        line-height: var(--height);

        @media only screen and ( -webkit-min-device-pixel-ratio: 2 ){
            line-height: calc(var(--height) + 2px);
        }
    }
}

bazis-chip.bazis-status.bazis-color {
    &::before {
        display: inline-block;
        background: var(--bazis-color-base);
    }
}

bazis-chip.bazis-status.bazis-chip_nodecor {
    &::before {
        display: none;
    }
}

bazis-chip.bazis-status.bazis-color.bazis-color-info {
    --color: var(--bazis-text-color-secondary);
    --background: rgba(var(--bazis-text-color-secondary-rgb, 0, 0, 0), 0.2);
    color: var(--color);

    &:not(.bazis-chip_clear){
        background: var(--background);
    }

    &::before {
        background: var(--color);
    }
}
