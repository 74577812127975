.bazis-percentage-bar {
    @include props-height;
    --background: initial;

    @include height;
    background: var(--background);

    &__part {
        --opacity: 1;
        --min-width: 0;
        display: block;
        float: left;
        height: var(--height);
        opacity: var(--opacity);
        min-width: var(--min-width);

        &.bazis-color {
            background: var(--bazis-color-base);
        }

        //types
        &_full {

        }
    }

    // types {
    &_mini {
        @extend .bazis-percentage-bar;
    }
}
