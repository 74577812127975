/** Application BAZIS CSS Variables **/
:root {
    /**
    **
    ** COLORS
    **
    **/

    /*** Theme colors ***/

    /** primary **/
    --bazis-color-primary: #005A9B;
    --bazis-color-primary-rgb: 0,90,155;
    --bazis-color-primary-contrast: #ffffff;
    --bazis-color-primary-contrast-rgb: 255,255,255;
    --bazis-color-primary-shade: #004f88;
    --bazis-color-primary-shade-rgb: 0,79,136;
    --bazis-color-primary-tint: #1a6ba5;

    /** secondary **/
    --bazis-color-secondary: #43B1F2;
    --bazis-color-secondary-rgb: 67,177,242;
    --bazis-color-secondary-contrast: #ffffff;
    --bazis-color-secondary-contrast-rgb: 255,255,255;
    --bazis-color-secondary-shade: #3b9cd5;
    --bazis-color-secondary-shade-rgb: 59, 156, 213;
    --bazis-color-secondary-tint: #56b9f3;

    /** tertiary **/
    --bazis-color-tertiary: #817AF9;
    --bazis-color-tertiary-rgb: 128,122,249;
    --bazis-color-tertiary-contrast: #ffffff;
    --bazis-color-tertiary-contrast-rgb: 255,255,255;
    --bazis-color-tertiary-shade: #726bdb;
    --bazis-color-tertiary-shade-rgb: 114, 107, 219;
    --bazis-color-tertiary-tint: #8e87fa;

    /** action **/
    --bazis-color-action: #3189DA;
    --bazis-color-action-rgb: 49, 137, 218;
    --bazis-color-action-contrast: #ffffff;
    --bazis-color-action-contrast-rgb: 255,255,255;
    --bazis-color-action-shade: #3189DA;
    --bazis-color-action-shade-rgb: 45, 76, 189;
    --bazis-color-action-tint: #4695de;
    --bazis-color-action-dark: #164982;

    /** info **/
    --bazis-color-info: #C4C4C4;
    --bazis-color-info-rgb: 196,196,196;
    --bazis-color-info-contrast: #ffffff;
    --bazis-color-info-contrast-rgb: 255,255,255;
    --bazis-color-info-shade: #acacac;
    --bazis-color-info-shade-rgb: 172, 172, 172;
    --bazis-color-info-tint: #f3f3f3;

    /** success **/
    --bazis-color-success: #78C649;
    --bazis-color-success-rgb: 120,198,73;
    --bazis-color-success-contrast: #ffffff;
    --bazis-color-success-contrast-rgb: 255,255,255;
    --bazis-color-success-shade: #6aae40;
    --bazis-color-success-shade-rgb: 106, 174, 64;
    --bazis-color-success-tint: #86cc5b;
    // --bazis-color-success-tint: #E4F4DB;

    /** warning **/
    --bazis-color-warning: #F6BF36;
    --bazis-color-warning-rgb: 246,191,54;
    --bazis-color-warning-contrast: #ffffff;
    --bazis-color-warning-contrast-rgb: 255,255,255;
    --bazis-color-warning-shade: #d8a830;
    --bazis-color-warning-shade-rgb: 216, 168, 48;
    --bazis-color-warning-tint: #f7c54a;
    // --bazis-color-warning-tint: #FDF2D7;

    /** danger **/
    --bazis-color-danger: #ED1A34;
    --bazis-color-danger-rgb: 237,26,52;
    --bazis-color-danger-contrast: #ffffff;
    --bazis-color-danger-contrast-rgb: 255,255,255;
    --bazis-color-danger-shade: #d1172e;
    --bazis-color-danger-shade-rgb: 209, 23, 46;
    --bazis-color-danger-tint: #ef3148;
    // --bazis-color-danger-tint: #FBD1D6;

    /*** Stepped Colors ***/
    --bazis-color-white: #ffffff;
    --bazis-color-white-rgb: 255, 255, 255;

    --bazis-color-step-50: #f2f2f2;
    --bazis-color-step-100: #e6e6e6;
    --bazis-color-step-150: #d9d9d9;
    --bazis-color-step-200: #cccccc;
    --bazis-color-step-250: #bfbfbf;
    --bazis-color-step-300: #b3b3b3;
    --bazis-color-step-350: #a6a6a6;
    --bazis-color-step-400: #999999;
    --bazis-color-step-450: #8c8c8c;
    --bazis-color-step-500: #808080;
    --bazis-color-step-550: #737373;
    --bazis-color-step-600: #666666;
    --bazis-color-step-650: #595959;
    --bazis-color-step-700: #4d4d4d;
    --bazis-color-step-750: #404040;
    --bazis-color-step-800: #333333;
    --bazis-color-step-850: #262626;
    --bazis-color-step-900: #191919;
    --bazis-color-step-950: #0d0d0d;

    --bazis-color-black: #000000;
    --bazis-color-black-rgb: 0, 0, 0;

    /*** Base colors ***/

    /** text colors **/
    --bazis-text-color: #111214;
    --bazis-text-color-rgb: 17, 18, 20;
    --bazis-text-color-secondary: #9096A2;
    --bazis-text-color-secondary-rgb: 144, 150, 162;
    --bazis-text-color-contrast: var(--bazis-color-white);
    --bazis-text-color-contrast-rgb: var(--bazis-color-white-rgb);

    /* link color */
    --bazis-link-color: #1B68AE;
    --bazis-link-color-rgb: 27,104,174;
    --bazis-link-color-dark: #004064;

    --sl-footer-link-color: #FFFFFF;

    /** placeholder color  **/
    --bazis-placeholder-color: #BCC1CC;
    --bazis-placeholder-color-rgb: 188, 193, 204;

    /** background light **/
    --bazis-background: var(--bazis-color-white);
    --bazis-background-rgb: var(--bazis-color-white-rgb);

    /** background medium **/
    --bazis-background-medium: #F7F8F9;
    --bazis-background-medium-rgb: 247, 248, 249;

    /** background dark **/
    --bazis-background-dark: #F1F1F3;
    --bazis-background-dark-rgb: 241, 241, 243;

    /** background accent **/
    --bazis-background-accent: #E3EEF4;
    --bazis-background-accent-rgb: 227,238,244;

    /** background info secondary **/
    --bazis-background-info-secondary: #FEF9EB;
    --bazis-background-info-secondary-rgb: 254, 249, 235;

    /** background header **/
    --bazis-header-background: #005A9B;
    --bazis-header-background-rgb: 0,90,155;

    /** background footer **/
    --bazis-footer-background: #004476;
    --bazis-footer-background-rgb: 0,68,118;

    /** background stepper active **/
    --bazis-stepper-background: #3189DA;
    --bazis-stepper-background-rgb: 49,137,218;

    /** border light  **/
    --bazis-border-color: #E8E9EB;
    --bazis-border-color-rgb: 232, 233, 235;

    /** border dark  **/
    --bazis-border-color-dark: #C4C7CC;
    --bazis-border-color-dark-rgb: 196, 199, 204;

    /** shadow **/
    --bazis-shadow-color-rgb: 239, 240, 242;

    /**
    **
    ** APPLICATION VARIABLES
    **
    **/

    /** Font family of the app **/
    --bazis-font-family: 'Gordita', sans-serif;
    --bazis-font-size: 13px;
    --bazis-line-height: 20px;

    /** H1 **/
    --bazis-font-size-h1: 45px;
    // --bazis-font-size-h1-major: ;
    // --bazis-font-size-h1-minor: ;
    --bazis-line-height-h1: 52px;
    // --bazis-line-height-h1-major: ;
    // --bazis-line-height-h1-minor: ;
    --bazis-h1-margin: 0 0 32px;
    // --bazis-h1-margin-major: ;
    // --bazis-h1-margin-minor: ;

    /** H2 **/
    --bazis-font-size-h2: 36px;
    // --bazis-font-size-h2-major: ;
    // --bazis-font-size-h2-minor: ;
    --bazis-line-height-h2: 44px;
    // --bazis-line-height-h2-major: ;
    // --bazis-line-height-h2-minor: ;
    // --bazis-h2-margin: ;
    // --bazis-h2-margin-major: ;
    // --bazis-h2-margin-minor: ;

    /** H3 **/
    --bazis-font-size-h3: 28px;
    // --bazis-font-size-h3-major: ;
    // --bazis-font-size-h3-minor: ;
    --bazis-line-height-h3: 36px;
    // --bazis-line-height-h3-major: ;
    // --bazis-line-height-h3-minor: ;
    --bazis-h3-margin: 32px 0 24px;
    // --bazis-h3-margin-major: ;
    // --bazis-h3-margin-minor: ;

    /** H4 **/
    --bazis-font-size-h4: 22px;
    // --bazis-font-size-h4-major: ;
    --bazis-font-size-h4-minor: 22px;
    --bazis-line-height-h4: 32px;
    // --bazis-line-height-h4-major: ;
    --bazis-line-height-h4-minor: 28px;
    // --bazis-h4-margin: ;
    // --bazis-h4-margin-major: ;
    // --bazis-h4-margin-minor: ;

    /** H5 **/
    --bazis-font-size-h5: 18px;
    // --bazis-font-size-h5-major: ;
    // --bazis-font-size-h5-minor: ;
    --bazis-line-height-h5: 24px;
    // --bazis-line-height-h5-major: ;
    // --bazis-line-height-h5-minor: ;
    // --bazis-h5-margin: ;
    // --bazis-h5-margin-major: ;
    // --bazis-h5-margin-minor: ;

    /** H6 **/
    --bazis-font-size-h6: 16px;
    --bazis-font-size-h6-major: 16px;
    --bazis-font-size-h6-minor: 14px; // возможно это используется только однажды.
    --bazis-line-height-h6: 24px;
    --bazis-line-height-h6-major: 32px;
    --bazis-line-height-h6-minor: 16px; // возможно это используется только однажды.
    --bazis-h6-margin: 0 0 12px;
    --bazis-h6-margin-major: var(--bazis-h6-margin);
    --bazis-h6-margin-minor: var(--bazis-h6-margin);

    /** p **/
    --bazis-font-size-p: 13px; // textSize=s
    --bazis-font-size-p-major: 14px; // textSize=m
    --bazis-font-size-p-minor: 12px; // textSize=xs
    --bazis-line-height-p: 20px; // textSize=s
    --bazis-line-height-p-major: 20px; // textSize=m
    --bazis-line-height-p-minor: 16px; // textSize=xs
    --bazis-p-margin: var(--bazis-multiple-px) 0;
    --bazis-p-margin-major: var(--bazis-p-margin);
    --bazis-p-margin-minor: var(--bazis-p-margin);

    /** small **/
    --bazis-font-size-small: 11px;
    --bazis-line-height-small: 12px;

    /** Базовое число пикселей, используемое для кратности определяемых размеров **/
    --bazis-multiple-px: 4px;

    /** Коэффиницет кратности по умолчанию **/
    --bazis-coef: 3;

    /** Adjust the safe area inset top of the app **/
    // --bazis-safe-area-top: ;

    /** Adjust the safe area inset right of the app **/
    // --bazis-safe-area-right: ;

    /** Adjust the safe area inset bottom of the app **/
    // --bazis-safe-area-bottom: ;

    /** Adjust the safe area inset left of the app **/
    // --bazis-safe-area-left: ;

    /** Adjust the margin **/
    --bazis-margin: calc(var(--bazis-multiple-px) * var(--bazis-coef)); // 12px

    --bazis-margin-1x: var(--bazis-multiple-px); // 4px
    --bazis-margin-2x: calc(var(--bazis-multiple-px) * 2); // 8px
    --bazis-margin-3x: calc(var(--bazis-multiple-px) * 3); // 12px
    --bazis-margin-4x: calc(var(--bazis-multiple-px) * 4); // 16px
    --bazis-margin-5x: calc(var(--bazis-multiple-px) * 5); // 20px
    --bazis-margin-6x: calc(var(--bazis-multiple-px) * 6); // 24px
    --bazis-margin-7x: calc(var(--bazis-multiple-px) * 7); // 28px
    --bazis-margin-8x: calc(var(--bazis-multiple-px) * 8); // 32px

    /** Adjust the padding **/
    --bazis-padding: calc(var(--bazis-multiple-px) * var(--bazis-coef));

    --bazis-padding-1x: var(--bazis-multiple-px); // 4px
    --bazis-padding-2x: calc(var(--bazis-multiple-px) * 2); // 8px
    --bazis-padding-3x: calc(var(--bazis-multiple-px) * 3); // 12px
    --bazis-padding-4x: calc(var(--bazis-multiple-px) * 4); // 16px
    --bazis-padding-5x: calc(var(--bazis-multiple-px) * 5); // 20px
    --bazis-padding-6x: calc(var(--bazis-multiple-px) * 6); // 24px
    --bazis-padding-7x: calc(var(--bazis-multiple-px) * 7); // 28px
    --bazis-padding-8x: calc(var(--bazis-multiple-px) * 8); // 32px

    /** Adjust the box shadow **/
    // --bazis-box-shadow: ;

    /** Adjust the border-radius **/
    --bazis-border-radius: 4px;

    /**
    **
    ** GRID VARIABLES
    **
    **/
    /** Number of columns in the grid **/
    // --bazis-grid-columns: ;
    /** Padding of the grid **/
    --bazis-grid-padding: 0;
    /** Padding of the grid for xs breakpoints **/
    // --bazis-grid-padding-xs: ;
    /** Padding of the grid for sm breakpoints **/
    // --bazis-grid-padding-sm: ;
    /** Padding of the grid for md breakpoints **/
    // --bazis-grid-padding-md: ;
    /** Padding of the grid for lg breakpoints **/
    // --bazis-grid-padding-lg: ;
    /** Padding of the grid for xl breakpoints **/
    // --bazis-grid-padding-xl: ;
    /** Padding of the grid columns for xs breakpoints **/
    // --bazis-grid-column-padding-xs: ;
    /** Padding of the grid columns for sm breakpoints **/
    // --bazis-grid-column-padding-sm: ;
    /** Padding of the grid columns for md breakpoints **/
    // --bazis-grid-column-padding-md: ;
    /** Padding of the grid columns for lg breakpoints **/
    // --bazis-grid-column-padding-lg: ;
    /** Padding of the grid columns for xl breakpoints **/
    // --bazis-grid-column-padding-xl: ;
}

/**
**
** SW SCSS Variables
**
**/
 