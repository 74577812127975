.bazis-list {
    /*
    * @prop --background-list: фон списка
    *
    * @prop --border-radius-list: радиус скругления списка
    * @prop --border-width-list: толщина границы списка
    * @prop --border-style-list: стиль границы списка
    * @prop --border-color-list: цвет границы списка
    *
    * @prop --padding-top-list: внутренний верхний отступ списка
    * @prop --padding-end-list: внутренний конечный отступ списка
    * @prop --padding-bottom-list: внутренний нижний отступ списка
    * @prop --padding-start-list: внутренний начальный отступ списка
    *
    * @prop --margin-top-list: внешний верхний отступ списка
    * @prop --margin-end-list: внешний конечный отступ списка
    * @prop --margin-bottom-list: внешний нижний отступ списка
    * @prop --margin-start-list: внешний начальный отступ списка
    * @prop --box-shadow: тень списка
    *
    * @prop --max-height: максимальная высота списка
    *
    * @prop --background: фон итема
    * @prop --background-hover: фон итема при наведении
    * @prop --background-selected: фон выбранного итема
    *
    * @prop --border-radius: радиус скругления итема
    * @prop --border-width: толщина границы итема
    * @prop --border-style: стиль границы итема
    * @prop --border-color: цвет границы итема
    *
    * @prop --padding-top: внутренний верхний отступ итема
    * @prop --padding-end: внутренний конечный отступ итема
    * @prop --padding-bottom: внутренний нижний отступ итема
    * @prop --padding-start: внутренний начальный отступ итема
    *
    * @prop --margin-top: внешний верхний отступ итема
    * @prop --margin-end: внешний конечный отступ итема
    * @prop --margin-bottom: внешний нижний отступ итема
    * @prop --margin-start: внешний начальный отступ итема
    *
    * @prop --min-height: минимальная высота итема
    *
    * @prop --list-style: стиль списка
    *
    * @prop --color: цвет текста
    * @prop --color-hover: цвет текста при наведении
    * @prop --color-selected: цвет текста выбранного пункта
    * @prop --font-size: размер шрифта
    * @prop --font-weight: жирность шрифта
    * @prop --line-height: межстрочный интервал для текста
    *
    * @prop --size-icon: размер иконки
    */
    --min-height: initial;
    --border-width: initial;
    --border-color: initial;
    --border-style: initial;
    --padding-start: initial;
    --padding-end: initial;
    --padding-top: initial;
    --padding-bottom: initial;
    --margin-start: initial;
    --margin-end: initial;
    --margin-top: initial;
    --margin-bottom: initial;
    --background: initial;
    --background-hover: var(--background);
    --background-selected: var(--background);
    --color: inherit;
    --color-hover: var(--color);
    --color-selected: var(--color);
    --font-size: inherit;
    --font-weight: inherit;
    --line-height: inherit;

    --max-height: initial;
    --padding-start-list: 0;
    --padding-end-list: 0;
    --padding-top-list: 0;
    --padding-bottom-list: 0;
    --margin-start-list: 0;
    --margin-end-list: 0;
    --margin-top-list: 0;
    --margin-bottom-list: 0;
    --background-list: initial;
    --border-radius-list: initial;
    --border-width-list: initial;
    --border-color-list: initial;
    --border-style-list: initial;
    --box-shadow: none;

    max-height: var(--max-height);
    border-radius: var(--border-radius-list);
    border-width: var(--border-width-list);
    border-style: var(--border-style-list);
    border-color: var(--border-color-list);
    padding: var(--padding-top-list) var(--padding-end-list) var(--padding-bottom-list)
        var(--padding-start-list);
    margin: var(--margin-top-list) var(--margin-end-list) var(--margin-bottom-list)
        var(--margin-start-list);
    background: var(--background-list);
    box-shadow: var(--box-shadow);
    list-style: var(--list-style);

    &.bh-no-margin {
        margin: 0;
    }

    .bazis-item {
        display: list-item;
        min-height: var(--min-height);
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        margin-left: var(--margin-start);
        margin-right: var(--margin-end);
        margin-top: var(--margin-top);
        margin-bottom: var(--margin-bottom);
        border-radius: var(--border-radius);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        background: var(--background);
        list-style: var(--list-style);
        font-size: var(--font-size);
        font-weight: var(--font-weight);
        line-height: var(--line-height);
        color: var(--color);

        &:hover,
        &.active {
            background: var(--background-hover);
            color: var(--color-hover);
        }

        &--selected,
        &--selected:hover,
        &:active,
        &:active:hover {
            background: var(--background-selected);
            color: var(--color-selected);
        }
    }

    //types
    &_h {
        display: flex;
        align-items: center;
    }

    &_short-info {
        display: flex;
        align-items: center;

        .bazis-item {
            padding-left: 0;

            &::after {
                content: '';
                display: inline-block;
                margin-left: var(--padding-end);
                margin-bottom: 2px;
                width: 2px;
                height: 2px;
                background: var(--color);
                border-radius: 50%;
            }

            &:last-child {
                --padding-start: 0;

                &::after {
                    display: none;
                }
            }

            a {
                text-decoration: none;
            }

            > *:not(bazis-tooltip) {
                display: inline-block;
            }
        }

        @media (max-width: 576px) {
            .bazis-item {
                padding-left: 0;

                &::before {
                    display: none;
                }

                // TODO: can be delete start
                &::after {
                    content: '';
                    display: inline-block;
                    margin-left: var(--padding-end);
                    margin-bottom: 2px;
                    width: 2px;
                    height: 2px;
                    background: var(--color);
                    border-radius: 50%;
                }

                &:last-child {
                    --padding-start: 0;

                    &::after {
                        display: none;
                    }
                }
                // TODO: can be delete finish
            }
        }
    }

    &_vline {
        > .bazis-item {
            display: inline-flex;

            &::before {
                content: '';
                border-left: 1px solid var(--border-color);
                padding-right: var(--padding-start);
            }

            &:first-child {
                --padding-start: 0;

                &::before {
                    display: none;
                }
            }

            &_noline {
                &::before {
                    display: none;
                }
            }
        }
    }

    &_select {
        width: 100%;
        overflow: auto;

        .bazis-item {
            --height: initial;
            --border-radius: 0;
            position: relative;
            min-height: var(--height, 36px);
            cursor: pointer;
        }

        a.bazis-item,
        bazis-link.bazis-item,
        button.bazis-item {
            display: block;
            width: 100%;
            line-height: var(--line-height);
            text-decoration: none;
            letter-spacing: 0;
            text-align: left;
        }
    }

    &_control-dd {
        @extend .bazis-list_select;
        position: absolute;
        top: var(--position-top, var(--height, 0)); // = field height
        left: 0;
        z-index: 160;

        &-wrapper {
            position: relative;
            padding-bottom: var(--padding-bottom);
        }

        .bazis-item_group {
            --background-hover: var(--background);
            --background-selected: var(--background);

            --color: var(--bazis-text-color-secondary);
            --color-hover: var(--color);
            --color-selected: var(--color);
            user-select: none;
        }

        .bazis-dropdown & {
            bazis-button.bazis-item {
                text-align: left;

                &::part(inner) {
                    justify-content: flex-start;
                }
            }
        }
    }

    &_gallery {
        overflow: hidden;
        position: relative;
        flex-wrap: nowrap;
        transition: margin-left 0.5s ease-in;
    }

    &_between {
        > .bazis-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .bazis-block__content_end & {
            --margin-top-list: 26px;
        }

        // subtype
        &_dot-leaders {
            @extend .bazis-list_between;
            > .bazis-item {
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    bottom: 4px;
                    opacity: 0.5;
                    border-bottom: 1px dotted var(--color-dot);
                }

                & > * {
                    position: relative;
                    z-index: 1;
                    background: var(--bazis-background);

                    &:first-child {
                        padding-right: 8px;
                    }
                    &:last-child {
                        padding-left: 8px;
                    }
                }
            }
        }
    }

    &_underline {
        --padding-top: calc(var(--bazis-multiple-px) * 2);
        --padding-bottom: calc(var(--bazis-multiple-px) * 2);
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
    }

    &_overline {
        --padding-top: calc(var(--bazis-multiple-px) * 8);
        --padding-bottom: calc(var(--bazis-multiple-px) * 8);
        --border-width: 1px 0 0 0;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
    }

    &_history {
        --width-bullet: 9px;

        > .bazis-item {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 5px;
                width: var(--width-bullet);
                height: var(--width-bullet);
                border: 1px solid var(--bazis-placeholder-color);
                border-radius: 50%;
            }

            &::after {
                content: '';
                position: absolute;
                left: 5px;
                top: 16px;
                bottom: calc(var(--margin-bottom) * -1 - 5px);
                width: 1px;
                background: var(--bazis-placeholder-color);
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }

        &.bazis-color {
            > .bazis-item {
                &::before {
                    border-color: var(--bazis-color-base);
                    background: var(--bazis-color-base);
                }
                &::after {
                    background: var(--bazis-color-base);
                }
            }
        }
    }
}
