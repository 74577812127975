.bazis-contact {
    @include props-margin;
    @include props-font;
    @include margin;
    @include font;

    display: block;
    color: inherit;

    a {
        color: currentColor;
    }
}
