.sl-interval-control {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    background: var(--bazis-color-white);
    box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
    border-radius: var(--bazis-border-radius);
    border: 1px solid var(--bazis-background-medium);

    bazis-timepicker,
    bazis-datepicker {
        --box-shadow: none;
        --border-width: 0;
    }

    app-timeinterval & {
        --width: 418px;
        --padding-top: 26px;

        bazis-timepicker {
            --padding-top: 0;
        }

        p {
            margin: 0 16px 8px;
        }
    }
}
