bazis-totop {
    --bottom: 84px;
    --margin-end: 40px;

    &::part(button) {
        --height-large: 56px;
        --border-radius: 50%;
        width: 56px;
        margin-top: -28px;
    }

    &::part(icon) {
        margin-right: -4px;
        margin-left: -4px;
        font-size: 24px;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0){
        &::part(icon) {
            margin-left: unset;
            margin-right: unset;
            margin-inline-start: -4px;
            margin-inline-end: -4px;
        }
    }
}

@media (max-width: 576px) {
    bazis-totop {
        --bottom: 44px;
        --margin-end: var(--bazis-margin-4x);

        &::part(button) {
            --height-large: 44px;
            width: 44px;
            margin-top: -22px;
        }
    }
}
