.sl-list-page {
    &__head {
        background: var(--bazis-background-medium);

        .bazis-breadcrumbs {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__title {
        display: flex;
        align-items: center;

        @media (min-width: 1024.98px) {
            h1 {
                margin: 0;
            }
        }
    }
    &__filters {
        background: var(--bazis-background-medium);
    }
    &__body {
        background: transparent;
    }

    @media (max-width: 1024px) {
        &__title {
            align-items: flex-start;
            flex-direction: column;
        }
    }
}
