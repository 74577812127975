.bazis-accordion_underline {
    &::part(body) {
        line-height: 20px;
    }
}

.bazis-accordion_clear {
    &::part(body) {
        line-height: 20px;
    }
}

// все типы аккордионов с переопределяющими переменными компонента
.sl-accordion {
    &_form-child {
        --margin-top: 40px;
        --margin-bottom: 40px;

        &::part(header) {
            --padding-top: 0;
            --padding-bottom: 12px;
            --border-width: 0 0 1px;
        }

        &::part(body) {
            --padding-bottom: 0;
        }

        &.bazis-accordion--opened {
            &::part(header) {
                --margin-bottom: 0;
                --border-width: 0;
            }
        }
    }

    &_profile {
        --open-close-color: var(--bazis-color-action);

        &::part(header) {
            --background: transparent;
            --border-width: 0;
            --padding-bottom: 0;
            --padding-top: calc(var(--bazis-multiple-px) * 2);
            --padding-start: calc(var(--bazis-multiple-px) * 2);
            --padding-end: 0;

            font-size: 18px;
            line-height: 24px;

            bazis-toolbar {
                bazis-button bazis-icon {
                    font-size: 24px;
                }
            }
        }

        &::part(body) {
            --padding-top: calc(var(--bazis-padding) * 2);
            --padding-start: 56px;

            .sl-form + bazis-toolbar {
                --padding-top: 32px;
            }
        }

        bazis-icon[slot='icon'] {
            --icon-l-size: 24px;
            margin-right: calc(var(--bazis-margin) * 2);
        }

        section hr {
            --margin-top: 0;
        }
    }
}
