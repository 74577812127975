.bazis-file-inline {

    .bazis-list {
        .bazis-item {
            bazis-link {
                --line-height: 20px;
            }

            @media only screen and ( -webkit-max-device-pixel-ratio: 1 ){
                bazis-icon {
                    position: relative;
                    top: -2px;
                }
            }
        }
    }

    .bazis-list .bazis-item:last-child & {
        margin-bottom: calc(var(--bazis-multiple-px) * 10);
    }

    & + bazis-button {
        margin-top: -8px;

        bazis-icon {
            margin-left: -8px;
            margin-right: -8px;
            font-size: 20px;
            color: var(--bazis-text-color-secondary);
        }
        @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
            bazis-icon {
                margin-left: unset;
                margin-right: unset;
                -webkit-margin-start:  -8px;
                margin-inline-start:  -8px;
                -webkit-margin-end: -8px;
                margin-inline-end: -8px;
            }
        }
    }
}

aside bazis-file-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bazis-file-inline {
        & + bazis-button {
            margin-top: 0;
            flex-shrink: 0;
            align-self: flex-start;
        }
    }
}
