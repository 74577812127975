.bazis-accordion_doc {
    .iframe_desktop {
        display: block;

        .dt-desktop & {
            display: block;
        }
        .dt-mobile & {
            display: none;
        }
    }
    .iframe_mobile {
        display: none;

        .dt-desktop & {
            display: none;
        }
        .dt-mobile & {
            display: block;
        }
    }
    @media screen and (max-device-width: 666px) {
        .iframe_desktop {
            display: none;

            .dt-desktop & {
                display: block;
            }
            .dt-mobile & {
                display: none;
            }
        }
        .iframe_mobile {
            display: block;

            .dt-desktop & {
                display: none;
            }
            .dt-mobile & {
                display: block;
            }
        }
    }
}
