.bh-line-elements {
    --margin-end: calc(var(--bazis-multiple-px) * 4);

    > * {
        margin-right: var(--margin-end);

        &:last-child {
            margin-right: 0;
        }
    }
}
