.rs-paginator {
   &__load-more {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: calc(var(--bazis-multiple-px) * 8);

        bazis-button {
            --color: var(--bazis-color-action);
            font-size: 18px;
            letter-spacing: 0;
            
            bazis-icon[slot=start] {
                margin-right: 12px;
                font-size: 24px;

                @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
                    margin-right: unset;
                    margin-inline-end: 12px;
                }
            }
        }
   } 
}