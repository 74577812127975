.bazis-btn-group {
    --border-radius: var(--bazis-border-radius);

    &:not(.bazis-btn-group_h) {
        overflow-x: auto;
    }

    .bazis-btn-group__item > bazis-button.bazis-btn_solid {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);

        --background: var(--bazis-background-medium);
        --color: var(--bazis-text-color);

        --background-activated: var(--bazis-color-action);
        --color-activated: var(--bazis-text-color-contrast);

        --background-hover: var(--bazis-color-action-shade);
        --color-hover: var(--bazis-text-color-contrast);

        --background-focused: var(--background-hover);
        --color-focused: var(--color-hover);

        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        bazis-icon {
            color: var(--bazis-text-color-secondary);
        }

        &.bazis-btn--active::part(native) {
            border-color: var(--background-activated);
        }

        &:hover::part(native),
        &:focus-visible::part(native) {
            border-color: var(--background-hover);
        }

        // объединение правил 42 - 50, приводит к неработоспособности каждой из групп, ховер - точно перестает работать.
        &.bazis-btn--active,
        &:hover {
            bazis-icon {
                color: var(--bazis-text-color-contrast);
            }
        }

        &::part(native):active,
        &:focus-visible::part(native) {
            bazis-icon {
                color: var(--bazis-text-color-contrast);
            }
        }
    }

    &--disabled .bazis-btn-group__item > bazis-button.bazis-btn_solid,
    & .bazis-btn-group__item > bazis-button.bazis-btn_solid.bazis-btn--disabled {
        &::part(native) {
            --background-disabled: var(--bazis-background-dark);
            --color-disabled: var(--bazis-text-color-secondary);
        }

        &.bazis-btn--active::part(native) {
            --background-disabled: var(--bazis-text-color-secondary);
            --color-disabled: var(--bazis-text-color-contrast);
            background: var(--background-disabled);
            border-color: var(--background-disabled);
            color: var(--color-disabled);
        }
    }

    .bazis-btn-group__item > bazis-button.bazis-btn_clear {
        --background: var(--bazis-color-info-tint);
        --color: var(--bazis-placeholder-color);

        --color-activated: var(--bazis-color-action);

        --background-hover: var(--background);
        --color-hover: var(--bazis-color-action);

        --background-focused: var(--background);
        --color-focused: var(--color);

        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        bazis-icon {
            color: currentColor;
        }

        &::part(native)::after {
            border-right: 1px solid var(--bazis-border-color);
        }
    }

    + .bazis-control__note {
        margin-top: calc(var(--bazis-multiple-px) * 3);
    }

    &_h {
        .bazis-btn-group__item > bazis-button.bazis-btn_outline {
            &.bazis-btn--active {
                --background-activated: var(--background);
                --color-activated: var(--bazis-color-primary);
                --color-hover: var(--color-activated);
                background: var(--background-activated);
                border-color: var(--background-activated);
                color: var(--color-activated);
            }
        }
    }

    @media (max-width: 576px) {
        // компонент формы
        app-input-options-button & bazis-button {
            --padding-start: var(--bazis-margin-2x);
            --padding-end: var(--bazis-margin-2x);
        }
    }
}
