.sl-card-mobile {
    @include props-block-model;
    @include props-margin;
    @include props-colors;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    --background-header: initial;
    --background-body: initial;

    @include block-model;
    @include margin;
    @include colors;

    &__header,
    &__body {
        @include props-block-model;
        @include props-margin;

        @include block-model;
        @include margin;
    }

    &__header {
        background: var(--background-header);

        bazis-toolbar {
            [slot='start'] {
                flex-direction: column;
                padding-top: 6px;
            }
        }

        bazis-status {
            display: inline-block;
            margin-top: var(--bazis-margin-2x);
        }
    }
    &__body {
        background: var(--background-body);
    }

    &_hoverable {
        &:hover {
            background: var(--background-hover);
            border-color: var(--border-color-hover);
            box-shadow: var(--box-shadow-hover);
        }
    }
}

// implemented
.sl-card-mobile {
    --padding-top: var(--bazis-margin-2x);
    --border-width: 1px 1px 0;
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-color-hover: var(--bazis-border-color-dark);
    --background: var(--bazis-background-medium);
    --background-header: var(--bazis-color-white);
    --background-body: var(--bazis-color-white);

    &__header {
        --padding-top: 4px;
        --padding-bottom: 12px;
        --padding-start: 12px;
        --padding-end: 4px;
        --border-width: 1px 0 0;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
    }
    &__body {
        --padding-top: 16px;
        --padding-bottom: 16px;
        --padding-start: 12px;
        --padding-end: 12px;
    }

    &__selection {
        border-bottom: 1px solid var(--border-color);
        padding: 10px 4px 10px 12px;
        margin: 0 -4px 0 -12px;
    }

    &--expanded {
        .sl-card-mobile__header {
            --border-width: 1px 0;
        }
    }

    .sl-contacts-group & {
        --padding-top: 0;
        border-top: 0;
    }
}
