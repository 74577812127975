.sl-select-date-control {
    width: 275px;

    .bazis-dropdown__body {
        --top: calc(var(--height) + 3px);
        --right: 0;
        --left: auto;
        --width: 330px;
        --padding-start: calc(var(--bazis-multiple-px) * 3);
        --padding-end: calc(var(--bazis-multiple-px) * 3);
        --padding-top: calc(var(--bazis-multiple-px) * 3);
        --padding-bottom: calc(var(--bazis-multiple-px) * 3);
        --background: var(--bazis-background);
        --border-width: 1px;
        --border-color: var(--bazis-background-medium);

        .sl-grid_form  > bazis-row > bazis-col {
            --bazis-grid-column-padding-start: calc(var(--bazis-multiple-px) * 1.5);
            --bazis-grid-column-padding-end: calc(var(--bazis-multiple-px) * 1.5);

            &:first-child {
                --bazis-grid-column-padding-start: 0;
            }
            &:last-child {
                --bazis-grid-column-padding-end: 0;
            }
        }
    }
}
