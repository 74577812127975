bazis-step {
    --color-muted: var(--bazis-text-color-secondary);

    --sign-margin: 16px;

    --sign-background: var(--bazis-color-action);
    --sign-background-muted: transparent;

    --sign-border-radius: 50%;
    --sign-border-style: solid;
    --sign-border-width: 1px;

    --sign-color: var(--bazis-color-white);
    --sign-color-muted: var(--bazis-text-color-secondary);
    --sign-size: 20px;
    --sign-icon-size: 12px;
    --sign-font-size: 11px;

    .bazis-step__label_another {
        --font-weight: 500;
    }
}
