.bazis-switcher {
    --color: var(--bazis-text-color);
    --color-disabled: var(--bazis-text-color-secondary);

    &__label {
        --font-weight: 700;
        --font-size: 16px;
        --line-height: 24px;
        margin-left: 8px;

        th & {
            --font-weight: 500;
            --font-size: 13px;
            --line-height: 20px;
            --color: var(--bazis-text-color-secondary);
        }
    }
}
