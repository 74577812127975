.bazis-figure {
    /**
    * @prop --background: фон кнопки или подложки картинки
    * @prop --border-radius: радиус скругления
    * @prop --border-width: толщина границы
    * @prop --border-style: стиль границы
    * @prop --border-color: цвет границы
    *
    * @prop --height: высота
    * @prop --width: ширина
    * @prop --min-width: минимальная ширина
    *
    * @prop --font-size: размер шрифта
    * @prop --font-weight: жирность шрифта
    */
    --background: initial;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --height: initial;
    --width: initial;
    --min-width: initial;
    --font-size: inherit;
    --font-weight: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    height: var(--height);
    width: var(--width);
    background: var(--background);
    border-radius: var(--border-radius);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);

    img, video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: var(--border-radius);
    }

    &_btn {
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        outline: none;
        font-size: var(--font-size);
        font-weight: var(--font-weight);

        bazis-icon {
            --bazis-icon-s-size: 16px;
            margin-bottom: calc(var(--bazis-multiple-px) * 2);
        }

        &.bazis-color {
            border-color: var(--bazis-color-base);
            color: var(--bazis-color-base);
        }
    }

    &_long {
        width: auto;
    }
}

.bazis-figcaption {
}
