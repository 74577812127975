.bazis-modal {
    --background: var(--bazis-color-white);
    --border-radius: var(--bazis-border-radius);
    --box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), .4);
    --width: 100%;
    --max-height: calc(100vh - var(--bazis-multiple-px) * 20);
    --max-width: 976px;
    --min-height: 62.5vh;

    --top-close: calc(var(--bazis-multiple-px) * 5);
    --right-close: calc(var(--bazis-multiple-px) * 5);

    --padding-start-header: calc(var(--bazis-multiple-px) * 10);
    --padding-end-header: 60px;
    --padding-top-header: calc(var(--bazis-multiple-px) * 5);
    --padding-bottom-header: var(--bazis-padding-6x);

    --padding-start-content: calc(var(--bazis-multiple-px) * 10);
    --padding-end-content: calc(var(--bazis-multiple-px) * 10);
    --padding-top-content: calc(var(--bazis-multiple-px) * 8);
    --padding-bottom-content: calc(var(--bazis-multiple-px) * 8);

    --padding-start-footer: calc(var(--bazis-multiple-px) * 10);
    --padding-end-footer: calc(var(--bazis-multiple-px) * 10);
    --padding-top-footer: var(--bazis-padding-6x);
    --padding-bottom-footer: calc(var(--bazis-padding-6x) * 2);

    + .bazis-backdrop {
        --backdrop-opacity: 0.6;
        --opacity: var(--backdrop-opacity);
    }

    &,
    &-wrap {
        &_big-content {
            .bazis-modal__header {
                position: relative;
                height: 76px;

                h1, h3 {
                    padding-top: 0;
                    font-size: 28px;
                    line-height: 36px;
                }
            }
        }
    }

    &__header {
        h1 {
            --bazis-h1-margin: 0;
        }
        h3 {
            --bazis-h3-margin: 0;
        }
    }

    &__content {
        section {
            margin-bottom: 40px;
        }
    }

    &__footer {
        border-top: 1px solid var(--bazis-border-color);
        box-shadow: 0px -15px 15px -10px rgba(var(--bazis-shadow-color-rgb), .4);
    }

    .bazis-alert__wrapper {
        --box-shadow: none;
    }

    // types
    &.map {
        --height: 640px;

        h4 {
            margin-right: 16px;
        }

        .bazis-map-wrapper {
            --height: calc(80% - var(--margin-bottom));

            .bazis-map {
                border-radius: 0 0 var(--border-radius) var(--border-radius);
            }
        }
    }

    &.gallery {
        --background: transparent;
        --top-close: calc(var(--bazis-multiple-px) * 10);
        --right-close: calc(var(--bazis-multiple-px) * 10);

        + .bazis-backdrop {
            --backdrop-opacity: 0.9;
            --opacity: var(--backdrop-opacity);
        }

        .bazis-loader_inline {
            --height: auto;
        }

        #plotCanvas {
            width: 100%;
            height: 100%;
        }

        .plot-container {
            width: 100%;
            height: 100%;
            overflow: hidden;

            > .svg-container {
                margin: auto;
            }
        }
    }

    &.video {
        --background: transparent;
        --top-close: 20px;
        --right-close: 78px;

        + .bazis-backdrop {
            --backdrop-opacity: 0.9;
            --opacity: var(--backdrop-opacity);
        }

        .bazis-modal__content {
            --padding-start-content: 0;
            --padding-end-content: 0;
            --padding-top-content: 0;
            --padding-bottom-content: 0;
        }
    }

    @media (max-width: 992px) {
        &:not(.fullscreen, .sl-modal_mobile-bottom, .dummy) {
            --width: calc(100% - 72px);
            --min-height: auto;
            --max-height: 100%;

            margin: 40px 36px;
            bottom: auto;

            .bazis-modal-wrap {
                max-height: var(--max-height);
            }
        }

        .bazis-modal__footer {
            .bazis-btn_clear {
                margin-left: -16px;
                margin-right: -16px;
            }
        }
    }


    @media (max-width: 576px) {
        &:not(.sl-modal_mobile-bottom, .dummy) {
            --min-height: 100vh;
            --width: 100%;
            --border-radius: 0;
            --box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);

            --top-close: 9px;
            --right-close: auto;
            --left-close: 12px;

            --padding-start-header: 60px;
            --padding-end-header: calc(var(--bazis-multiple-px) * 5);
            --padding-top-header: calc(var(--bazis-multiple-px) * 4);
            --padding-bottom-header: calc(var(--bazis-multiple-px) * 4);

            --padding-start-content: calc(var(--bazis-multiple-px) * 5);
            --padding-end-content: calc(var(--bazis-multiple-px) * 5);

            --padding-start-footer: calc(var(--bazis-multiple-px) * 5);
            --padding-end-footer: calc(var(--bazis-multiple-px) * 5);
            --padding-top-footer: calc(var(--bazis-multiple-px) * 4);
            --padding-bottom-footer: calc(var(--bazis-multiple-px) * 4);

            margin: 0;
            bottom: 0;
        }

        .bazis-modal__header {
            h1 {
                padding-top: 0;
                font-size: 18px;
                line-height: 24px;
            }
            h4 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .bazis-modal__footer {
            box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
        }

        app-signature {
            .bazis-modal__footer {
                bazis-button {
                    display: block;
                }
            }
        }

        &.video {
            --top-close: 8px;
            --right-close: 8px;
            --left-close: auto;
            padding: 0;

            .bazis-modal__close {
                z-index: 100;
            }

            app-video-modal {
                display: flex;
                flex-direction: column;
                height: 100vh;
                justify-content: center;
            }

            .bazis-modal__content {
                position: relative;
                height: 0;
                padding-top: 56.25%; /* 16:9 */
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        &.map {
            --padding-top-content: 0;
            --padding-bottom-content: 0;
            --padding-start-content: 0;
            --padding-end-content: 0;

            --top-close: 8px;
            --right-close: 8px;
            --left-close: auto;

            .sl-desktop-content {
                display: none;
            }

            .bazis-map-wrapper {
                --height: 100vh;
            }

            .sl-mobile-content {
                .bazis-control {
                    --height: 34px;
                    --control-width: calc(100% - 8px);
                    margin-right: 8px;
                }

                .bazis-list_short-info > .bazis-item {
                    --font-size: 16px;
                    --line-height: 24px;
                    --font-weight: 500;
                    --color: var(--bazis-text-color);
                }
            }
        }
    }
}

// types
.sl-modal {

    &_small {
        --max-height: 580px;
        --max-width: 780px;

        --padding-top-header: 21px;
        --padding-bottom-header: calc(var(--bazis-multiple-px) * 4);
        --padding-top-content: calc(var(--bazis-multiple-px) * 4);

        &.map {
            --max-height: 490px;
            --max-width: 976px;

            --padding-top-content: calc(var(--bazis-multiple-px) * 8);
        }
    }

    &_xs {
        --min-height: 401px;
        --max-height: 430px;
        --max-width: 664px;
        --padding-bottom-header: 0;
        --padding-top-content: 0;
        --padding-bottom-content: 0;
    }

    &_mobile-bottom {
        --width: calc(100% - 16px);
        --min-height: 0;

        --padding-start-header: calc(var(--bazis-multiple-px) * 5);
        --padding-end-header: calc(var(--bazis-multiple-px) * 5);
        --padding-top-header: calc(var(--bazis-multiple-px) * 5);
        --padding-bottom-header: calc(var(--bazis-multiple-px) * 5);

        --padding-top-content: 0;
        --padding-bottom-content: calc(var(--bazis-multiple-px) * 5);
        --padding-start-content: calc(var(--bazis-multiple-px) * 5);
        --padding-end-content: calc(var(--bazis-multiple-px) * 5);

        --padding-start-footer: calc(var(--bazis-multiple-px) * 5);
        --padding-end-footer: calc(var(--bazis-multiple-px) * 5);
        --padding-top-footer: calc(var(--bazis-multiple-px) * 5);
        --padding-bottom-footer: calc(var(--bazis-multiple-px) * 5);
        bottom: 0;
        top: auto;
        margin: 8px;

        .bazis-list_underline {
            --border-style: dashed;
            --padding-top-list: 0;
            --padding-bottom-list: 0;

            --padding-bottom: 16px;
            --padding-top: 0;
            --margin-bottom: 16px;

            > .bazis-item {
                &:last-child {
                    border: none;
                }
            }
        }

        .bazis-control & {
            --padding-top-content: calc(var(--bazis-multiple-px) * 5);

            .bazis-control__label {
                --font-size-label: 18px;
                line-height: 24px;
                color: var(--bazis-text-color);
            }

            .bazis-radio__label::before {
                margin-left: 0;
            }

            bazis-button {
                --height: 44px;
                margin: 40px 0 20px;
            }
        }
    }

    &_role {
        --max-width: 664px;
        --max-height: 598px;
        --padding-top-content: 52px;
    }

    &_form {
        --padding-top-header: var(--bazis-padding-5x);
        --padding-bottom-header: var(--bazis-padding-6x);
        --padding-start-header: 40px;
        --padding-end-header: 40px;

        --padding-top-content: var(--bazis-padding-8x);
        --padding-bottom-content: calc(var(--bazis-padding-6x) * 2);

        h3 {
            --bazis-h3-margin: 0;
        }
    }
}
