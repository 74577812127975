.bazis-paginator {

    @media (max-width: 576px) {
        .bazis-control {
            --control-width: 64px;
        }
        .sl-paginator__total {
            line-height: 36px;
        }
    }
}
