.sl-section {
    @include props-margin;
    @include margin;

    &__content {
        @include props-padding;
        @include padding;
    }
}

.sl-section {
    --margin-top: 32px;

    &__subtitle {
        margin-bottom: 12px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        color: var(--bazis-text-color-secondary);
    }
}

.sl-header-section.bazis-block {
    --margin-top: 20px;
    --margin-bottom: 48px;
    --padding-top: 32px;
    --padding-bottom: 32px;
    --padding-start: 0;
    --padding-end: 0;

    .bazis-block__content {
        display: flex;

        &_main {
            --padding-start: 32px;
            --padding-end: 32px;

            h5 {
                margin: 0 0 16px;
            }
        }

        &_end {
            --width: 340px;
            --padding-start: 32px;
            --padding-end: 32px;
            --margin-start: 0;
        }
    }
}
