dl {
    --width-dt: initial;
    --width-dd: initial;
    --padding-end-dt: initial;
    --padding-start-dd: initial;
    --margin-bottom-dt: initial;
    --margin-bottom-dd: initial;
    --margin-top-dl: initial;
    --margin-bottom-dl: initial;

    --color-dt: inherit;
    --color-dd: inherit;

    margin-top: var(--margin-top-dl);
    margin-bottom: var(--margin-bottom-dl);

    dt {
        padding-right: var(--padding-end-dt);
        margin: 0 0 var(--margin-bottom-dt);

        color: var(--color-dt);
    }

    dd {
        margin: 0 0 var(--margin-bottom-dd);
        color: var(--color-dd);
    }

    &.bazis-dl_inline {
        clear: both;
        overflow: hidden;

        dt {
            float: left;
            clear: left;
            width: var(--width-dt);
        }

        dd {
            float: left;
            width: var(--width-dd);
            overflow: hidden;
        }
    }

    &.bazis-dl_dot-leaders {
        @extend .bazis-dl_inline;
        --color-dot: inherit;

        dt {
            display: inline-flex;
            padding-right: 0;

            & > * {
                flex-shrink: 0;
            }

            &::after {
                content: '';
                position: relative;
                width: 100%;
                top: -7px;
                opacity: 0.5;
                margin-left: 2px;
                border-bottom: 1px dotted var(--color-dot);
            }
        }
        dd {
            padding-left: var(--padding-start-dd);
        }

        &_nowrap {
            @extend .bazis-dl_dot-leaders;
            dt {
                white-space: nowrap;
            }
        }
    }

    &.bazis-dl_easy {
        dt {
        }
        dd {
        }
    }
}
