.bazis-tile {
    --padding-start: 20px;
    --padding-end: 20px;
    --height-header: 48px;

    &:not(.bazis-tile_full) {
        max-height: 282px;
    }

    &__header {
        --margin-start: -20px;
        --margin-end: -20px;
        --padding-start: 20px;
        --padding-end: 20px;
        --padding-top: 20px;
        --padding-bottom: 8px;
    }

    &__content {
        --padding-top: 8px;
        --padding-bottom: 20px;
        --padding-end: 20px;
        --margin-end: -19px;
    }

    bazis-tabs {
        width: 100%;
        border-bottom: 1px solid var(--bazis-border-color);
    }

    &--active {
        .bazis-tile__header {
            box-shadow: 0px 15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
        }
    }
}
