
bazis-timepicker {
    --width: 360px;
    --height: 176px;
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-top: 16px;
    --padding-bottom: 16px;
	--background: var(--bazis-color-white);
    --background-active: var(--bazis-color-action);
	--box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-background-medium);
    --position-bottom: 20px;


    --color: var(--bazis-text-color);
    --color-active: var(--bazis-color-white);
    --font-size: 12px;
    --font-weight: 500;
    --line-height: 16px;

    --item-height: 16px;
    --item-padding-start: 0;
    --item-padding-end: 0;
    --item-padding-top: 8px;
    --item-padding-bottom: 8px;
    --item-active-padding-start: 0;
    --item-active-padding-end: 0;
    --item-active-padding-top: 16px;
    --item-active-padding-bottom: 16px;

    --hightlight-border-radius: var(--bazis-border-radius);
    --item-muted-background: rgba(var(--bazis-color-white-rgb), 0.7);
}
