.bazis-tile {
    @include props-block-model;
    @include props-margin;
    @include props-width;
    @include props-colors;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    @include block-model;
    @include margin;
    @include width;
    @include colors;

    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-color-hover: var(--bazis-border-color-dark);
    --height-header: initial;
    min-height: 100%;
    overflow: hidden;

    &__header,
    &__content,
    &__footer {
        @include props-block-model;
        @include props-width;
        @include props-margin;

        @include block-model;
        @include width;
        @include margin;
    }

    &__header {
        position: relative;
        z-index: 1;
    }

    &__content {
        ::-webkit-scrollbar-track,
        &::-webkit-scrollbar-track {
            margin-top: 1px;
            margin-bottom: 1px;
        }

        &:not(.bazis-tile_full .bazis-tile__content) {
            max-height: calc(281px - var(--height-header));
            min-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    // state
    &--active {
    }
}
