bazis-grid.sl-grid_table {
    --bazis-grid-columns: 24;
    margin-bottom: calc(var(--bazis-multiple-px) * 10);

    > bazis-row,
    *:not(bazis-row) bazis-row {
        border-bottom: 1px solid var(--bazis-border-color);

        > bazis-col {
            --bazis-grid-column-padding-start: 12px;
            --bazis-grid-column-padding-end: 12px;
            --bazis-grid-column-padding-top: 16px;
            --bazis-grid-column-padding-bottom: 16px;

            line-height: 19px;

            &.sl-col_th {
                --bazis-grid-column-padding-top: 7px;
                --bazis-grid-column-padding-bottom: 7px;

                background: var(--bazis-background-medium);

                font-size: 13px;
                font-weight: 500;
                text-align: left;
                color: var(--bazis-text-color-secondary);

                &.sl-col_selectable {
                    --bazis-grid-column-padding-start: 52px;
                }
            }

            &.sl-col_no-padding-end {
                --bazis-grid-column-padding-end: 0;
            }

            &.sl-table__cell-btns {
                --bazis-grid-column-padding-top: 7px;
            }
        }

        &.sl-row_thead {
            border-bottom: 0;

            &.sl-th_no-padding > bazis-col,
            > bazis-col.sl-th_no-padding {
                --bazis-grid-column-padding-bottom: 0;
            }
            &.sl-th_no-padding + & > bazis-col {
                --bazis-grid-column-padding-top: 0;
            }
        }

        &.bazis-td-no-border {
            border-width: 0;
        }

        &.sl-row_tbody {
            align-items: flex-start;

            &:hover {
                background: var(--bazis-background-accent);
            }

            > bazis-col.sl-col_th {
                --bazis-grid-column-padding-top: 16px;
                --bazis-grid-column-padding-bottom: 16px;
                border-bottom: 1px solid var(--bazis-background-medium);
                text-align: center;
            }

            .sl-form & {
                align-items: flex-start;

                > bazis-col {
                    --bazis-grid-column-padding-start: 6px;
                    --bazis-grid-column-padding-end: 6px;
                    --bazis-grid-column-padding-top: 16px;
                    --bazis-grid-column-padding-bottom: 16px;

                    &:first-child {
                        --bazis-grid-column-padding-start: 8px;
                    }

                    &[size='24'] {
                        --bazis-grid-column-padding-start: 8px;
                        --bazis-grid-column-padding-end: 8px;
                    }
                }
            }

            &.sl-col-top-zero > bazis-col,
            > bazis-col.sl-col-top-zero {
                --bazis-grid-column-padding-top: 0;
            }

            &.sl-col-bottom-zero > bazis-col,
            > bazis-col.sl-col-bottom-zero {
                --bazis-grid-column-padding-bottom: 0;
            }
        }
    }

    & & {
        margin-bottom: 0;

        > bazis-row,
        > * > bazis-row {
            &:first-child {
                > bazis-col {
                    --bazis-grid-column-padding-top: 0;
                }
            }

            &:last-child {
                border-bottom: 0;

                > bazis-col {
                    --bazis-grid-column-padding-bottom: 0;
                }
            }

            &.sl-tr_no-border {
                border-bottom: 0;
            }

            > bazis-col {
                --bazis-grid-column-padding-start: 0;
                --bazis-grid-column-padding-end: 0;
            }
        }
    }
}

bazis-grid.sl-grid_table {
    .sl-dropdown_action {
        bazis-button.bazis-dropdown__trigger {
            --color-hover: var(--bazis-color-action);

            bazis-icon {
                color: currentColor;
            }
        }

        &:hover {
            bazis-button.bazis-dropdown__trigger {
                --color: var(--color-hover);
            }
        }
    }

    .bazis-dropdown {
        bazis-vehicle-number + bazis-button.bazis-dropdown__trigger {
            --background: transparent;
            --background-hover: transparent;
            position: relative;
            top: -4px;
        }
    }

    .sl-btn-expanded {
        position: relative;
        top: -9px;
    }

    > bazis-row,
    > * > bazis-row {
        > bazis-col {
            .bazis-dropdown {
                &__trigger {
                    --background: var(--bazis-color-white);
                    --color: var(--bazis-placeholder-color);
                }
                &__body {
                    --top: 36px;
                }

                &_vehicle {
                    .bazis-dropdown__trigger {
                        --color: var(--bazis-color-base);
                    }
                }
            }

            bazis-button bazis-icon {
                --bazis-icon-s-size: 16px;
                color: var(--bazis-placeholder-color);
            }

            .sl-tr-collapsed {
                cursor: pointer;

                bazis-icon {
                    font-size: 8px;
                    color: var(--bazis-text-color);
                }
            }

            bazis-status {
                display: block;
                margin-top: var(--bazis-margin-2x);
            }

            p:first-child {
                margin-top: 0;
            }
        }
    }
}
