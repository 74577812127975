.bazis-avatar {
    --width: 64px;
    --height: 64px;
    --background: #66B8F6;
    --border-radius: 50%;
    --color: var(--bazis-color-white);
    --font-size: 16px;
    --font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.25px;

    &_small {
        --font-size: 13px;
        --font-weight: 500;
    }
}


.sl-avatar_userpic {
    @extend .bazis-avatar;

    --width: 64px;
    --height: 64px;
    --background: var(--bazis-background);
    --color: var(--bazis-text-color-secondary);

    float: left;
    margin-right: 0;
    border: 1px solid var(--bazis-border-color);
}
