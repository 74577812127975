.sl-filter__additional {

    .bazis-control {

        &__range {
            .bazis-control__field {
                --padding-field-end: 0;
            }

            .bazis-control__field-group__end {
                bazis-button.bazis-btn_clear {
                    --padding-start: 10px;
                    --padding-end: 10px;
                }
            }
        }
    }
}
