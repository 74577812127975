.sl-e-sign {
    display: block;
    flex-shrink: 0;
    width: 42px;
    height: 42px;
    margin-right: 30px;
    background: var(--bazis-color-base, var(--bazis-text-color-secondary));
    border: 2px solid var(--bazis-color-contrast, var(--bazis-color-white));
    border-radius: 50%;
    box-shadow: 0 0 0 6px rgba( var(--bazis-color-base-rgb, var(--bazis-text-color-secondary-rgb)), .2 );

    text-align: center;
    line-height: 38px;

    bazis-icon {
        --bazis-icon-s-size: 16px;
        color: var(--bazis-color-contrast, var(--bazis-color-white));
    }
}
