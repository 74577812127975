.bh-block {
    display: block;
}

.bh-inline {
    display: inline;
}

.bh-inline-block {
    display: inline-block;
}

.bh-display-table {
    display: table;
}

.bh-flex {
    display: flex;
}

.bh-inline-flex {
    display: inline-flex;
}

.bh-display-grid {
    display: grid;
}

.bh-hide {
    display: none !important;
}

.bh-contents {
    display: contents;
}

// .bh-hide-up {
//     display: none !important;
// }

// .bh-hide-down {
//     display: none !important;
// }

.bh-hidden {
    visibility: hidden;
}

@media screen and (min-width: 576.98px) {
    .bh-hide-sm-up {
        display: none !important;
    }
}
@media screen and (max-width: 576px) {
    .bh-hide-sm-down {
        display: none !important;
    }
}
@media screen and (min-width: 768.98px) {
    .bh-hide-md-up {
        display: none !important;
    }
}
@media screen and (max-width: 768px) {
    .bh-hide-md-down {
        display: none !important;
    }
}
@media screen and (min-width: 992.98px) {
    .bh-hide-lg-up {
        display: none !important;
    }
}
@media screen and (max-width: 992px) {
    .bh-hide-lg-down {
        display: none !important;
    }
}
@media screen and (min-width: 1024.98px) {
    .bh-hide-xl-up {
        display: none !important;
    }
}
@media screen and (max-width: 1024px) {
    .bh-hide-xl-down {
        display: none !important;
    }
}
@media screen and (min-width: 1280.98px) {
    .bh-hide-xxl-up {
        display: none !important;
    }
}
@media screen and (max-width: 1280px) {
    .bh-hide-xxl-down {
        display: none !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 768px) {
    .bh-hide-sm-md {
        display: none !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 992px) {
    .bh-hide-sm-lg {
        display: none !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 1024px) {
    .bh-hide-sm-xl {
        display: none !important;
    }
}
@media screen and (min-width: 576.98px) and (max-width: 1280px) {
    .bh-hide-sm-xxl {
        display: none !important;
    }
}
@media screen and (min-width: 768.98px) and (max-width: 992px) {
    .bh-hide-md-lg {
        display: none !important;
    }
}
@media screen and (min-width: 768.98px) and (max-width: 1024px) {
    .bh-hide-md-xl {
        display: none !important;
    }
}
@media screen and (min-width: 768.98px) and (max-width: 1280px) {
    .bh-hide-md-xxl {
        display: none !important;
    }
}
@media screen and (min-width: 992.98px) and (max-width: 1024px) {
    .bh-hide-lg-xl {
        display: none !important;
    }
}
@media screen and (min-width: 992.98px) and (max-width: 1280px) {
    .bh-hide-lg-xxl {
        display: none !important;
    }
}
@media screen and (min-width: 1024.98px) and (max-width: 1280px) {
    .bh-hide-xl-xxl {
        display: none !important;
    }
}

@media print {
    .bh-hide-print {
        display: none !important;
    }
}

// devices
@media (min-device-width: 576.98px) {
    .bh-hide-device-sm-up {
        display: none !important;
    }
}
@media (max-device-width: 576px) {
    .bh-hide-device-sm-down {
        display: none !important;
    }
}
@media (min-device-width: 768.98px) {
    .bh-hide-device-md-up {
        display: none !important;
    }
}
@media (max-device-width: 768px) {
    .bh-hide-device-md-down {
        display: none !important;
    }
}
@media (min-device-width: 992.98px) {
    .bh-hide-device-lg-up {
        display: none !important;
    }
}
@media (max-device-width: 992px) {
    .bh-hide-device-lg-down {
        display: none !important;
    }
}
@media (min-device-width: 1024.98px) {
    .bh-hide-device-xl-up {
        display: none !important;
    }
}
@media (max-device-width: 1024px) {
    .bh-hide-device-xl-down {
        display: none !important;
    }
}
@media (min-device-width: 1280.98px) {
    .bh-hide-device-xxl-up {
        display: none !important;
    }
}
@media (max-device-width: 1280px) {
    .bh-hide-device-xxl-down {
        display: none !important;
    }
}

// media level 4 (weak support on 04.2023)
/* смартфоны, устройства с сенсорным экраном, с которым работают пальцами */
@media (hover: none) and (pointer: coarse) {
    .bh-hide-touch-device {
        display: none !important;
    }
}
/* мышь, тачпад */
@media (hover: hover) and (pointer: fine) {
    .bh-hide-cursor-device {
        display: none !important;
    }
}
/* устройства с сенсорным экраном, с которым работают стилусом */
@media (hover: none) and (pointer: fine) {
    /* ... */
}
