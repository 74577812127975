.bazis-select {
    --padding-start: 12px;
    --padding-end: 12px;
    --border-radius: var(--bazis-border-radius);
    --background: var(--bazis-color-white);

    &_secondary {
        --background: var(--bazis-color-info-tint);
        --padding-end: 0;
        color: var(--color);

        .bazis-select__trigger {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: var(--border-radius);

            bazis-icon {
                --size-icon: 20px;
                flex-shrink: 0;
                color: var(--color);

                &:first-child {
                    margin-right: 4px;
                }
            }
        }
    }

    @media (max-width: 992px) {
        &_secondary {
            --background: var(--bazis-background-medium);
        }
    }
}

.sl-select {
    &-text {
        display: inline-block;
        margin: 4px 0;

        .sl-select-text__label {
            margin-right: 8px;
            font-size: 14px;
            line-height: 20px;
        }

        .bazis-list_control-dd {
            top: 16px;
            // right: 0;
            left: 0;
            width: auto;
            min-width: 140px;

            .bazis-checkbox {
                min-width: 160px;

                &__label {
                    margin-right: 0;
                }
            }
        }
    }
}
