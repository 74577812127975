bazis-range {
    --bar-height: 7px;
    --thumb-background: var(--bazis-color-white, #ffffff);
    --thumb-width: 20px;
    --thumb-height: 20px;
    --thumb-border-radius: 50%;
    --thumb-border-width: 0;
    --thumb-border-style: solid;
    --thumb-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);

    --track-background: var(--bazis-placeholder-color, #BCC1CC);
    --track-height: var(--bar-height);
    --track-border-radius: 2px;
    --track-border-width: 0;
    --track-border-style: solid;
    --track-box-shadow: none;

    --progress-background: var(--bazis-color-primary, #00A2AD);

    --font-size: 14px;
    --font-weight: normal;
    --line-height: 20px;
}
