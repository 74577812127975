.bazis-control {
    --height: 34px;

    --padding-field-start: var(--bazis-padding);
    --padding-field-end: var(--bazis-padding);

    --background: var(--bazis-color-white);
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px;
    --border-color: var(--bazis-border-color-dark);
    --border-color-hover: var(--bazis-text-color-secondary);
    --border-color-focus: var(--bazis-color-action);
    --border-color-active: var(--bazis-color-action);
    --border-style: solid;
    --border-style-estimated: dashed;
    --color: var(--bazis-text-color);
    --font-size: 13px;
    --font-size-label: calc(var(--font-size) + 1px);

    --size-icon: 16px;

    --background-disabled: var(--bazis-background-medium);

    .sl-form & {
        --margin-bottom: var(--bazis-margin-5x);
    }

    .sl-form app-input-search > & {
        --margin-bottom: var(--bazis-margin-1x);
    }

    .sl-form_oneline &,
    .sl-grid_table bazis-col &,
    .sl-control-no-margin &,
    app-input-map-point &,
    .sl-datetime-control & {
        --margin-bottom: 0;
    }

    &__units {
        color: var(--bazis-text-color-secondary);
    }

    &__label {
        .sl-grid_accordion & {
            --font-size-label: 12px;
            --color-label: var(--bazis-text-color-secondary);
            font-weight: 400;
            line-height: 16px;
        }

        .sl-form_oneline & {
            line-height: 36px;
        }
    }

    &__field-group {
        &-wrapper {
            .bazis-control__field-group {
                margin-right: 32px;
                width: calc(100% - 32px);
            }

            bazis-button {
                --border-radius: 50%;
                margin-left: 8px;
            }
        }

        > button {
            --size-icon: 12px;
        }

        &__end {
            button {
                min-width: calc(var(--height) + var(--bazis-multiple-px) * 2);
            }

            bazis-button.bazis-btn_clear {
                --height: 34px;
                --background-hover: transparent;
                --background-focused: transparent;
                --background-activated: transparent;
                --color-hover: var(--bazis-color-action);
                --color-focused: var(--bazis-color-action);
                --color-activated: var(--bazis-color-action);

                &.bazis-eraser {
                    --padding-end: calc(var(--bazis-multiple-px) * 2);
                    --padding-start: calc(var(--bazis-multiple-px) * 2);
                }
            }
        }
    }

    textarea {
        --padding-field-top: 10px;
        --padding-field-bottom: 10px;
        min-height: 64px;
    }

    bazis-button,
    .bazis-figure_btn {
        input[type='file'] {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
        }
    }

    .bazis-figure {
        --height: 134px;
    }

    .bazis-list_control-dd {
        --position-top: var(--height);
    }

    @media (max-width: 992px) {
        &--activated {
            app-select-search &,
            app-search-filter & {
                --border-radius: 0;
                --border-width: 1px 0 1px 0;
                position: fixed;
                z-index: 1002;
                left: 0;
                right: 0;
                top: 0;

                .bazis-eraser {
                    display: none;
                }

                .bazis-control__label {
                    display: none;
                }

                .bazis-list_control-dd {
                    --max-height: calc(100vh - 36px - 1px);
                    --margin-top-list: 1px;
                    --padding-top-list: 0;
                    --padding-end-list: 20px;
                    --padding-start-list: 20px;
                    --border-radius-list: 0;

                    --border-width: 0 0 1px 0;
                    --border-color: var(--bazis-border-color);
                    --border-style: solid;
                    --padding-end: 0;
                    --padding-start: 0;
                    --padding-top: 24px;
                    --padding-bottom: 20px;

                    height: var(--max-height);

                    .bazis-item {
                        --background-hover: var(--background);

                        &.bazis-radio,
                        &.bazis-checkbox {
                            border-bottom: 1px solid var(--border-color);
                            // margin-top: 0; // не обязательно?
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        .sl-form app-input-map-point bazis-col:first-child &,
        app-input-timeinterval bazis-col:first-child & {
            --margin-bottom: var(--bazis-margin-6x);
        }
    }
}

.dt-desktop {
    app-input-timeinterval,
    app-input-dateinterval-time,
    app-input-datetime {
        .bazis-control__field-group__end bazis-button:not(.bazis-eraser) {
            display: none;
        }
    }
}

