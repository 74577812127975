.bazis-radio {
    --border-radius: 50%;
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-border-color-dark);
    --border-color-checked: var(--bazis-color-action);
    --border-width-checked: 4px;
    --background: transparent;
    --background-checked: var(--bazis-background);
    --color: var(--bazis-text-color);
    --color-disabled: var(--bazis-text-color-secondary);
    --size: 16px;
    --disabled-opacity: 0.5;

    .bazis-radio__label {
        margin-right: var(--bazis-padding);

        &.sl-label-hidden {
            margin-right: 0;
        }
    }

    &:not(.bh-inline-block, .bazis-radio_tab, .bazis-list_control-dd .bazis-radio)
        + &:not(.bh-inline-block, .bazis-radio_tab, .bazis-list_control-dd .bazis-radio),
    .bazis-control__label + &:not(.bh-inline-block, .bazis-radio_tab) {
        margin-top: calc(var(--bazis-multiple-px) * 5);
    }

    .bazis-control &,
    .bazis-list_control-dd & {
        --background-selected: var(--background);
        --color-selected: var(--color-checked);

        border: 0;

        .bazis-radio__label {
            --border-radius: 50%;
            &::before {
                margin-left: 0;
                margin-right: calc(var(--size) / 2);
            }
        }

        &.bazis-radio_right {
            .bazis-radio__label {
                &::before {
                    margin-right: 0;
                    margin-left: calc(var(--size) / 2);
                }
            }
        }

        &.bazis-item {
            border-radius: 0;
        }

        &.bazis-item--selected {
            .bazis-radio__label {
                color: var(--color-checked);

                &::before {
                    border-color: var(--border-color-checked);
                    border-width: var(--border-width-checked);
                    background: var(--background-checked);
                }
            }
        }
    }

    // types
    &_tab {
        .bazis-radio__label {
            height: 148px;
            background: var(--bazis-background-medium);
            margin: 0;
            font-size: 14px;
            font-weight: 500;

            bazis-icon {
                margin-bottom: 18px;
            }

            &::before {
                position: absolute;
                top: 12px;
                right: 8px;
                border: none;
                width: 20px;
                height: 20px;
            }
        }

        input {
            &:checked + .bazis-radio__label {
                &::before {
                    background: url('/assets/images/check-circle.svg');
                }
            }
        }
    }

    &_block {
        .bazis-radio__label {
            margin-top: calc(var(--bazis-multiple-px) * 2);
            margin-right: 0;
            border: 1px solid var(--bazis-border-color);
            border-radius: var(--bazis-border-radius);
            padding: calc(var(--bazis-multiple-px) * 4) var(--bazis-padding);
        }

        input {
            &:checked + .bazis-radio__label {
                border-color: var(--border-color-checked);
                background: var(--bazis-background-accent);
            }
        }
    }
}
