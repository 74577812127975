// все типы панелей инструментов с переопределяющими переменными компонента
.sl-toolbar {
    &_table {
        --padding-top: var(--bazis-padding-7x);
        --margin-bottom: var(--bazis-margin-5x);
        flex-wrap: wrap;

        &::part(end) {
            margin-top: var(--bazis-margin-5x);
        }

        [slot='end'] {
            justify-content: flex-end;
        }

        .bazis-btn-group {
            margin: 0 32px 0 16px;

            &:last-child {
                margin-right: 0;
            }
        }

        .bazis-control:not(app-sort .bazis-control) {
            --height: 30px;
            --margin-bottom: 0;

            .sl-form & {
                --margin-bottom: 0;
            }

            bazis-button {
                --height: 30px;
            }
        }

        app-input-default.sl-search-control {
            width: calc(var(--max-width-container) * 0.65);
        }

        app-sort .bazis-control {
            --height: 32px;
        }

        .sl-form & {
            --margin-top: var(--bazis-margin);
            --padding-top: 14px;
            --padding-bottom: 12px;
        }

        @media (max-width: 1024px) {
            &::part(start),
            &::part(end) {
                width: 100%;
                flex-shrink: 0;
            }
        }

        @media (max-width: 576px) {
            app-sort {
                width: 100%;
            }
            app-sort .bazis-control {
                max-width: 100%;
            }
        }
    }

    &_table-header {
        --margin-top: -4px;
        --padding-bottom: 16px;
    }

    &_section {
        --margin-bottom: var(--bazis-margin-4x);

        @media (max-width: 1024px) {
            flex-wrap: wrap;

            &::part(start),
            &::part(end) {
                width: 100%;
            }
            &::part(start) {
                margin-bottom: var(--margin-bottom);
            }
        }
    }

    &_contacts {
        --margin-bottom: var(--bazis-margin);
        --bazis-line-height-p-major: 36px;
    }

    &_details {
        .bh-line-elements {
            --margin-end: 4px;

            bazis-button bazis-icon,
            .bazis-dropdown bazis-button.bazis-dropdown__trigger bazis-icon {
                --bazis-icon-s-size: 20px;
                font-size: 20px;
            }
        }
    }

    @media (max-width: 1024px) {
        &_table-header {
            --margin-top: 0;
            --padding-top: 4px;
            --padding-bottom: 4px;
            --padding-start: 12px;
            --padding-end: 4px;
            --border-width: 1px 1px 0 1px;
            --border-style: solid;
            --border-color: var(--bazis-border-color);
        }
    }
}

bazis-toolbar {
    & + .bazis-list_short-info {
        --margin-bottom-list: var(--bazis-margin-4x);
    }

    app-select .bazis-control,
    app-sort .bazis-control,
    app-select-date .sl-select-date-control {
        --border-width: 0;
        --background: var(--bazis-color-info-tint);
        --font-size: 14px;
        --color: var(--bazis-link-color);

        .bazis-control__field-group {
            &__end {
                button {
                    border: none;
                }
            }

            bazis-icon {
                color: var(--color);
            }
        }
    }

    .bh-margin-start {
        --bazis-margin: 16px;
    }

    app-select .bazis-control,
    app-sort .bazis-control {
        max-width: 220px;
    }

    .bazis-select {
        --background: var(--bazis-color-info-tint);
    }

    @media (max-width: 1024px) {
        app-select .bazis-control {
            .bazis-control__field-group__end:not(
                    .sl-toolbar_searchable .bazis-control__field-group__end
                ) {
                display: none;
            }
        }
    }
}
