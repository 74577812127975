aside {
    &.bh-sticky {
        overflow-y: auto;
        max-height: calc(100vh - 150px);
        margin-right: -8px;
        padding-right: 8px;
    }

    &.sl-aside_right {
        @media (max-width: 1024px) {
            margin-top: var(--bazis-margin-8x);
        }
    }
}
