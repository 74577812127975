/* Core CSS required for components to work properly */
@import 'core/core';

/* Basic CSS for apps built */
@import 'core/normalize';
@import 'core/structure';
@import 'core/scroll';
@import "core/typography";

/* Optional CSS utils that can be commented out */
@import 'utils/display-utils';
@import 'utils/flex-utils';
@import 'utils/float-elements';
@import 'utils/font';
@import 'utils/line-elements';
@import 'utils/padding';
@import 'utils/margin';
@import 'utils/text-alignment';
@import 'utils/text-decoration';
@import 'utils/text-transformation';
@import 'utils/other';

/* Mixins */
@import 'mixins/flex';
@import 'mixins/media-query';
@import 'mixins/gradients';
@import 'mixins/text-ellipsis';
@import 'mixins/props';

/* Base Components of alphabet */
@import 'base-components/accordion';
@import 'base-components/alert';
@import 'base-components/avatar';
@import 'base-components/backdrop';
@import 'base-components/block';
@import 'base-components/breadcrumbs';
@import 'base-components/browse-default';
@import 'base-components/button-group';
@import 'base-components/card';
@import 'base-components/cckeditor';
@import 'base-components/contact';
@import 'base-components/cookie';
@import 'base-components/coordinates';
@import 'base-components/corner';
@import 'base-components/counter';
@import 'base-components/dateplate';
@import 'base-components/dl';
@import 'base-components/document-accordeon';
@import 'base-components/dropdown';
@import 'base-components/empty';
@import 'base-components/figure';
@import 'base-components/file-inline';
@import 'base-components/gallery';
@import 'base-components/infinite-scroll-content';
@import 'base-components/list';
@import 'base-components/loader';
@import 'base-components/loadmore-button';
@import 'base-components/menu';
@import 'base-components/message-plane';
@import 'base-components/modal';
@import 'base-components/paginator';
@import 'base-components/pane';
@import 'base-components/percentage-bar';
@import 'base-components/percentage-block';
@import 'base-components/slider';
@import 'base-components/status';
@import 'base-components/stepper';
@import 'base-components/tab-status';
@import 'base-components/tag';
@import 'base-components/tile';
@import 'base-components/timeline';
@import 'base-components/vehicle-number';

/* Form Components */
@import 'form-components/control.base';
@import 'form-components/checkbox';
@import 'form-components/contacts';
@import 'form-components/datetime';
@import 'form-components/file';
@import 'form-components/interval';
@import 'form-components/mask';
@import 'form-components/number-buttons';
@import 'form-components/options';
@import 'form-components/radio';
@import 'form-components/schedule';
@import 'form-components/select-date';
@import 'form-components/select-on-map';
@import 'form-components/select';
@import 'form-components/switcher';
@import 'form-components/vehicle-number';
@import 'form-components/display-error';

/* Table Components */
@import 'table-components/table.base';

/* MAP */
@import 'map-components/map';

/* Pages */
// @import 'pages/page';
// @import 'pages/dispatcher-page';
@import 'pages/dummy-page';
// @import 'pages/entrance-page';
// @import 'pages/legal-page';
@import 'pages/list-page';
// @import 'pages/main-page';
// @import 'pages/referral-page';
// @import 'pages/service-page';
// @import 'pages/simple-page';
// @import 'pages/summary-page';
