.rs-sitemap {
    height: 100vh;
    padding: 302px 40px 38px;
    background: var(--bazis-color-primary);
    color: var(--bazis-color-primary-contrast);
    overflow-y: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(105deg, #00B2FF 36.68%, rgba(0, 71, 255, 0.00) 98.85%);
        opacity: 0.2;
    }

    &__header {
        position: absolute;
        z-index: 1;
        top: 38px;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 788px;
        margin: 0 auto 77px;
        text-align: center;

        bazis-logo {
            --height: 40px;
        }

        h1 {
            margin-top: 42px;
        }
    }

    &__list {
        position: relative;
        display: flex;
        gap: 12px;
    }

    &__item {
        flex-shrink: 0;
        width: 172px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: var(--bazis-color-primary-contrast);
        text-decoration: none;
        transition: transform 0.4s;

        &:hover {
            transform: translateY(-10px);
        }

        &:last-child {
            padding-right: 40px;
            box-sizing: content-box;
        }
    }

    &__preview {
        display: block;
        margin-top: 4px;
        transform: scale(0.123);
        transform-origin: 0 0;
    }

    &__iframe {
        width: 1400px;
        border: none;
        pointer-events: none;
    }
}