.bazis-block {
    --padding-top: 24px;
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-bottom: 24px;
    --background: var(--bazis-background);
    --border-radius: var(--bazis-border-radius);
    --margin-bottom: 20px;

    &__header {
        --margin-bottom: 16px;

        &:last-child {
            --margin-bottom: 0;
        }

        .bazis-block_accent & {
            --margin-bottom: calc(var(--bazis-multiple-px) * 6);
            --padding-bottom: calc(var(--bazis-multiple-px) * 6);
            --border-width: 0 0 1px 0;
            --border-style: solid;
            --border-color: var(--bazis-border-color-dark);
        }
    }

    &__content {
        &_start {
            flex-shrink: 0;
        }
        &_main {
            --width: 100%;

            &__left {
                flex-shrink: 0;
                width: 200px;
                margin-right: 40px;

                bazis-dateplate + bazis-dateplate {
                    display: block;
                    margin-top: 12px;
                }
            }
            &__center {
                width: 100%;
            }
            .bazis-block__footer {
                --padding-top: 12px;
                --margin-start: 0;
                --margin-end: 0;
                --padding-start: 0;
                --padding-end: 0;
            }
        }
        &_end {
            --padding-start: calc(var(--bazis-multiple-px) * 10);
            --border-width: 0 0 0 1px;
            --border-style: solid;
            --border-color: var(--bazis-border-color);
            --width: 337px;
            --margin-start: calc(var(--bazis-margin) * 2);
            flex-shrink: 0;
        }
    }

    &__footer {
        --margin-top: 32px;
        --margin-start: -24px;
        --margin-end: -24px;
        --border-width: 1px 0 0 0;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --padding-top: 20px;
        --padding-start: 24px;
        --padding-end: 24px;

        .bazis-block_accent & {
            --margin-top: 16px;
            --margin-start: -20px;
            --margin-end: -32px;
            --padding-start: 20px;
            --padding-end: 32px;

            > .bh-flex {
                margin-left: 70px;
            }

            .bazis-h6 {
                margin-bottom: var(--bazis-multiple-px);
                line-height: 16px;
            }
        }

        aside & {
            --margin-top: 0;
            --margin-start: -16px;
            --margin-end: -16px;
            --padding-top: 20px;
            --padding-start: 16px;
            --padding-end: 16px;
        }
    }

    &_outline {
        --padding-top: 32px;
        --padding-start: 32px;
        --padding-end: 32px;
        --padding-bottom: 32px;

        @media (max-width: 1024px) {
            --padding-top: 24px;
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-bottom: 24px;
        }
    }

    &_editable {
        --padding-top: 8px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-bottom: 8px;
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color-dark);
        --border-color-hover: var(--bazis-text-secondary);
        --margin-bottom: calc(var(--bazis-multiple-px) * 2);

        .bazis-list_short-info {
            --color: var(--bazis-color-action);
        }
    }

    &_accent {
        --padding-top: 32px;
        --padding-start: 32px;
        --padding-end: 32px;
        --padding-bottom: 32px;
        --margin-bottom: 24px;
        --background: var(--bazis-background-accent);

        .bazis-block & {
            --margin-top: 20px;
            --margin-bottom: 0;
            --padding-top: 8px;
            --padding-start: 12px;
            --padding-end: 12px;
            --padding-bottom: 8px;

            .sl-table_clear td {
                --padding-top: 2px;
                --padding-bottom: 2px;
            }
        }

        &.sl-block_large {
            --margin-bottom: 40px;
        }

        bazis-skeleton {
            --background: var(--bazis-border-color-dark);
        }

        @media (max-width: 1024px) {
            --padding-top: 24px;
            --padding-bottom: 24px;
            --padding-start: 20px;
            --padding-end: 20px;
        }

        @media (max-width: 576px) {
            .bazis-block__footer > .bh-flex {
                display: block;
                margin-left: 0;

                .sl-text-size_mini {
                    font-size: 14px;
                    line-height: 20px;
                }

                > *:not(.bh-no-margin) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &_warning {
        --margin-bottom: 0;
        --padding-top: 16px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-bottom: 16px;
        --background: var(--bazis-background-info-secondary);

        > bazis-icon {
            float: left;
            margin-right: calc(var(--bazis-multiple-px) * 3);
        }

        .bazis-block__content {
            overflow: hidden;
            line-height: 20px;

            .bazis-list_short-info {
                --margin-top-list: 3px;
            }
        }

        @media (max-width: 576px) {
            .bazis-block__content .bazis-list_short-info {
                flex-wrap: wrap;
            }
        }
    }

    &_secondary {
        --background: var(--bazis-background-medium);

        .bazis-modal & {
            --padding-top: 20px;
            --padding-bottom: 20px;
            --padding-start: 16px;
            --padding-end: 16px;
        }
    }

    aside & {
        --padding-top: 20px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-bottom: 20px;

        &.sl-block_small {
            --padding-start: 16px;
            --padding-end: 16px;
        }

        &.sl-block_large {
            --padding-top: 24px;
            --padding-start: 20px;
            --padding-end: 20px;
            --padding-bottom: 24px;
        }

        & .bazis-block_secondary {
            --margin-top: 12px;
            --margin-bottom: 0;
            --padding-top: 16px;
            --padding-start: 16px;
            --padding-end: 16px;
            --padding-bottom: 16px;
        }

        hr {
            --margin-top: var(--bazis-margin-4x);
            --margin-bottom: var(--bazis-margin-4x);
        }

        &__header {
            --margin-bottom: 12px;
            h6 a {
                text-decoration: none;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 2px;
                    right: 2px;
                    bottom: -2px;

                    border-bottom: 1px dashed var(--bazis-link-color);
                }
            }
        }

        &.bazis-block_outline {
            bazis-toolbar:first-child {
                margin-top: -10px;
            }
        }
    }

    aside.sl-aside_left & {
        --padding-top: 16px;
        --padding-start: 20px;
        --padding-end: 20px;
        --padding-bottom: 16px;

        @media (max-width: 992px) {
            &,
            &_outline {
                --padding-start: 0;
                --padding-end: 0;
                --padding-top: 0;
                --padding-bottom: 0;
                --border-width: 0;

                bazis-button {
                    display: inline-block;
                }
            }
        }

        @media (max-width: 576px) {
            &,
            &_outline {
                bazis-button {
                    display: block;
                }
            }
        }
    }

    @media (max-width: 576px) {
        --margin-bottom: 16px;
    }
}

a.bazis-block {
    display: block;
    text-decoration: none;
}

.sl-block {
    &_mini {
        --padding-top: 8px;
        --padding-bottom: 8px;
        --padding-start: 16px;
        --padding-end: 16px;

        .bazis-list_history & {
            --margin-bottom: 0;
            --margin-top: var(--bazis-margin-3x);
            display: inline-flex;
        }
    }

    &_search {
        --max-width: 612px;
        --padding-top: 20px;
        --padding-start: 20px;
        --padding-end: 20px;
        --padding-bottom: 20px;
        --margin-bottom: 0;

        position: absolute;
        z-index: 150;
        bottom: 0;
        left: 0;

        &.bazis-block_shadow {
            --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
        }

        .bazis-block__footer {
            --margin-bottom: -20px;
            --margin-start: -20px;
            --margin-end: -20px;
            --padding-top: var(--bazis-padding);
            --padding-bottom: var(--bazis-padding);
            --padding-start: calc(var(--bazis-multiple-px) * 4);
            --padding-end: calc(var(--bazis-multiple-px) * 4);
        }

        .bazis-empty & {
            text-align: left;
        }
    }
}

.sl-flex {
    display: flex;
    gap: var(--gap);
    align-items: (var(--align));
}