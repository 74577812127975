.bazis-paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_secondary {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bazis-btn-group {
            &:last-child {
                bazis-button {
                    &.bh-hidden {
                        display: none;
                    }

                    margin-left: 8px;

                    &::part(native) {
                        border: var(--border-width) var(--border-style) var(--border-color);
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        .bazis-btn-group bazis-button.bazis-btn_solid {
            --background: var(--bazis-color-white);
            font-weight: 400;
        }
    }

    @media (max-width: 400px) {
        &_secondary {
            display: block;

            .bazis-btn-group {
                &:last-child {
                    margin-top: 8px;

                    bazis-button {
                        &:first-child,
                        &.bh-hidden + bazis-button {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}
