.bazis-stepper {
    --min-height: 56px;
    --padding-start: 33px;
    --padding-end: 12px;
    --padding-top: 16px;
    --padding-bottom: 16px;
    --background: var(--bazis-header-background);
    --background-active: var(--bazis-stepper-background);

    --width-item-before: 18px;
    --width-item-after: 18.5px;

    .bazis-item {
        --margin-start: 0;
        cursor: default;
        pointer-events: none;

        &:last-child::after {
            background-image: none;
        }
    }

    bazis-step {
        --font-size: 14px;
        --font-weight: 500;
        --line-height: 24px;
        --color-muted: var(--bazis-text-color-secondary);

        --sign-margin: 10px;
        --sign-background: var(--bazis-text-color-secondary);
        --sign-background-muted: var(--bazis-text-color-secondary);
        --sign-background-active: var(--bazis-color-white);
        --sign-color-muted: var(--bazis-color-white);
        --sign-size: 24px;

        &::part(sign) {
            line-height: var(--sign-size);
        }

        &.bazis-step_check {
            --sign-color: var(--background);
            --sign-color-active: var(--bazis-stepper-background);
        }
        &.bazis-step_outline {
            --sign-color-active: var(--bazis-color-white);
        }
    }

    .sl-header & {
        --margin-start: 20px;
    }

    @media (max-width: 920px) {
        --padding-start: 88px;
        --padding-end: 0;
        --background-active: transparent;

        .bazis-item {
            &::before {
                --width-item-before: 88px;
                content: '';
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: 1px;
                width: var(--width-item-before);
                clip-path: none;
                background: var(--bazis-stepper-background);
            }
            &::after {
                display: none;
            }

            &:first-child {
                --padding-start: 0;
                &::before {
                    display: none;
                }
            }

            &--active {
                &::before {
                    background: var(--bazis-color-white);
                }
            }
        }
        bazis-step {
            --sign-margin: 0;
            &::part(label) {
                display: none;
            }
        }
    }

    @media (max-width: 400px) {
        --padding-start: 64px;
        // --padding-end: 32px;
        .bazis-item {
            &::before {
                --width-item-before: 64px;
            }
        }
    }
}
