// все типы сеток с переопределяющими переменными компонента сетки
.sl-grid {
    &_global {
        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start-xl: 0;
            --bazis-grid-column-padding-end-xl: 40px;

            --bazis-grid-column-padding-start-xs: 0;
            --bazis-grid-column-padding-end-xs: 0;
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;
        }

        dl.bazis-dl_inline {
            --width-dt: 200px;
            --margin-bottom-dt: 12px;
            --margin-bottom-dd: 12px;

            &--lg {
                --margin-bottom-dt: 20px;
                --margin-bottom-dd: 20px;
            }
        }
    }
    &_form {
        > bazis-row {
            margin: 0 calc(var(--bazis-multiple-px) * -3);

            > bazis-col {
                --bazis-grid-column-padding-start-sm: calc(var(--bazis-multiple-px) * 3);
                --bazis-grid-column-padding-end-sm: calc(var(--bazis-multiple-px) * 3);
                --bazis-grid-column-padding-top: 0;
                --bazis-grid-column-padding-bottom: 0;

                --bazis-grid-column-padding-start-xs: 0;
                --bazis-grid-column-padding-end-xs: 0;

                .sl-form_oneline & {
                    --bazis-grid-column-padding-start: var(--bazis-multiple-px);
                    --bazis-grid-column-padding-end: var(--bazis-multiple-px);
                }
            }
        }
    }
    &_accordion,
    &_accordion &_form {
        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start: var(--bazis-padding-4x);
            --bazis-grid-column-padding-end: var(--bazis-padding-4x);
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;
        }
    }
    &_card {
        margin: 0 calc(var(--bazis-multiple-px) * -4);

        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start: calc(var(--bazis-multiple-px) * 4);
            --bazis-grid-column-padding-end: calc(var(--bazis-multiple-px) * 4);
            --bazis-grid-column-padding-bottom: calc(var(--bazis-padding) * 2);
        }
    }
    &_filter {
        --padding-grid: calc(var(--bazis-multiple-px) * 3);
        margin: 0 calc(var(--padding-grid) * -1);

        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start: var(--padding-grid);
            --bazis-grid-column-padding-end: var(--padding-grid);
        }
    }
    &_timeinterval {
        > bazis-row {
            > bazis-col {
                --bazis-grid-column-padding-start-sm: var(--bazis-padding-2x);
                --bazis-grid-column-padding-end-sm: var(--bazis-padding-2x);
                --bazis-grid-column-padding-top: 0;
                --bazis-grid-column-padding-bottom: 0;

                --bazis-grid-column-padding-start-xs: 0;
                --bazis-grid-column-padding-end-xs: 0;
            }
        }
    }
    &_center {
        max-width: 800px;

        > bazis-row > bazis-col {
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;
        }
    }

    &_global,
    &_accordion,
    &_timeinterval,
    &_center
    {
        > bazis-row > bazis-col {
            &:first-child {
                --bazis-grid-column-padding-start-xs: 0;
                --bazis-grid-column-padding-start-sm: 0;
                --bazis-grid-column-padding-start-xl: 0;
            }
            &:last-child {
                --bazis-grid-column-padding-end-xs: 0;
                --bazis-grid-column-padding-end-sm: 0;
                --bazis-grid-column-padding-end-xl: 0;
            }
        }
    }
}

bazis-row {
    &.bazis-list_vline {
        > bazis-col.bazis-item {
            --bazis-grid-column-padding-start: 0;
            --bazis-grid-column-padding-end: 0;

            &::before {
                --padding-start: var(--bazis-padding-4x);
            }
        }

        &:first-child {
            > bazis-col {
                --bazis-grid-column-padding-top: 0;
            }
        }
        &:last-child {
            > bazis-col {
                --bazis-grid-column-padding-bottom: 0;
            }
        }
    }

    &.sl-row_vertical-zero {
        > bazis-col {
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;
        }
    }
}

@media (max-width: 1024px) {
    .sl-grid_center {
        max-width: 100%;
        margin: 0 36px;
    }
}

@media (max-width: 576px) {
    .sl-grid_center {
        margin: 0;
    }
}