.bazis-empty {
}

.sl-empty {
    &_notifications {
        --margin-top: 0;
        --padding-top: calc(var(--bazis-multiple-px) * 4);
        --padding-bottom: calc(var(--bazis-multiple-px) * 6);
        --background: var(--bazis-background-medium);
        --font-size: 12px;
        --line-height: 16px;
    }

    &_primary {
        --color: var(--bazis-text-color);
    }
}
