.bazis-breadcrumbs {
    --padding-top: 16px;
    --padding-bottom: 16px;
    --margin-bottom: 16px;
    --text-decoration: none;
    --color: var(--bazis-text-color-secondary);

    .bazis-list {
        --font-size: 12px;
        --line-height: 16px;

        > .bazis-item {
            --margin-end: 8px;
        }
    }

    bazis-icon {
        --bazis-icon-s-size: 12px;
        margin-right: var(--margin-end);
    }
}
