.sl-table_twice {
    > thead > tr > th {
        --background: transparent;
        --padding-start: 8px;
        --padding-top: 4px;
        --padding-end: 8px;
        --padding-bottom: 4px;

        &:first-child {
            --padding-start: 0;
        }
    }

    > tbody > tr > td {
        --background: var(--bazis-background);
        --padding-start: 16px;
        --padding-top: 16px;
        --padding-end: 16px;
        --padding-bottom: 16px;

        --border-color: var(--bazis-background-accent);
        --border-width: 0 0 8px 0;
        --border-radius: var(--bazis-border-radius);
    }

    &__child {
        --margin-bottom: 0;

        td {
            --padding-top: 4px;
            --padding-bottom: 4px;
            --border-width: 0;

            &:first-child {
                --padding-start: 0;
            }
            &:last-child {
                --padding-end: 0;
            }
        }
        tr {
            &:first-child td {
                --padding-top: 0;
            }
            &:last-child td {
                --padding-bottom: 0;
            }
        }
    }
}
