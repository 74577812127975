.bazis-timeline {
    display: flex;
    align-items: flex-end;

    & > *:not(.bazis-timeline__slider-wrapper) {
        flex-shrink: 0;
    }

    .bazis-map-wrapper & {
        position: absolute;
        left: 8px;
        right: 8px;
        bottom: 8px;
        z-index: 150;
        border-radius: var(--bazis-border-radius);
        background: var(--bazis-color-white);
        padding: 10px 12px;
        width: calc(100% - 16px);
    }

    &__slider {
        &-wrapper {
            max-width: 1080px;
            width: 100%;
            margin: 0 24px -4px;
            overflow: hidden;
        }
    }

    &__graph {
        height: 40px;
        width: 100%;
        max-width: 1080px;

        &_brick {
            @extend .bazis-timeline__graph;
            display: flex;
            align-items: flex-end;
        }
    }

    &__labels {
        display: flex;
        width: 100%;
        max-width: 1080px;
        // justify-content: space-between;
        font-size: 11px;
        line-height: 12px;
        color: var(--bazis-text-color-secondary);

        span {
            width: 100%;
            transform: translateX(-48%);
            text-align: center;

            &:first-child {
                visibility: hidden;
            }
        }
    }

    &__brickwork {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding: 0 2px 0 3px;

        &::before {
            position: absolute;
            left: 0;
            content: '';
            height: 100%;
            width: 1px;
            border-left: 1px solid var(--bazis-border-color);
        }

        &:nth-child(even) {
            &::before {
                height: 12px;
            }
        }
        // overflow: hidden;
    }

    &__brick {
        height: 12px;
        width: 100%;
        padding: 1px;
        margin-bottom: 1px;
        border-radius: 1px;
        background: var(--bazis-color-base);
        color: var(--bazis-color-white);
        text-align: center;
        font-size: 10px;
        line-height: 1;

        &:last-child {
            margin-bottom: 0;
        }

        &_xs {
            @extend .bazis-timeline__brick;
            height: 4px;
        }
    }

    .bazis-timeline__play {
        margin-right: 22px;
    }

    .bazis-timeline__speed {
        --border-radius: 50%;
        --border-color: transparent;
        --border-color-activated: var(--bazis-border-color-dark);
        --background-activated: var(--bazis-color-white);
        --padding-start: 0;
        --padding-end: 0;

        width: 32px;
        margin: 0 2px;
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
    }

    //// ngx-slider
    .ngx-slider {
        margin: 2px 0 6px;

        .ngx-slider-pointer {
            background-color: var(--bazis-text-color);
            width: 3px;
            height: 12px;
            border-radius: 1.5px;
            top: -4px;

            &::after, &::before {
                content: '';
                position: absolute;
                top: 4px;
                width: 2px;
                height: 4px;
                border-radius: 0;
                background: var(--bazis-border-color);
            }
            &::after {
                left: 3px;
            }
            &::before {
                left: -2px;
            }

            &.ngx-slider-active::after {
                background: var(--bazis-border-color);
            }
        }
        .ngx-slider-bar-wrapper {
            margin-top: -8px;
            padding-top: 8px;

            &.ngx-slider-selection-bar {
                visibility: visible !important;
            }
        }
        .ngx-slider-bar {
            background-color: var(--bazis-border-color);
        }
        .ngx-slider-selection {
            background-color: var(--bazis-text-color);
        }
        .ngx-slider-bubble {
            display: none;
        }
    }
}
