th {
    --padding-start: 8px;
    --padding-top: 8px;
    --padding-end: 8px;
    --padding-bottom: 8px;
    --font-size: 13px;
    --font-weight: 500;
    --line-height: 20px;

    text-align: left;

    .sl-table_mini & {
        --padding-top: 2px;
        --padding-bottom: 2px;
        --font-size: 10px;
        --line-height: 12px;
        text-transform: uppercase;
        min-width: 66px;

        &:last-child {
            --padding-end: 8px;
            text-align: left;
        }
    }

    thead &,
    &.sl-thead-th {
        --background: var(--bazis-background-medium);
        --color: var(--bazis-text-color-secondary);
    }
    thead tr.sl-th_no-padding &,
    thead &.sl-th_no-padding {
        --padding-bottom: 0;
    }
    thead tr.sl-th_no-padding + tr & {
        --padding-top: 0;
    }

    tbody & {
        --background: var(--bazis-background-accent);
        --color: var(--bazis-text-color);
        --border-width: 0 0 1px 0;
        --border-style: solid;
        --border-color: var(--bazis-background-dark);
    }
    tbody &.sl-thead-th {
        --padding-top: 16px;
        --padding-bottom: 16px;
        --border-color: var(--bazis-background-medium);
        text-align: center;
        vertical-align: middle;
    }
}

td {
    --padding-start: 8px;
    --padding-top: 16px;
    --padding-end: 8px;
    --padding-bottom: 16px;
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-width: 0 0 1px 0;
    --line-height: 20px;

    tbody & {
        .bazis-dropdown {
            &__trigger {
                --background: var(--bazis-color-white);
                --color: var(--bazis-placeholder-color);
            }
            &__body {
                --top: 36px;
            }

            .bazis-list_control-dd {
                --margin-start-list: 0;
                --margin-end-list: 0;
                --margin-top-list: 0;
                --margin-bottom-list: 0;
                width: var(--width);
            }
        }

        bazis-icon {
            --bazis-icon-s-size: 16px;
            color: var(--bazis-placeholder-color);
        }
    }

    &:last-child {
        --padding-end: var(--bazis-padding);
    }

    &.sl-table__cell-btns {
        --padding-top: 8px;
    }

    .sl-tr-collapsed {
        cursor: pointer;

        bazis-icon {
            font-size: 8px;
            color: var(--bazis-text-color);
        }
    }

    .sl-td-narrow & {
        --padding-top: 4px;
        --padding-bottom: 4px;
        position: relative;
        top: -10px;
    }
    .sl-td-narrow.bazis-td-no-border & {
        --padding-top: 0;
        --padding-bottom: 4px;
    }

    .sl-tr--active & {
        --background: var(--bazis-background-accent);
    }
}

.bazis-block_accent {
    thead th,
    th.sl-thead-th {
        --background: rgba(var(--bazis-background-rgb), 0.7);
    }
    tbody th {
        --background: var(--bazis-background-accent);
        --border-color: var(--bazis-color-white);
    }
    tbody th.sl-thead-th {
        --border-color: rgba(var(--bazis-background-rgb), 0.7);
    }

    td {
        --border-color: var(--bazis-color-white);
    }
}

table {
    --margin-bottom: calc(var(--bazis-multiple-px) * 6);

    .sl-form & .bazis-control,
    .bazis-control {
        --margin-bottom: 0;
    }

    .bazis-checkbox__label,
    .bazis-radio__label {
        &::before {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .sl-form & {
        th, td {
            --padding-start: 24px;
            --padding-end: 0;
        }
        td:last-child,
        thead th:last-child {
            --padding-end: 8px;
        }
    }

    .sl-dropdown_action {
        bazis-button.bazis-dropdown__trigger {
            --color-hover: var(--bazis-color-action);

            bazis-icon {
                color: currentColor;
            }
        }

        &:hover {
            bazis-button.bazis-dropdown__trigger {
                --color: var(--color-hover);
            }
        }
    }

    .sl-tooltip_dropdown {
        --box-shadow: none;
        padding: 0;

        .bazis-list_control-dd {
            --margin-top-list: 0;
            position: static;
        }
    }
}

.sl-table_schedule {
    --margin-top: 13px;

    td {
        --padding-start: 12px;
        --padding-end: 12px;

        &:first-child {
            --padding-start: 0;
            min-width: 240px;
        }

        min-width: 218px;

        &.sl-table__cell_action {
            --padding-end: 0;
            min-width: 0;
        }

        .bazis-control {
            max-width: 226px;
            white-space: nowrap;
        }
    }
}

.sl-table_hoverable {
    tbody tr:hover td {
        background: var(--bazis-background-accent);
    }
}

.sl-table_clear {
    --margin-bottom: 0;

    td {
        --padding-top: 8px;
        --padding-bottom: 8px;
        --border-width: 0;

        &:first-child {
            --padding-start: 0;
        }
        &:last-child {
            --padding-end: 0;
        }
    }
}
