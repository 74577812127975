.bazis-counter {
    // type
    &_line {
        display: flex;
        align-items: center;

        .bazis-counter__item {
            --height: initial;
            --width: initial;
            --border-radius: initial;
            border-radius: var(--border-radius);
            height: var(--height);
            width: var(--width);
            background: var(--bazis-color-base, var(--bazis-placeholder-color));
        }

        .bazis-counter__total {
            @include props-font();
            @include font();

            --margin-end: initial;

            &:first-child {
                margin-right: var(--margin-end);
            }
            &:last-child {
                margin-left: var(--margin-end);
            }
        }
    }
}
