bazis-tabs {
    --padding-bottom: 8px;
    --color: var(--bazis-text-color);
    --height: 48px;
    --font-size: 16px;
    --font-weight: 400;
    --line-height: 1;

    --font-weight-selected: 500;

    --tab-background-selected: transparent;
    --tab-border-width: 0 0 2px 0;
    --tab-border-style: solid;
    --tab-border-color: transparent;
    --tab-border-color-hover: var(--bazis-text-color);
    --tab-border-color-selected: var(--bazis-text-color);
    --tab-margin-end: 24px;

    &::part(wrapper) {
        margin-bottom: calc(-1 * var(--padding-bottom));
    }
}
