bazis-badge {
    --background: #66B8F6; 

    --height: 12px;
    --height-xxs: 6px;
    --height-xs: 8px;
    --height-medium: 16px;
    --height-large: 24px;
    --padding-start: 2px;
    --padding-end: 2px;
    --margin-start: 4px;
    --margin-end: 4px;

    --font-size: 9px;
    --font-size-medium: 12px;
    --font-size-large: 16px;
    --font-weight: 500;
    --color: var(--bazis-color-white);
}