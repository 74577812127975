@import 'checkbox';
@import 'radio';
@import 'control.base';
@import 'interval';
@import 'mask';
@import 'number-buttons';
@import 'range-controls';
@import 'select-date';
@import 'select';
@import 'switcher';
@import 'symbol';
@import 'vehicle-number';

.sl-form {
    .bazis-map-wrapper {
        --height: 324px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 48px;
        background: var(--bazis-background);
        border-top: 1px solid var(--bazis-border-color);

        &::after,
        &::before {
            display: none;
        }

        &__right {
            display: flex;
            align-items: center;
        }

        @media (max-width: 576px) {
            flex-direction: column-reverse;

            .bh-line-elements {
                display: block;
                width: 100%;
                margin-top: var(--bazis-margin-4x);

                &:last-child {
                    margin-top: 0;
                }
            }
            &_finish {
                flex-direction: column;
            }
            &__right {
                display: flex;
                flex-direction: column-reverse;
                width: 100%;
                margin-top: 16px;

                > * {
                    width: 100%;
                }
            }
            bazis-button {
                display: block;
            }
        }
    }

    &-group {
        margin-bottom: var(--bazis-margin-2x);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-error {
        --bazis-font-size-p: 11px;
        margin-bottom: 24px;
        font-size: 11px;
    }
}

app-browse-default {
    display: block;
    margin-bottom: var(--bazis-margin-6x);

    .sl-block_mini & {
        margin-bottom: 0;
    }
}

.sl-datetime-control {
    position: relative;
    margin-bottom: var(--bazis-margin-6x);

    > .bazis-control__note {
        display: flex;
        align-items: center;
        margin-top: 8px;

        .bazis-checkbox label::before {
            margin-left: 0;
        }
    }

    .sl-control-no-margin & {
        margin-bottom: 0;
    }
}

.sl-eraser_outer {
    position: absolute;
    z-index: 1;
    right: 0;
    top: -8px;
}

.sl-loader-form {
    height: calc(100vh - 320px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sl-options-content {
    margin-top: 20px;
    max-height: 210px;
    overflow: auto;

    .bazis-dropdown__body & {
        margin-top: 0;
    }
}
