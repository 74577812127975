.sl-scheduler {
    @include props-width;

    @include width;
}

.sl-scheduler {
    --margin-bottom-list: 12px;
    --width: 348px;
    --max-width: 100%;

    &.bazis-list {
        --padding-top: var(--bazis-multiple-px);
        --padding-bottom: var(--bazis-multiple-px);

        .sl-scheduler__day {
            display: inline-block;
            width: 20px;
            font-size: 10px;
            text-transform: uppercase;

            + span {
                margin-left: calc(var(--bazis-multiple-px) * 3);
            }
        }
    }

    .bazis-item {
        --padding-top: 0;
        --padding-bottom: 0;
        --margin-bottom: 6px;
    }

    @media (max-width: 780px) {
        --width: 100%;
    }

    @media (max-width: 576px) {
        --margin-bottom-list: 0;

        &.bazis-list  {
            .bazis-item {
                display: block;

                > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                > span {
                    display: block;
                    text-align: right;
                }
            }
        }
    }
}
