.bazis-dateplate {
    .bazis-block__content_main__left & {
        .bazis-text_action {
            letter-spacing: 0;
            color: var(--bazis-text-color);
        }
    }

    h5 &,
    h6 & {
        display: inline-block;
        padding-left: calc(var(--bazis-multiple-px) * 3);
        font-weight: 400;
        vertical-align: middle;

        bazis-icon {
            margin-top: -2px;
        }
    }

    &__time bazis-icon {
        --bazis-icon-xs-size: 16px;
    }
}
