bazis-interrogation {
    --margin-start: calc(var(--bazis-multiple-px) * 3);
    --color: var(--bazis-placeholder-color);

    .bazis-checkbox_right + & {
        --margin-start: 0;
        --margin-end: calc(var(--bazis-multiple-px) * 3);
        // проверить на кросс браузерность
        display: inline-flex;
    }
}
