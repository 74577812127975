.bazis-checkbox {
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-border-color-dark);
    --background: transparent;
    --background-checked: var(--bazis-color-action);
    --background-disabled: var(--bazis-background-dark);
    --border-color-checked: var(--bazis-color-action);
    --border-color-disabled: var(--bazis-border-color-dark);
    --color: var(--bazis-text-color);
    --color-disabled: var(--bazis-text-color-secondary);
    --size: 16px;
    --disabled-opacity: 0.5;

    &__label {
        margin-right: calc(var(--bazis-multiple-px) * 3);

        @media only screen and (-webkit-min-device-pixel-ratio: 2) {
            line-height: 16px;
        }

        &::before {
            line-height: 14px;

            @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                line-height: 16px;
            }
        }

        &.sl-label-hidden {
            margin-right: 0;
        }
    }

    &:not(.bh-inline-block, .bazis-item) + &:not(.bh-inline-block, .bazis-item),
    .bazis-control__label + &:not(.bh-inline-block, .bazis-item) {
        margin-top: calc(var(--bazis-multiple-px) * 5);
    }

    .bazis-control &,
    .bazis-list_control-dd & {
        --background-selected: var(--background);
        --color-selected: var(--color-checked);
        --border-radius: var(--bazis-border-radius);

        border: 0;

        .bazis-checkbox__label {
            &::before {
                margin-left: 0;
                margin-right: calc(var(--size) / 2);
            }
        }

        &.bazis-checkbox_right {
            .bazis-checkbox__label {
                &::before {
                    margin-right: 0;
                    margin-left: calc(var(--size) / 2);
                }
            }
        }

        &.bazis-item {
            border-radius: 0;
        }

        &.bazis-item--selected {
            .bazis-checkbox__label::before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' viewBox='0 0 11 8'%3E%3Cpath d='M0 4.17391L0.6875 3.47826L3.78125 6.6087L10.3125 0L11 0.695652L3.78125 8L0 4.17391Z' fill='white' /%3E%3C/svg%3E");
                border-color: var(--border-color-checked);
                background: var(--background-checked);

                color: var(--checkmark-color);
            }
        }
    }

    .bazis-list_vline .bazis-control & {
        margin-left: var(--bazis-margin-3x);

        &__label::before {
            margin-right: var(--bazis-margin-3x);
        }
    }

    .sl-item--selected_partial & {
        &__label::before {
            content: '—';
            border-color: var(--border-color-checked);
            background: var(--background-checked);

            color: var(--checkmark-color);
        }
    }

    .sl-col_selectable .bazis-control & {
        &__label::before {
            margin-right: var(--bazis-margin-6x);
        }
    }

    @media (max-width: 1024px) {
        .sl-toolbar_table-header .bazis-list_vline .bazis-control & {
            margin-left: 0;
        }
    }
}
