.bazis-message-plane {
    @include props-padding;
    @include props-colors;
    @include props-font;

    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    @include colors;
    @include font;

    @extend .bazis-container;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > *:not(.bazis-message-plane__close){
            width: 100%;
        }

        > .bazis-message-plane__close {
            flex-shrink: 0;
        }
    }
}
