.bazis-menu {
    /*
    * @prop --background-list: фон списка
    *
    * @prop --border-radius-list: радиус скругления списка
    * @prop --border-width-list: толщина границы списка
    * @prop --border-style-list: стиль границы списка
    * @prop --border-color-list: цвет границы списка
    *
    * @prop --padding-top-list: внутренний верхний отступ списка
    * @prop --padding-end-list: внутренний конечный отступ списка
    * @prop --padding-bottom-list: внутренний нижний отступ списка
    * @prop --padding-start-list: внутренний начальный отступ списка
    *
    * @prop --margin-top-list: внешний верхний отступ списка
    * @prop --margin-end-list: внешний конечный отступ списка
    * @prop --margin-bottom-list: внешний нижний отступ списка
    * @prop --margin-start-list: внешний начальный отступ списка
    * @prop --box-shadow: тень списка
    *
    * @prop --max-height: максимальная высота списка
    *
    * @prop --background: фон итема
    * @prop --background-hover: фон итема при наведении
    * @prop --background-selected: фон выбранного итема
    *
    * @prop --border-radius: радиус скругления итема
    * @prop --border-width: толщина границы итема
    * @prop --border-style: стиль границы итема
    * @prop --border-color: цвет границы итема
    *
    * @prop --padding-top: внутренний верхний отступ итема
    * @prop --padding-end: внутренний конечный отступ итема
    * @prop --padding-bottom: внутренний нижний отступ итема
    * @prop --padding-start: внутренний начальный отступ итема
    *
    * @prop --margin-top: внешний верхний отступ итема
    * @prop --margin-end: внешний конечный отступ итема
    * @prop --margin-bottom: внешний нижний отступ итема
    * @prop --margin-start: внешний начальный отступ итема
    *
    * @prop --min-height: минимальная высота итема
    *
    * @prop --list-style: стиль списка
    *
    * @prop --color: цвет текста
    * @prop --color-hover: цвет текста при наведении
    * @prop --color-selected: цвет текста выбранного пункта
    * @prop --font-size: размер шрифта
    * @prop --font-weight: жирность шрифта
    * @prop --line-height: межстрочный интервал для текста
    *
    * @prop --size-icon: размер иконки
    */
    --min-height: initial;
    --border-width: initial;
    --border-color: initial;
    --border-style: initial;
    --padding-start: initial;
    --padding-end: initial;
    --padding-top: initial;
    --padding-bottom: initial;
    --margin-start: initial;
    --margin-end: initial;
    --margin-top: initial;
    --margin-bottom: initial;
    --background: initial;
    --background-hover: var(--background);
    --background-selected: var(--background);
    --color: inherit;
    --color-hover: var(--color);
    --color-selected: var(--color);
    --font-size: inherit;
    --font-weight: inherit;
    --line-height: inherit;

    --max-height: initial;
    --padding-start-list: 0;
    --padding-end-list: 0;
    --padding-top-list: 0;
    --padding-bottom-list: 0;
    --margin-start-list: 0;
    --margin-end-list: 0;
    --margin-top-list: 0;
    --margin-bottom-list: 0;
    --background-list: initial;
    --border-radius-list: initial;
    --border-width-list: initial;
    --border-color-list: initial;
    --border-style-list: initial;
    --box-shadow: none;

    max-height: var(--max-height);
    border-radius: var(--border-radius-list);
    border-width: var(--border-width-list);
    border-style: var(--border-style-list);
    border-color: var(--border-color-list);
    padding: var(--padding-top-list) var(--padding-end-list) var(--padding-bottom-list)
        var(--padding-start-list);
    margin: var(--margin-top-list) var(--margin-end-list) var(--margin-bottom-list)
        var(--margin-start-list);
    background: var(--background-list);
    box-shadow: var(--box-shadow);
    list-style: var(--list-style);
    clear: both;

    &.bh-no-margin {
        margin: 0;
    }

    .bazis-item {
        display: list-item;
        min-height: var(--min-height);
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        margin-left: var(--margin-start);
        margin-right: var(--margin-end);
        margin-top: var(--margin-top);
        margin-bottom: var(--margin-bottom);
        border-radius: var(--border-radius);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        background: var(--background);
        list-style: var(--list-style);
        font-size: var(--font-size);
        font-weight: var(--font-weight);
        line-height: var(--line-height);
        color: var(--color);

        &:hover,
        &.active {
            background: var(--background-hover);
            color: var(--color-hover);
        }

        &--selected,
        &--selected:hover,
        &:active,
        &:active:hover {
            background: var(--background-selected);
            color: var(--color-selected);
        }

        &--disabled {
            opacity: 0.5;
        }
    }

    .bazis-item:not(a, bazis-button) {
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
    }

    bazis-button {
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        --height: auto;
        --background: transparent;
        --background-hover: var(--background);
        --background-activated: var(--background);
        --background-focused: var(--background);
        --color-hover: var(--color);
        --color-focused: var(--color);
        --color-activated: var(--color);
        display: block;
        width: 100%;
        text-align: center;
        font-weight: var(--font-weight);
        line-height: var(--line-height);
        font-size: var(--font-size);
        letter-spacing: 0;

        &::part(inner) {
            justify-content: flex-start;
        }
    }

    a {
        display: block;
        color: var(--color);
        text-decoration: none;

        &:hover {
            color: var(--color-hover);
        }

        &:not(.bazis-item) {
            padding-left: var(--padding-start);
            padding-right: var(--padding-end);
            padding-top: var(--padding-top);
            padding-bottom: var(--padding-bottom);
        }
    }

    // subtype
    &_rounded {
        @extend .bazis-menu !optional;

        bazis-icon {
            border-radius: 50%;
        }
    }
}
