.bazis-dropdown {
    --width: 362px;
    --padding-start: calc(var(--bazis-multiple-px) * 4);
    --padding-end: calc(var(--bazis-multiple-px) * 4);
    --padding-top: calc(var(--bazis-multiple-px) * 5);
    --padding-bottom: calc(var(--bazis-multiple-px) * 5);
    --background: var(--bazis-color-white);
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-background-medium);
    --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
    --top: 48px;
    // --bottom: 0;
    // --left: 0;
    --right: 0;

    bazis-button.bazis-dropdown__trigger,
    &__trigger bazis-button,
    a.bazis-dropdown__trigger,
    &__trigger a {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;

        bazis-icon {
            --bazis-icon-s-size: 16px;
            --bazis-icon-l-size: 24px;
            --bazis-icon-xl-size: 32px;

            &.bazis-icon-l {
                font-size: var(--bazis-icon-l-size);
            }
        }
    }

    .bazis-list_select {
        --margin-start-list: -16px;
        --margin-end-list: -16px;
        --margin-top-list: -20px;
        --margin-bottom-list: -20px;
        --border-radius: 0;
        --box-shadow: none;

        width: calc(100% - var(--margin-start-list) - var(--margin-end-list));
        border: 0;

        .bazis-item {
            --height: 36px;
            --color: var(--bazis-text-color);
            --color-hover: var(--bazis-text-color);
            margin: 0;
        }
    }

    &.sl-dropdown_action,
    bazis-signing & {
        --top: 28px;
        --width: 210px;
        --color: var(--bazis-text-color-secondary);
        float: right;
    }

    .bazis-list_vline & {
        --top: 28px;
        --width: 220px;
        --color: var(--bazis-text-color-secondary);
        margin-left: 20px;
    }

    &.sl-dropdown_notifications {
        --width: 484px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;

        .bazis-dropdown__body {
            // transform: translateX(calc(var(--width) / 2 - 18px));
            display: none;

            &[data-show] {
                display: block;
            }
        }

        .bazis-dropdown__trigger {
            --overflow: visible;
            position: relative;
        }

        bazis-badge {
            --margin-end: 0;
            position: absolute;
            right: -4px;
            top: -4px;
        }        

        @media (max-width: 576px) {
            --width: 100%;
            --border-radius: 0;
            position: static;

            .bazis-dropdown__body {
                top: 8px !important;
            }
        }
    }

    // &.sl-dropdown_tooltip {
    //     bazis-tooltip {
    //         --min-width: 210px;
    //         --padding-top: calc(var(--bazis-multiple-px) * 3);
    //         --padding-bottom: calc(var(--bazis-multiple-px) * 3);
    //         --padding-start: 0;
    //         --padding-end: 0;
    //     }

    //     .bazis-item bazis-button {
    //         display: block;

    //         &::part(inner) {
    //             justify-content: flex-start;
    //         }
    //     }
    // }

    &.sl-dropdown_settings-filter {
        --top: 36px;
        --left: 0;
        --width: 295px;
        --padding-top: calc(var(--bazis-multiple-px) * 3);
        --padding-bottom: calc(var(--bazis-multiple-px) * 3);

        bazis-button.bazis-dropdown__trigger {
            --background: var(--bazis-color-info-tint);
            --color: var(--bazis-color-base);
        }

        .bazis-control__label {
            margin-bottom: 12px;

            & + .bazis-radio {
                margin-top: 0;
                margin-bottom: calc(var(--bazis-multiple-px) * 4);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @media (max-width: 992px) {
            .bazis-dropdown__body {
                --top: auto;
                --left: 8px;
                --right: 8px;
                --bottom: 8px;
                --width: calc(100% - 16px);
                position: fixed;
                z-index: 1001;

                hr {
                    display: none;

                    + bazis-button {
                        --height: 44px;
                        display: block;
                    }
                }
            }
        }
    }

    .bazis-btn-group .sl-select-date-control .bazis-dropdown__body {
        --top: 40px;
    }
}
