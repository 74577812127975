.bazis-cookie {
    position: fixed;
    z-index: 1001;
    bottom: 20px;
    left: 0;
    right: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    .bazis-block {
        --max-width: 1360px;
        --margin-bottom: 0;
        --padding-top: 10px;
        --padding-start: 16px;
        --padding-end: 16px;
        --padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        bazis-icon {
            margin-right: 12px;
            flex-shrink: 0;
        }

        a {
            font-weight: 500;
            letter-spacing: 0.25px;
            text-decoration: none;
        }

        > div {
            justify-content: center;
            width: 100%;
            padding: 6px 0;
        }
    }

    @media (max-width: 992px) {
        .bazis-block > div {
            justify-content: flex-start;
            padding-right: 72px;
        }
    }

    @media (max-width: 576px) {
        .bazis-block {
            display: block;

            > div {
                padding-right: 0;
            }
        }

        bazis-button {
            margin-top: 16px;
            margin-left: 32px;
        }
    }
}
