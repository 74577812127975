.bazis-vehicle-number {
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-text-color);
    --border-radius: var(--bazis-border-radius);
    --background: var(--bazis-background-accent);
    --box-shadow: 0px 0.272727px 1.63636px rgba(0, 0, 0, 0.2);
    --color: var(--bazis-text-color);
    outline: 1px solid var(--bazis-color-white);

    &--disabled {
        --border-color: var(--bazis-border-color);
        --background: var(--bazis-background-medium);
        --color: var(--bazis-text-color-secondary);
    }

    &_rus {
        --height: 24px;

        .bazis-vehicle-number__part {
            &_0 {
                --margin-start: 6px;
                --margin-end: 2px;
                --font-size: 19px;
                --line-height: 12px;
                width: 11px;
            }
            &_1 {
                --margin-end: 0x;
                --font-size: 22px;
                --line-height: 12px;
                letter-spacing: 1px;
                width: 37px;
            }
            &_2 {
                --margin-end: 0;
                --font-size: 19px;
                --line-height: 12px;
                letter-spacing: 3px;
                width: 24px;
            }
            &_3 {
                --margin-end: 2px;
                --margin-start: 2px;
                --font-size: 11px;
                --line-height: 20px;
                position: relative;
                width: 20px;
                height: 100%;
                letter-spacing: 1.5px;
                padding-left: 2px;

                &::before {
                    position: absolute;
                    top: 1px;
                    left: -3px;
                    display: block;
                    content: '';
                    height: 20px;
                    width: 1px;
                    background: var(--color);
                }

                &::after {
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: 2px;
                    content: 'RUS';
                    background: url('/assets/images/flag-ru.svg') no-repeat 100% 50%;
                    background-size: 7px 5px;
                    font-family: 'PT Sans Caption', sans-serif;
                    font-weight: 700;
                    font-size: 4.91px;
                    line-height: 6.35px;
                    letter-spacing: 0;
                    color: var(--bazis-text-color);
                }
            }

            @media only screen and ( -webkit-min-device-pixel-ratio: 2 ){
                &_0 {
                    --line-height: 10px;
                }
                &_1 {
                    --line-height: 12px;
                }
                &_2 {
                    --line-height: 10px;
                }
            }
        }
        .bazis-vehicle-number__noparts {
            margin: 0 10px;
            font-family: 'PT Sans Caption', sans-serif;
            font-size: 6px;
            line-height: 22px;
            white-space: nowrap;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }
        &.bazis-vehicle-number--disabled {
            .bazis-vehicle-number__part_3 {
                &::before {
                    background: var(--bazis-border-color);
                }
                &::after {
                    background-image: url('/assets/images/flag-ru-grey.svg');
                }
            }
        }
    }
}
