.sl-page {
    @include props-padding;
    @include padding;
}

.sl-page {
    --padding-bottom: 120px;

    &-header {
        border-bottom: 1px solid var(--bazis-border-color);
        margin-bottom: 32px;

        &.bh-sticky {
            background: var(--bazis-background);
        }

        &--scrolling.bh-sticky {
            border-bottom: 0;
            z-index: 160;
            overflow: hidden; // для обрезки after, удлинения бордера

            h1 {
                font-size: 20px;
                font-weight: 400;
                line-height: normal;
            }

            bazis-toolbar {
                & + .bazis-list_short-info {
                    --margin-bottom-list: 12px; // 18 -> 12
                }
            }

            // компенсация высоты 36
            &::after {
                content: '';
                display: block;
                border-top: 1px solid var(--bazis-border-color);
                height: 36px;
                margin: 0 -9999px;
            }
        }

        bazis-toolbar {
            + .bazis-list_short-info {
                --margin-top-list: var(--bazis-margin-3x);
                --margin-bottom-list: var(--bazis-margin-3x);
            }
            .bh-line-elements {
                margin-left: 32px;
                > * {
                    margin-right: 32px;
                    margin-left: var(--margin-end);

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        bazis-tabs {
            position: relative;
            bottom: -1px;
        }

        bazis-button {
            bazis-icon.bazis-icon-medium {
                margin-left: -8px;
                margin-right: -8px;
                font-size: 20px;
            }
        }

        .bazis-dropdown bazis-button.bazis-dropdown__trigger bazis-icon.bazis-icon-s {
            --bazis-icon-s-size: 20px;
            margin-left: -8px;
            margin-right: -8px;
        }
    }

    @media (min-width: 1024.98px) {
        &-header {
            bazis-toolbar {
                h1 {
                    margin: 0;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        &-header {
            bazis-toolbar + .bazis-list_short-info {
                --margin-top-list: 0;
            }
        }
    }

    @media (max-width: 576px) {
        --padding-bottom: 88px;

        &-header {
            bazis-toolbar {
                flex-wrap: wrap;

                &::part(start),
                &::part(end) {
                    width: 100%;
                }

                .bh-line-elements[slot='end'] {
                    justify-content: space-between;
                }

                + .bazis-list_short-info {
                    --margin-top-list: var(--bazis-margin-6x);
                    overflow-y: auto;

                    > .bazis-item {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
