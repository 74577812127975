@import "./src/bazis/styles/bazis";
@import "core/typography";
@import "core/structure";

$path: '/assets';

/* Leaflet */
@import "leaflet/main";
@import "leaflet/popup";
@import "leaflet/tooltip";
@import "leaflet/oldie";


/* Layout Components of alphabet */
@import 'layout-components/aside';
@import 'layout-components/footer';
@import 'layout-components/header';
@import 'layout-components/layout';
@import 'layout-components/section';

/* Bazis determination and extends */
@import 'bazis-components/bazis';

/* Base Components of alphabet */
@import 'base-components/balance';
@import 'base-components/agreement';
@import 'base-components/card-mobile';
@import 'base-components/contacts-group';
@import 'base-components/e-sign';
@import 'base-components/scheduler';
@import 'base-components/section-sign';
@import 'base-components/tabs.cmp';
@import 'base-components/other';
@import 'base-components/paginator';

/* Form Components */
@import 'form-components/form.base';

/* Table Components */
@import 'table-components/table.base';
@import 'table-components/grid.table';
@import 'table-components/table-twice';

/* Filters Components */
@import 'filter-components/filter';
@import 'filter-components/control.base';
@import 'filter-components/control.additional';

/* Trip Components */
@import 'trip/trip-tape';
@import 'trip/trip-ticket';
@import 'trip/trip';

/* MAP */
@import 'map-components/map';

/* Pages */
@import 'pages/page';
@import 'pages/dummy-page';
@import 'pages/entrance-page';
@import 'pages/legal-page';
@import 'pages/list-page';
@import 'pages/main-page';
@import 'pages/profile-page';
@import 'pages/service-page';

/* Blocks */
@import 'blocks/faq';
@import 'blocks/notifications';
@import 'blocks/other';
@import 'blocks/sitemap';