.leaflet-tooltip {
    /**
        * @prop --background: Фон тултипа
        * @prop --color: цвет текста тултипа
        *
        * @prop --border-radius: радиус скругления тултипа
        * @prop --box-shadow: тень тултипа
        *
        * @prop --padding-start: начальный внутренний отступ тултипа
        * @prop --padding-end: конечный внутренний отступ тултипа
        * @prop --padding-top: верхний внутреннний отступ тултипа
        * @prop --padding-bottom: нижний внутренниц отступ тултипа
        *
        * @prop --min-height: минимальная высота тултипа
        * @prop --min-width: минимальная ширина тултипа
        * @prop --width: ширина тултипа
        * @prop --max-width: максимальная ширина тултипа
        *
        * @prop --font-size: размер шрифта тултипа
        * @prop --font-weight: жирность шрифта тултипа
        * @prop --line-height: межстрочный интервал шрифта тултипа
        *
        * @prop --height-arrow: высота стрелки тултипа
        * @prop --width-arrow: ширина стрелки тултипа
        */
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --border-radius: initial;
    --border-width: initial;
    --border-style: initial;
    --border-color: initial;
    --box-shadow: initial;
    --background: initial;
    --min-height: initial;
    --max-height: initial;
    --min-width: initial;
    --width: initial;
    --max-width: initial;
    --color: inherit;
    --font-size: inherit;
    --font-weight: inherit;
    --line-height: initial;

    --height-arrow: initial;
    --width-arrow: initial;
    --border-color-arrow: var(--background);

    position: absolute; // to prevent tooltip jumps
    min-height: var(--min-height);
    max-height: var(--max-height);
    min-width: var(--min-width);
    max-width: var(--max-width);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    color: var(--color);

    &__content {
        position: absolute;
        bottom: 0;
        display: block;
        box-sizing: border-box;
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        border-width: var(--border-width);
        border-style: var(--border-style);
        border-color: var(--border-color);
        background: var(--background);
        box-shadow: var(--box-shadow);
        border-radius: var(--border-radius);
        width: var(--width);
    }

    &::after {
        content: '';
        position: absolute;
        bottom: calc(var(--height-arrow) / -1);
        border-left: calc(var(--width-arrow) / 2) solid transparent;
        border-right: calc(var(--width-arrow) / 2) solid transparent;
        border-top: var(--height-arrow) solid var(--border-color-arrow);
        left: 50%;
        transform: translateX(-50%);
    }
}

// implemented
.leaflet-tooltip {
    --padding-start: var(--bazis-padding);
    --padding-end: var(--bazis-padding);
    --padding-top: var(--bazis-padding);
    --padding-bottom: var(--bazis-padding);
    --border-radius: var(--bazis-border-radius);
    --background: var(--bazis-text-color);
    --min-height: 40px;
    --min-width: 100px;
    --max-width: 312px;
    --color: var(--bazis-text-color-contrast);
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 16px;

    --height-arrow: 4px;
    --width-arrow: 12px;

    &_telemetry {
        --padding-start: 12px;
        --padding-end: 12px;
        --padding-top: 4px;
        --padding-bottom: 4px;
        --border-radius: var(--bazis-border-radius) var(--bazis-border-radius) var(--bazis-border-radius) 0;
        --min-height: 24px;
        --width: max-content;

        &::after {
            border-left: 0;
            border-right: var(--width-arrow) solid transparent;
            border-top: var(--height-arrow) solid var(--border-color-arrow);
            left: 0;
            transform: translateX(0);
        }
    }

    &_default {
        padding-left: var(--padding-start);
        padding-right: var(--padding-end);
        padding-top: var(--padding-top);
        padding-bottom: var(--padding-bottom);
        background: var(--background);
        box-shadow: var(--box-shadow);
        border-radius: var(--border-radius);
    }
}
