dl {
    &.bazis-dl_inline {
        --width-dt: 216px;
        --width-dd: calc(100% - var(--width-dt));
        --padding-end-dt: var(--bazis-padding-4x);
        --margin-bottom-dt: var(--bazis-margin-4x);
        --margin-bottom-dd: var(--bazis-margin-4x);
        --color-dt: var(--bazis-text-color-secondary);
        line-height: 20px;
        font-size: 13px;

        .bazis-modal & {
            --margin-bottom-dt: var(--bazis-margin-4x);
            --margin-bottom-dd: var(--bazis-margin-4x);
        }

        dd {
            .bazis-list_between > .bazis-item {
                --margin-bottom-dd: 0;
            }
        }

        .sl-section &:last-child {
            dt:last-of-type,
            dd:last-of-type {
                --margin-bottom-dt: 0;
                --margin-bottom-dd: 0;
            }
        }

        dt,
        dd {
            &:last-of-type {
                margin: 0;
            }
        }

        &--lg {
            --margin-bottom-dt: var(--bazis-margin-4x);
            --margin-bottom-dd: var(--bazis-margin-4x); 
        }
    }

    &.bazis-dl_dot-leaders {
        --width-dt: 260px;
        --width-dd: calc(100% - var(--width-dt));
        --padding-start-dd: calc(var(--bazis-multiple-px) * 6);
        --margin-bottom-dt: calc(var(--bazis-multiple-px) * 4);
        --margin-bottom-dd: calc(var(--bazis-multiple-px) * 4);
        --color-dt: rgba(var(--bazis-text-color-rgb), 0.6);
        --color-dot: var(--bazis-text-color-secondary);
        --margin-bottom-dl: 0;

        dt:last-of-type,
        dd:last-of-type {
            --margin-bottom-dt: 0;
            --margin-bottom-dd: 0;
        }

        .bazis-list_short-info {
            --color: var(--bazis-text-color);

            bazis-link {
                font-weight: 400;
            }
        }

        .sl-section-sign & {
            --width-dt: 35%;
            --margin-top-dl: var(--bazis-margin-3x);
            --padding-start-dd: var(--bazis-margin-3x);

            dd {
                font-size: 14px;
            }
        }
    }

    &.bazis-dl_easy {
        --margin-bottom-dt: var(--bazis-margin-2x);
        --margin-bottom-dd: var(--bazis-margin-8x);
        line-height: 20px;

        dt {
            font-size: 13px;
        }
        dd:last-child {
            margin-bottom: 0;
        }

        .sl-card-mobile & {
            --margin-bottom-dd: var(--bazis-margin-4x);
            line-height: 16px;

            dt {
                font-weight: 500;
                color: var(--bazis-text-color-secondary);
            }
        }
    }
    @media (max-width: 1024px) {
        h6 + &.bazis-dl_inline {
            --margin-top-dl: var(--bazis-margin-4x);
        }
    }

    @media (max-width: 576px) {
        &.bazis-dl_inline {
            --margin-bottom-dt: 8px;

            dt,
            dd {
                float: none;
                clear: none;
                width: 100%;
            }

            dd:last-child {
                margin-bottom: 0;
            }
        }
        &.bazis-dl_dot-leaders_nowrap {
            dt {
                white-space: normal;

                &::after {
                    display: none;
                }
            }

            .sl-section-sign & {
                --padding-start-dd: 0;
            }
        }
    }
}
