.sl-trip-tape {
    .bazis-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bazis-status {
            --margin-bottom: 4px;
        }

        > * {
            &:first-child {
                max-width: 180px;
                width: 100%;
            }
        }
    }

    table {
        --margin-bottom: 0;

        & + .bazis-item {
            --border-width: 0;
        }
    }

    .bazis-dl_inline {
        margin-right: calc(var(--bazis-multiple-px) * 4);
    }

    &__price {
        position: relative;
        padding-right: calc(var(--bazis-multiple-px) * 4);
        cursor: pointer;

        bazis-icon {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -4px;
            font-size: 8px;
        }
    }

    &__segment { // переписать имплементацию, вынесено из блока
        @include props-block-model;
        @include props-margin;
        @include props-width;
        @include props-colors;

        --background-hover: var(--background);
        --border-color-hover: var(--border-color);
        --box-shadow-hover: var(--box-shadow);

        @include block-model;
        @include margin;
        @include width;
        @include colors;

        --padding-top: 12px;
        --padding-start: 12px;
        --padding-end: 12px;
        --padding-bottom: 12px;
        --margin-bottom: 0;
        --border-width: 1px;
        --border-style: solid;
        --border-color: transparent;

        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            --border-color: var(--bazis-background-dark);
        }

        > bazis-button {
            margin-left: calc(var(--bazis-multiple-px) * 2);
        }

        bazis-trip-line {
            --width: 301px;
        }

        &.sl-trip-tape__segment--active {
            --background: var(--bazis-background-dark);
        }
    }

    &.bazis-block {
        .bazis-map-wrapper {
            --height: 400px;
            --margin-top: var(--bazis-margin);
        }
    }
}
