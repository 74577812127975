.bazis-message-plane {
    --padding-top: calc(var(--bazis-multiple-px) * 3);
    --padding-bottom: calc(var(--bazis-multiple-px) * 3);
    --background: var(--bazis-color-base, var(--bazis-white-color));
    --color: var(--bazis-color-contrast, var(--bazis-text-color));
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 20px;

    &.bazis-color-warning {
        --color: var(--bazis-text-color);

        .bazis-message-plane__close {
            --color: var(--bazis-text-color);
            --background-hover: rgba(var(--bazis-color-white-rgb), 0.1);
            --background-activated: rgba(var(--bazis-color-tint), 0.1);
            --background-focused: rgba(var(--bazis-color-white-rgb), 0.1);
        }
    }
}
