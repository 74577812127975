.bazis-list {
    --list-style: none;
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 20px;

    // types
    &_h {
        > .bazis-item {
            --margin-end: calc(var(--bazis-multiple-px) * 3);
        }
    }

    &_short-info {
        > .bazis-item {
            --padding-start: calc(var(--bazis-multiple-px) * 3);
            --padding-end: calc(var(--bazis-multiple-px) * 3);
            --margin-end: 0;
            --font-size: 12px;
            --color: var(--bazis-text-color-secondary);
        }

        bazis-infinite-list & {
            display: inline-block;

            > .bazis-item {
                min-height: initial;
                display: inline;
            }
        }
    }

    &_vline {
        --padding-start: calc(var(--bazis-multiple-px) * 5);
        --border-color: var(--bazis-border-color);
        --line-height: 16px;

        .bazis-checkbox__label,
        .bazis-radio__label {
            margin-right: 0;
        }

        &.bazis-list_h > .bazis-item {
            --margin-end: 0;
        }

        &_mini {
            @extend .bazis-list_vline;
            --padding-start: calc(var(--bazis-multiple-px) * 3);
        }

        .sl-toolbar_table-header & {
            align-items: center;
        }
    }

    &_select {
        --padding-start: 12px;
        --padding-end: 12px;
        --padding-top: calc(var(--bazis-multiple-px) * 2);
        --padding-bottom: calc(var(--bazis-multiple-px) * 2);
        --background-hover: var(--bazis-background-dark);
        --background-selected: var(--bazis-color-action);
        --color-selected: var(--bazis-color-action-contrast);

        --max-height: 208px;
        --background-list: var(--bazis-color-white);
    }

    &_control-dd {
        --max-height: 208px;
        --border-radius-list: var(--bazis-border-radius);
        --border-width-list: 1px;
        --border-style-list: solid;
        --border-color-list: var(--bazis-background-medium);
        --padding-top-list: 12px;
        --padding-bottom-list: 12px;
        --margin-top-list: 3px;
        --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);

        &-wrapper {
            --height: 36px;
            --padding-bottom: calc(var(--bazis-multiple-px) * 2);
        }

        .bazis-dropdown & {
            bazis-button.bazis-item {
                --background: transparent;
                --background-hover: var(--bazis-background-dark);
                --padding-start: 12px;
                --padding-end: 12px;
                --padding-top: 8px;
                --padding-bottom: 8px;
                text-align: left;

                &::part(inner) {
                    --padding-start: 0;
                    --padding-end: 0;
                    justify-content: flex-start;
                }

                &:active:hover {
                    &::part(inner) {
                        background: var(--background-selected);
                        color: var(--color-selected);
                    }
                }
            }
        }
    }

    &_gallery {
        > .bazis-item {
            --margin-end: var(--bazis-multiple-px);
        }

        .bazis-figure {
            --width: 271px;

            &_medium {
                --width: 604px;
            }
        }
    }

    &_between {
        > .bazis-item {
            --margin-bottom: var(--bazis-margin-4x);
        }

        &_dot-leaders {
            > .bazis-item {
                --margin-top: 0;
                --margin-bottom: calc(var(--bazis-multiple-px) * 5);

                &:last-child {
                    --margin-bottom: 0;
                }
            }
            --color-dot: var(--bazis-text-color-secondary);
        }
    }

    &_underline,
    &_overline {
        --margin-bottom: 0;
        --border-radius: 0;
    }

    &_checkable {
        --margin-bottom: 16px;

        .bazis-list .bazis-item {
            --margin-bottom: 8px;
        }

        .bazis-item_parent {
            & > bazis-step {
                --font-weight: 500;

                &::part(sign) {
                    visibility: hidden;
                }
            }

            &.bazis-item_invalid > bazis-step {
                --color: var(--color-muted);
            }
        }
    }

    &_history {
        --width-bullet: 8px;

        > .bazis-item {
            --margin-bottom: var(--bazis-margin-8x);
            --padding-start: calc(var(--bazis-padding-4x) + var(--width-bullet) + 2px);

            app-browse-default {
                width: 156px;
                margin-right: calc(var(--bazis-multiple-px) * 8);

                &:last-child {
                    margin-right: 0;
                }
            }
            &::before {
                top: 4.5px;
            }

            &::after {
                top: 14.5px;
                left: 4.5px;
            }

            &:last-child {
                --margin-bottom: 0;

                &::after {
                    display: block;
                    bottom: 0;
                }
            }
        }
    }
}

.sl-list {
    &_easy {
        > .bazis-item + .bazis-item {
            --padding-top: calc(var(--bazis-multiple-px) * 4);
        }

        > .bazis-item {
            bazis-tooltip & {
                --padding-top: calc(var(--bazis-multiple-px) * 3);
            }
        }
    }

    &_e-sign {
        --margin-bottom: calc(var(--bazis-multiple-px) * 10);
        --margin-top-dl: calc(var(--bazis-multiple-px) * 4);

        .sl-e-sign + div {
            width: 100%;
        }

        textarea {
            border-style: dashed;
            border-color: var(--bazis-placeholder-color);
            border-radius: var(--bazis-border-radius);

            resize: vertical;
            width: 100%;
        }
    }

    &_trip {
        @extend .bazis-list_history;
        --margin-top-list: 12px;

        > .bazis-item {
            --padding-start: calc(var(--width-bullet) + var(--bazis-margin-4x) + 1px);
            --margin-bottom: var(--bazis-margin-5x);

            &::before {
                top: 4.5px;
                background: var(--bazis-color-base, var(--bazis-placeholder-color));
            }

            &::after {
                left: 4.5px;
                top: 14.5px;
            }
            &:last-child {
                --margin-bottom: 0;

                &::before {
                    border-color: var(--bazis-color-base, var(--bazis-placeholder-color));
                }
                &::after {
                    display: none;
                }
            }
        }
    }

    &_vtabs {
        --color: var(--bazis-text-color);
        --font-size: 13px;
        --font-weight: 400;
        --line-height: 20px;
        --padding-start: calc(var(--bazis-multiple-px) * 4 - 2px);
        --padding-end: calc(var(--bazis-multiple-px) * 5);
        --padding-top: calc(var(--bazis-multiple-px) * 2);
        --padding-bottom: calc(var(--bazis-multiple-px) * 2);
        --border-width: 0 0 0 2px;
        --border-color: transparent;
        --border-style: solid;
        --border-radius: 0;

        aside & {
            --margin-start-list: calc(var(--bazis-multiple-px) * -4);
            --margin-top-list: calc(var(--bazis-multiple-px) * 2);
        }

        aside.sl-aside_left & {
            --margin-start-list: calc(var(--bazis-multiple-px) * -5);
        }

        .bazis-item {
            cursor: pointer;

            &--active {
                --font-weight: 500;
                --border-color: var(--bazis-text-color);
            }
        }
    }
}
