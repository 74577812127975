.bazis-slider {
    --indicator-gap: 4px;
    --indicator-margin-top: 0;
    --indicator-margin-bottom: 0;
    --indicator-dot-width: 4px;
    --indicator-dot-height: 4px;
    --indicator-radius: 50%;
    --indicator-bg: initial;
    --indicator-color: initial;

    --indicator-dot-width-active: var(--indicator-dot-width);
    --indicator-dot-height-active: var(--indicator-dot-height);
    --indicator-radius-active: var(--indicator-radius);
    --indicator-bg-active: var(--indicator-bg);
    --indicator-color-active: var(--indicator-color);

    &__wrapper {
        overflow: hidden;
    }

    &__content {
        display: flex;
        align-items: stretch;
        will-change: transform;
        // transition: margin-left 300ms;
        transition: transform 300ms;

        > * {
            flex-shrink: 0;
        }
    }
    &__controls {
        margin-top: 32px;
        text-align: center;

        bazis-button {
            margin: 0 4px;
        }
    }

    &__indicator {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--indicator-gap);
        margin: var(--indicator-margin-top) auto var(--indicator-margin-bottom);

        &__item {
            width: var(--indicator-dot-width);
            height: var(--indicator-dot-height);
            border-radius: var(--indicator-radius);
            background: var(--indicator-bg);
            color: var(--indicator-color);

            &--active {
                width: var(--indicator-dot-width-active);
                height: var(--indicator-dot-height-active);
                border-radius: var(--indicator-radius-active);
                background: var(--indicator-bg-active);
                color: var(--indicator-color-active);
            }
        }
    }
}
