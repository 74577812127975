.sl-number-buttons-control {
    .bazis-control__field-group {
        --size-icon: 12px;

        bazis-button {
            --height: 24px;
            --border-radius: 50%;
            --padding-start: 11px;
            --padding-end: 11px;

            ::slotted(bazis-icon[slot=icon-only]) {
                font-size: var(--size-icon);
            }
        }
    }

    &__data {
        margin: 0 calc(var(--bazis-multiple-px) * 3);
        white-space: nowrap;
    }

    .bazis-control__note {
        .sl-grid_table & {
            padding-top: 6px;
        }
    }
}
