.bazis-icon-wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-left: 8px;
    border-radius: 50%;
    background: var(--bazis-color-action);
    text-align: center;
    color: var(--bazis-color-action-contrast);

    bazis-icon {
        --bazis-icon-s-size: 12px;
    }
}
