.sl-service-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 112px;
    padding-bottom: 120px;
    max-width: 664px;
    margin: auto;

    bazis-sign-status {
        margin-bottom: 32px;
    }

    h1 {
        padding-bottom: 0;
        margin-bottom: 16px;
        text-align: center;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        + bazis-button {
            margin-top: 32px;
        }

        a {
            text-decoration: none;
        }
    }

    .sl-code-status {
        font-weight: 500;
        font-size: 250px;
        line-height: 270px;

        text-align: center;
        letter-spacing: 0.02em;
        color: var(--bazis-background-dark);

        @media (max-width: 576px) {
            font-size: 150px;
            line-height: 170px;
        }
    }

    .bazis-menu_rounded {
        --margin-top-list: 32px;
        --margin-bottom: 0;
        --border-width: 1px 0 0;
        --border-color: var(--bazis-border-color);
        --border-style: solid;
        --border-radius: 0;
        width: 442px;

        .bazis-item {
            --font-size: 16px;
            --font-weight: 400;

            &:hover {
                --border-color: transparent;
                --border-radius: 4px;
            }
        }

        bazis-button {
            --multiple: 2;
        }
    }
}
