.bazis-figure {
    --height: 168px;
    --width: 210px;
    --background: var(--bazis-color-white);
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --font-size: 13px;
    --font-weight: 500;
    margin-right: calc(var(--bazis-multiple-px) * 2);

    &:last-child {
        margin-right: 0;
    }

    &__close {
        position: absolute;
        top: var(--bazis-multiple-px);
        right: var(--bazis-multiple-px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin: 0;
        outline: 0;
        border-radius: 50%;
        background: rgba(var(--bazis-color-white-rgb), .5);
        padding: 0;
        bazis-icon {
            --bazis-color-base: var(--bazis-link-color-dark) !important;
        }
    }

    .bh-ellipsis {
        max-width: calc(100% - 32px);
    }

    .bazis-percentage-bar {
        --height: 4px;
        width: 178px;
    }

    .bazis-list_gallery & {
        --border-radius: none;
    }

    .bazis-control & {
        margin-top: calc(var(--bazis-multiple-px) * 3);
    }

    // types
    &_btn {
        --border-width: 1px;
        --border-style: dashed;
        --border-color: var(--bazis-color-action);

        &.bazis-color {
            border-color: var(--bazis-link-color-dark);
            color: var(--bazis-link-color-dark);
        }
    }
    &_long {
        margin-bottom: calc(var(--bazis-multiple-px) * 8);
    }
}
