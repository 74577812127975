.sl-section-sign {
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }

    > bazis-icon {
        float: left;
        margin-right: var(--bazis-margin-8x);

        + * {
            overflow: hidden;
        }
    }

    .bazis-modal__content & {
        padding-left: 0;
        padding-bottom: calc(var(--bazis-multiple-px) * 6);

        > bazis-icon {
            float: left;
            margin-right: calc(var(--bazis-multiple-px) * 5);
        }

        p {
            margin-top: 0;
            margin-bottom: 2px;
            font-weight: 500;

            + bazis-link,
            + a {
                font-weight: 400;
            }
        }
    }

    @media (max-width: 1024px) {
        margin-bottom: 24px;

        > bazis-icon {
            float: none;
            margin-right: 0;
            margin-bottom: var(--bazis-margin-5x);

            + * {
                overflow: initial;
            }
        }
    }
}
