.bh-text-overline {
    text-decoration: overline !important;
}

.bh-text-underline {
    text-decoration: underline !important;
}

.bh-text-decornone {
    text-decoration: none !important;
}

.bh-text-dotted {
    border-bottom: 1px dotted currentColor !important;
}

.bh-text-dashed {
    border-bottom: 1px dashed currentColor !important;
}

.bh-text-line-through {
    text-decoration: line-through !important;
}


@media (min-width: 576px) {
    .bh-text-sm-overline {
        text-decoration: overline !important;
    }

    .bh-text-sm-underline {
        text-decoration: underline !important;
    }

    .bh-text-sm-decornone {
        text-decoration: none !important;
    }

    .bh-text-sm-dotted {
        border-bottom: 1px dotted currentColor !important;
    }

    .bh-text-sm-dashed {
        border-bottom: 1px dashed currentColor !important;
    }

    .bh-text-sm-line-through {
        text-decoration: line-through !important;
    }
}
@media (min-width: 768px) {
    .bh-text-md-overline {
        text-decoration: overline !important;
    }

    .bh-text-md-underline {
        text-decoration: underline !important;
    }

    .bh-text-md-decornone {
        text-decoration: none !important;
    }

    .bh-text-md-dotted {
        border-bottom: 1px dotted currentColor !important;
    }

    .bh-text-md-dashed {
        border-bottom: 1px dashed currentColor !important;
    }

    .bh-text-md-line-through {
        text-decoration: line-through !important;
    }
}
@media (min-width: 992px) {
    .bh-text-lg-overline {
        text-decoration: overline !important;
    }

    .bh-text-lg-underline {
        text-decoration: underline !important;
    }

    .bh-text-lg-decornone {
        text-decoration: none !important;
    }

    .bh-text-lg-dotted {
        border-bottom: 1px dotted currentColor !important;
    }

    .bh-text-lg-dashed {
        border-bottom: 1px dashed currentColor !important;
    }

    .bh-text-lg-line-through {
        text-decoration: line-through !important;
    }
}
@media (min-width: 1200px) {
    .bh-text-xl-overline {
        text-decoration: overline !important;
    }

    .bh-text-xl-underline {
        text-decoration: underline !important;
    }

    .bh-text-xl-decornone {
        text-decoration: none !important;
    }

    .bh-text-xl-dotted {
        border-bottom: 1px dotted currentColor !important;
    }

    .bh-text-xl-dashed {
        border-bottom: 1px dashed currentColor !important;
    }

    .bh-text-xl-line-through {
        text-decoration: line-through !important;
    }
}
