bazis-datepicker {
    --widthOne: 360px;
    --background: var(--bazis-color-white);
    --background-hover: var(--bazis-background-accent);
    --background-selected: var(--bazis-color-action);
    --background-current: var(--bazis-color-primary);
    --background-muted: var(--bazis-background-medium);
    --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
    --datepicker-arrow-color: var(--bazis-color-action);

    --padding-start: 16px;
    --padding-end: 16px;
    --padding-top: 16px;
    --padding-bottom: 16px;

    --font-weight: 500;
    --font-size: 12px;
    --color: var(--bazis-text-color);
    --color-selected: var(--bazis-text-color-contrast);
    --color-current: var(--bazis-text-color-contrast);

    --width-day: 32px;
    --height-day: 32px;
    --border-radius-day: 2px;
    --opacity-day: 0.8;
    --font-weight-day: 500;

    --font-size-weekday: 12px;
    --color-weekday: var(--bazis-text-color-secondary);

    --font-size-head: 12;
    --font-weight-head: var(--font-weight-day);
    --color-head: var(--bazis-color-action);

    &::part(button) {
        display: block;
        --height: 24px;
        --padding-top: 0;
        --padding-bottom: 0;
        // вынести в стили проекта фон и цвет точно
        --background-hover: var(--bazis-background-medium);
        --background-activated: var(--bazis-color-info-tint);
        --background-focused: var(--bazis-background-medium);

        --color: var(--bazis-text-color-secondary);
        --color-hover: var(--bazis-color-action);
        --color-focused: var(--bazis-color-action);
    }

    &::part(value) {
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    &::part(interval-head-button) {
        --height: 48px;
        --border-color: var(--bazis-color-action);
        --color: var(--bazis-color-action);
        top: 50%;
        margin-top: calc(var(--height) / -2);
        width: 48px;

        &:first-child {
            left: calc(-20px - var(--height) / 2);
        }

        &:last-child {
            right: calc(-20px - var(--height) / 2);
        }
    }

    &::part(head-button){
        --padding-start: 6px;
        --padding-end: 6px;
    }

    &::part(list) {
        overflow: auto;
        max-height: 264px;
    }

    &::part(list-item) {
        padding: 2px 12px;
        font-weight: 400;
        font-size: var(--font-size);
        line-height: 20px;
        text-transform: lowercase;
    }

    &::part(calendar-head) {
        margin-bottom: var(--bazis-margin-4x);
        padding: 0;
    }
}
