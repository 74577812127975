.bazis-accordion_doc {
    .bazis-file-inline bazis-link {
        font-weight: 400;
    }

    .bazis-dropdown {
        --top: 24px;
        --width: 250px;
    }

    .bh-line-elements {
        --margin-end: var(--bazis-margin-2x);
    }

    .bazis-btn_small {
        bazis-link[slot='icon-only'] {
            margin-left: -4px;
            margin-right: -4px;
        }
        @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
            bazis-link[slot='icon-only'] {
                margin-left: unset;
                margin-right: unset;
                -webkit-margin-start: -4px;
                margin-inline-start: -4px;
                -webkit-margin-end: -4px;
                margin-inline-end: -4px;
            }
        }
    }

    bazis-button bazis-icon {
        color: var(--bazis-text-color-secondary);
    }
}
