.sl-profile-page {
    padding-top: var(--bazis-padding-8x);

    @media (max-width: 1024px) {
        .sl-page {
            --padding-bottom: var(--bazis-padding-6x);

            h1 {
                margin-top: 0;
            }
        }

        &__footer {
            margin-top: 72px;
            padding: var(--bazis-padding-3x) var(--bazis-padding-5x) var(--bazis-padding-5x);
            box-shadow: 0px -15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
            border-top: 1px solid var(--bazis-border-color);
        }
    }
}
