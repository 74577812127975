.sl-entrance-page {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;

    .bazis-btn-group {
        width: 100%;
        margin-top: 32px;

        &__item {
            width: 50%;

            // возможно перенесем в файл bazis-btn-group, если везде будет шрифт 13
            > bazis-button.bazis-btn_solid {
                font-size: 13px;
            }
        }
    }

    .bazis-block {
        --margin-top: 32px;
        --margin-bottom: 32px;
        --margin-start: auto;
        --margin-end: auto;
        --padding-top: 40px;
        --padding-bottom: 40px;
        --width: 394px;
        max-width: 100%;

        &__header {
            --margin-bottom: 16px;
        }

        &_shadow {
            --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        }

        @media (max-width: 576px) {
            --margin-top: 0;
            --margin-bottom: 0;
            --padding-start: 24px;
            --padding-end: 24px;
            --width: 100%;
            --border-color: transparent;
            --box-shadow: none;

            h4 {
                text-align: left;
            }

            bazis-button {
                --height: 44px;
            }
        }
    }

    .bazis-menu_rounded {
        .bazis-item {
            --padding-top: calc(var(--bazis-multiple-px) * var(--multiple));
            --padding-bottom: calc(var(--bazis-multiple-px) * var(--multiple));
            --padding-start: calc(var(--bazis-multiple-px) * var(--multiple));
            --padding-end: calc(var(--bazis-multiple-px) * var(--multiple));
            --font-weight: 400;

            cursor: pointer;
            width: 100%;

            &--active,
            &--active bazis-icon {
                background: var(--background-hover);
            }

            &--disabled {
                pointer-events: none;
            }
        }
    }

    .bazis-control {
        margin-top: 16px;

        &__label {
            letter-spacing: 0.5px;
        }

        &.sl-symbol-control {
            margin-bottom: 24px;
        }
    }

    app-input-symbol + .error {
        position: relative;
        top: -20px;
    }

    header,
    footer {
        border-style: solid;
        border-color: var(--bazis-border-color);
        text-align: center;
    }

    header {
        border-width: 0 0 1px 0;
        background: #f6f6fe;

        > div {
            position: relative;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .sl-header__faq {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        .sl-header__back {
            display: block;
            margin-top: 24px;
            text-decoration: none;
            text-align: left;

            bazis-icon {
                padding: 10px;
                border-radius: 50%;
                background: var(--bazis-background-medium);
                margin-right: 16px;
            }
        }

        @media (max-width: 992px) {
            > div {
                justify-content: space-between;
            }

            .sl-header__faq {
                position: static;
                margin-top: 0;
            }

            + div {
                min-height: calc(100vh - 169px);
            }
        }

        @media (max-width: 576px) {
            border-width: 0;
            padding: 0 24px;

            + div {
                height: auto;
            }
        }
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 112px;
        border-width: 1px 0 0 0;
        background: rgba(var(--bazis-background-rgb), 0.2);

        .bazis-list {
            justify-content: center;

            &.bazis-list_short-info .bazis-item {
                --font-size: 13px;
                --color: var(--bazis-text-color-secondary);
            }

            a {
                text-decoration: none;
                color: var(--bazis-text-color-secondary);

                &:hover {
                    color: var(--bazis-link-color);
                }
            }
        }

        @media (max-width: 576px) {
            background: var(--bazis-background-medium);
            justify-content: flex-start;
            padding: 0 24px;
            text-align: left;

            .bazis-list {
                flex-direction: column;
                align-items: flex-start;

                &.bazis-list_short-info .bazis-item {
                    --padding-start: 0;
                    --margin-bottom: 12px;

                    &:last-child {
                        --margin-bottom: 0;
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    p:not(.bazis-control p, app-display-error p) {
        margin-bottom: 32px;
    }

    h4,
    p,
    bazis-button {
        width: 100%;
    }

    hr {
        margin: 24px auto 16px;
        background: var(--bazis-border-color);
    }

    fieldset {
        margin-top: 12px;
        border: 0;
        border-top: 1px solid var(--bazis-border-color);
    }

    legend {
        padding: 0 8px;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        color: var(--bazis-text-color-secondary);
    }

    form {
        .sl-forgotten-pass {
            font-weight: 400;
            line-height: 20px;
        }
    }

    bazis-sign-status {
        margin: 0 auto 12px;
    }
}
