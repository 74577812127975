app-map,
app-static-map {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

// implemented
.bazis-map-wrapper {
    --height: 336px;

    app-input-map-point & {
        margin-bottom: 24px;
    }

    &.sl-map-wrapper_small {
        --height: 260px;
        --margin-bottom: 32px;
    }
}

.bazis-map-circle {
    margin-left: var(--bazis-margin-8x);
}

.sl-map-wrapper_dd {
    @extend .bazis-map-wrapper;
    --height: 100px;
    --width: 332px;
    --margin-top: var(--bazis-margin-2x);
    border: 1px solid var(--bazis-border-color);
    border-radius: var(--bazis-border-radius);
}

@media (max-width: 1024px) {
    .bazis-map-circle {
        margin-left: var(--bazis-margin-6x);
    }
}
