.sl-vehicle-number-control {
    .bazis-control__field {
        --height: auto;
        flex-shrink: 0;
        padding: 0;

        &-group {
            align-items: flex-end;
            width: 162px;
        }
    }

    input {
        outline: none;
        border: none;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        background: transparent;
        text-align: center;

        &.ng-dirty + .sl-vehicle-number-control__placeholder {
            opacity: 0;
        }
    }

    &__placeholder {
        position: absolute;
        background: transparent;
        pointer-events: none;
        
        &::placeholder {
            text-align: center;
            color: var(--bazis-placeholder-color);
        }
    }

    &_rus {
        --height: 24px;
        .sl-vehicle-number-control__part {
            @for $i from 0 through 3 {
                &_#{$i} {
                    @include props-margin;
                    @include props-font;

                    @include margin;
                    @include font;
                }
            }
        }
        .sl-vehicle-number-control__part {
            &_0 {
                --margin-start: 6px;
                --margin-end: 0;
                --margin-bottom: 2px;
                --font-size: 18px;
                --line-height: 15px;
                width: 17px;
            }
            &_1 {
                --margin-end: 0;
                --margin-bottom: 0;
                --font-size: 27px;
                --height: 32px;
                --line-height: 17px;
                width: 60px;

                input {
                    letter-spacing: 4px;
                }

                @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                    --margin-bottom: -1px;
                }
            }
            &_2 {
                --margin-start: 0;
                --margin-end: 0;
                --margin-bottom: 2px;
                --font-size: 19px;
                --line-height: 15px;
                width: 24px;

                input {
                    letter-spacing: 3px;
                }
            }
            &_3 {
                --margin-end: 7px;
                --margin-start: 16px;
                --margin-bottom: 2px;
                --font-size: 15px;
                --line-height: 17px;
                height: 32px;
                width: 36px;
                align-self: flex-start;

                &::before {
                    content: '';
                    display: block;
                    width: 1px;
                    position: relative;
                    top: 3px;
                    left: -5px;
                    height: 30px;
                    background: var(--bazis-border-color-dark);
                }

                &::after {
                    content: 'RUS';
                    position: absolute;
                    left: 3px;
                    right: 3px;
                    bottom: 0;
                    background: url('/assets/images/flag-ru.svg') no-repeat 100% 50%;
                    background-size: 11px 8px;
                    font-family: 'PT Sans Caption', sans-serif;
                    font-size: 7.36px;
                    font-weight: 700;
                    line-height: 6.35px;
                    letter-spacing: 0;
                    color: var(--bazis-text-color);
                }

                input {
                    letter-spacing: 3px;
                    margin-bottom: 6px;
                }
            }

            @media only screen and ( -webkit-min-device-pixel-ratio: 2 ){
                &_0 {
                    --line-height: 10px;
                }
                &_1 {
                    --line-height: 12px;
                }
                &_2 {
                    --line-height: 10px;
                }
            }
        }
    }
}
