.sl-agreement {
    margin-right: var(--bazis-margin-8x);
    text-align: right;
    color: var(--bazis-text-color-secondary);

    @media (max-width: 1024px) {
        max-width: 290px;
    }
    @media (max-width: 576px) {
        margin: 16px auto 0;
        text-align: center;
    }
}
