.sl-notification-block {
    color: var(--bazis-text-color);

    &__body {
        max-height: 376px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    header {
        padding: 12px 0 8px;

        bazis-toolbar {
            --padding-start: calc(var(--bazis-multiple-px) * 4);
            --padding-end: calc(var(--bazis-multiple-px) * 4);

            @media (max-width: 576px) {
                .bh-line-elements {
                    --margin-end: 12px;
                }
            }
        }
    }

    hr {
        --margin-top: 0;
        --margin-bottom: 0;
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
    }
}

.sl-notification-item {
    display: block;
    background: var(--bazis-background);
    border-bottom: 1px solid var(--bazis-border-color);
    padding: calc(var(--bazis-multiple-px) * 2) calc(var(--bazis-multiple-px) * 4);

    .sl-announcement & {
        padding: 20px 0;

        &:first-child {
            border-top: 1px solid var(--bazis-border-color);
        }
    }

    &:last-of-type {
        border-bottom: 0;

        .sl-announcement & {
            border-bottom: 1px solid var(--bazis-border-color);
        }
    }

    &__checkbox {
        margin-right: calc(var(--bazis-multiple-px) * 4);

        .sl-notification-block & {
            display: none;
        }
    }

    &__major-line {
        display: flex;
        align-items: center;

        .sl-announcement & {
            align-items: flex-start;
        }

        bazis-button {
            &:hover {
                color: var(--bazis-color-action);
            }
        }

        & + * {
            padding-left: calc(var(--bazis-multiple-px) * 7);
            padding-top: 6px;
            padding-bottom: calc(var(--bazis-multiple-px) * 2);

            .sl-announcement & {
                padding-left: 32px;
                padding-top: 12px;
                padding-bottom: 0;
            }
        }
    }

    &__action {
        flex-shrink: 0;
        width: 32px;
    }

    &__type {
        --bazis-icon-s-size: 16px;
        margin-right: calc(var(--bazis-multiple-px) * 3);
        flex-shrink: 0;
    }

    &__message {
        position: relative;
        display: flex;
        width: 100%;
        font-size: 13px;
        line-height: 16px;

        a {
            color: var(--bazis-color-action);
        }

        &__content {
            font-size: 13px;
            line-height: 16px;

            p {
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .sl-more-mute {
            max-height: 48px;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                top: 28px;
                height: 20px;
                background: linear-gradient(0deg, rgba(var(--bazis-color-white-rgb), 1), rgba(var(--bazis-color-white-rgb), 0));
            }
        }
    }

    &__datetime {
        padding-left: calc(var(--bazis-multiple-px) * 3);
        padding-right: calc(var(--bazis-multiple-px) * 2);
        font-size: 11px;
        line-height: 12px;
        text-align: right;
        color: var(--bazis-text-color-secondary);
        white-space: nowrap;

        .sl-announcement & {
            font-size: 13px;
            line-height: 24px;
        }

        @media (max-width: 576px) {
            padding-left: 0;
            padding-top: 4px;
            text-align: left;
        }
    }

    &--active {
        background: var(--bazis-background-medium);
    }
}
