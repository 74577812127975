.bazis-contact {
    --font-size: 13px;
    --line-height: 20px;

    &__name {
        margin: 0 0 4px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
    }
    &__position {
        margin: 0 0 12px;
    }
    &__mail {
        margin: 0 0 4px;
    }
    &__phone {
        margin: 0;
    }

    & + & {
        --margin-top: var(--bazis-margin-4x);
    }

    + hr {
        --margin-top: var(--bazis-margin-5x);
        --margin-bottom: var(--bazis-margin-5x);
    }
}
