.bazis-card {
    --padding-top: 12px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-bottom: 36px;
    --background: var(--bazis-background);
    --border-radius: var(--bazis-border-radius);
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-color-hover: var(--bazis-color-primary);

    // так как карточка располагается внутри флекс контейнера, и имеет в дочерних канву
    // (канва не дружит с флекс контейнером вычисляя свои размеры)
    // необходимо задать на каком либо уровне обертки фиксированную, а не плавающую ширину.
    // для адаптивного дизайна - необходимо будет, либо отступы делать динамическими,
    // либо для каждой точки задавать свою фиксированную ширину
    --width: 316px;
    height: 100%;
    position: relative;
    line-height: 20px;

    .bazis-map-wrapper {
        --height: 132px;
        --margin-bottom: var(--bazis-margin);
        position: relative;

        &,
        .leaflet-container {
            border-radius: var(--bazis-border-radius);
        }

        .bazis-dropdown {
            position: absolute;
            z-index: 150;
            top: 4px;
            right: 4px;

            &__trigger {
                --height: 28px;
                --background: var(--bazis-color-white);
                --padding-start: 12px;
                --padding-end: 12px;
                --color: var(--bazis-placeholder-color);

                width: 28px;
            }
        }
    }

    .bazis-status {
        --margin-bottom: 8px;
        --margin-top: 8px;
    }
}

a.bazis-card {
    display: block;
    text-decoration: none;
}

.sl-card {
    &__date {
        position: absolute;
        bottom: 8px;
        left: var(--padding-start);
        right: var(--padding-end);
        color: var(--bazis-text-color-secondary);
    }

    &_trip {
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        --border-radius: 0;
        --width: auto;
        --border-color: transparent;
        --border-color-hover: var(--bazis-background-dark);
        --margin-bottom: calc(var(--bazis-multiple-px) * 4);
        height: auto;
        cursor: pointer;

        .bazis-card__header {
            height: 36px;
            padding: 0 var(--bazis-padding);
            background: var(--bazis-background-medium);
            line-height: 36px;
        }

        .bazis-card__body {
            padding: var(--bazis-padding);
        }

        &--selected {
            --border-color: var(--bazis-color-action);
            --border-color-hover: var(--border-color);
        }

        &-wrapper {
            height: 500px;
            overflow: auto;
            padding-right: calc(var(--bazis-multiple-px) * 2);
        }
    }
}
