.bazis-gallery {
    &-preview {
        @include props-width-height;
        @include width-height;

        position: relative;

        &__more {
            /*
            * @prop --opacity: прозрачность кнопки more
            * @prop --border-radius: скругление кнопки more
            */
            --opacity: initial;
            --border-radius: initial;

            @include props-position;
            @include props-width-height;
            @include props-colors;
            @include props-font;
            @include position;
            @include width-height;
            @include colors;
            @include font;

            position: absolute;
            background: rgba(var(--background), var(--opacity));
            border-radius: var(--border-radius);
            text-align: center;
        }
    }

    &-slider {
        @include props-width;
        @include width;

        position: relative;

        > bazis-button {
            --border-radius: 50%;
            position: absolute;
            z-index: 150; // так как галерея содержит карту, и кнопка должна быть над картой.
            top: 50%;
            margin-top: -18px;

            &.bazis-gallery-slider__prev {
                left: -18px;
            }
            &.bazis-gallery-slider__next {
                right: -18px;
            }
        }

        &__content {
            will-change: transform;
            transition: margin-left 300ms;
        }
    }

    &-single {
        @include props-width;
        @include width;

        position: relative;

        > bazis-button {
            position: absolute;
            top: 50%;
            margin-top: -18px;

            &.bazis-gallery-single__prev {
                left: 20px;
            }
            &.bazis-gallery-single__next {
                right: 20px;
            }
        }
    }
}
