.bazis-file-inline {
    --max-height: none;
    --min-height: none;

    display: inline-flex;
    width: 100%;
    justify-content: flex-start;

    // закоменчено с целью позиционирования баджика на уровне текста, при wrap списка ( или длинного имени файла и его переносе на новою строку)
    // возможно не на маке будет не центрироваться по тексту. Необходимо обратить внимание
    // align-items: center;

    bazis-badge {
        --margin-start: var(--bazis-margin-2x);
        --margin-end: var(--bazis-margin-2x);
        margin-top: 2px;
        margin-left: 0;
        border-radius: 2px;
        text-transform: uppercase;
        line-height: 16px;
        flex-shrink: 0;
    }

    .bazis-list {
        // 56px - длина самого длинного баджика (www), при данном размере и семействе шрифта
        width: calc(100% - 56px);
        justify-content: flex-start;

        > .bazis-item {
            white-space: nowrap;

            &:first-child {
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(100% - 1px);
                display: inline-block;

                &::after {
                    display: none;
                }
            }

            &:last-child {
                padding-right: 0;

                &:not(:first-child) {
                    padding-left: var(--padding-end);

                    &::before {
                        content: '';
                        display: inline-block;
                        margin-right: var(--padding-end);
                        margin-bottom: 2px;
                        width: 2px;
                        height: 2px;
                        background: var(--color);
                        border-radius: 50%;
                    }
                }
            }

            bazis-link {
                display: contents;
                font-weight: 400;
            }
        }
    }
}

.bazis-signed-file-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bazis-file-inline {
        overflow: hidden;
        .bazis-list {
            // 56px - ширина самого длинного баджика (www), при данном размере и семействе шрифта
            // 36px - ширина кнопки
            width: calc(100% - 56px - 36px);
        }
    }

    .bazis-file-inline + bazis-button {
        flex-shrink: 0;
        align-self: flex-start;
    }

    .bazis-dropdown {
        --top: 24px;
        --color: var(--bazis-placeholder-color);
    }
}
