.bazis-list-page {
    --background-head: var(--bazis-background-medium);
    --background-filters: var(--bazis-background-medium);
    --background-body: transparent;

    &__head {
        background: var(--background-head);

        .bazis-breadcrumbs {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__title {
        display: flex;
        align-items: center;

        @media (min-width: 1024.98px) {
            h1 {
                margin: 0;

                .os-Windows & {
                    position: relative;
                    top: -6px;
                }
            }
        }
    }
    &__filters {
        background: var(--background-filters);
    }
    &__body {
        background: var(--background-body);
    }

    @media (max-width: 576px) {
        .bazis-list-page__title {
            display: block;
        }
    }
}
