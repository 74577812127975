.sl-filter {
    @include props-padding;
    @include padding;

    &__base {
        @include props-margin;
        @include margin;
        display: flex;
        align-items: center;

        &__wrapper {
            display: flex;
            width: 100%;
        }

        &__control {
            width: 100%;

            &:first-child {
                .bazis-control {
                    &::before {
                        display: none;
                    }
                }
            }

            .bazis-control__field-group__end button {
                border-left: none;
                --size-icon: 24px;
            }
        }

        > bazis-button {
            --multiple: 11;
            --height: 56px;
            --border-radius: 0 var(--bazis-border-radius) var(--bazis-border-radius) 0;
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 1px;
            flex-shrink: 0;
            width: 156px;
        }
    }

    &__additional {
        @include props-padding;
        @include padding;
    }

    @media (max-width: 1024px) {
        .sl-filter__base {
            &,
            .sl-filter__base__wrapper {
                display: block;
            }

            > bazis-button {
                --multiple: 4;
                --height: 44px;
                --border-radius: var(--bazis-border-radius);
                margin-right: 32px;
            }
        }
    }
}

// implemented
.sl-filter {
    --padding-top: 16px;
    --padding-bottom: 20px;

    &__base {
        --margin-bottom: 16px;
        background: var(--bazis-background);
        border-radius: var(--bazis-border-radius);
    }

    &__additional {
        --bazis-grid-columns: 15;
        --padding-top: calc(var(--bazis-multiple-px) * 5 - 5px);
    }

    .bazis-list_control-dd-wrapper {
        --padding-bottom: 0;
    }

    @media (max-width: 1024px) {
        --padding-top: 0;

        &__base {
            --margin-bottom: 0;
            background: transparent;
        }
    }

    @media (max-width: 576px) {
        .sl-filter__base {
            + .bh-padding-bottom {
                --bazis-coef: 0 !important;
            }
        }

        .sl-filter__additional {
            --bazis-grid-columns: 5;
            --padding-top: 0;
            --padding-bottom: 20px;
        }

        .sl-filter-apply {
            --height: 44px;
            display: block;
        }
    }
}
