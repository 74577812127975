.bazis-percentage-block {
    --padding-top: 8px;
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-bottom: 6px;
    --margin-bottom: 12px;
    --border-radius: var(--bazis-border-radius);
    --background: var(--bazis-background);
    --background-hover: var(--bazis-background-medium);
    --border-color-hover: var(--bazis-border-color);

    &__count {
        --font-size: 18px;
        --line-height: 24px;
        color: var(--bazis-color-action);
    }

    &__percent {
        color: var(--bazis-text-color-secondary);
    }

    bazis-tooltip {
        z-index: 149;
    }

    &--active {
        --background: var(--bazis-color-action);
        --border-color: var(--bazis-color-action);
        --color: var(--bazis-color-primary-contrast);
        --background-hover: var(--background);
        --border-color-hover: var(--border-color);

        .bazis-percentage-block__count {
            --font-weight: 700;
            color: var(--color);
        }

        .bazis-percentage-block__percent {
            color: rgba(var(--bazis-color-primary-contrast-rgb), 0.7);
        }
    }

    &:last-child {
        --margin-bottom: 0;
    }

    &_medium {
        @extend .bazis-percentage-block;
        --padding-top: 10px;
        --padding-bottom: 10px;
    }
}
