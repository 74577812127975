.bazis-coordinates {
    dl &,
    .bazis-list > .bazis-item &,
    bazis-grid.sl-grid_table &,
    table & {
        margin-bottom: 0;
    }

    dl & {
        margin-top: var(--bazis-margin-1x);
    }
}
