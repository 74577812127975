.bazis-tag {
    /**
    * @prop --background: фон тега
    * @prop --background-hover: фон тега при наведении
    * @prop --background-disabled: фон тега при отключенной теге
    *
    * @prop --color: цвет текста тега
    * @prop --color-hover: цвет текста тега при наведении
    * @prop --color-disabled: цвет текста тега при отключенной теге
    *
    * @prop --transition: переход
    *
    * @prop --border-radius: радиус скругления
    * @prop --border-width: толщина границы
    * @prop --border-style: стиль границы
    * @prop --border-color: цвет границы
    * @prop --border-color-hover: цвет границы при наведении
    * @prop --border-color-disabled: цвет границы при отключенной кнопке
    *
    * @prop --box-shadow: тень тега
    * @prop --opacity: прозрачность тега
    *
    * @prop --padding-top: внутренний верхний отступ
    * @prop --padding-end: внутренний правый отступ
    * @prop --padding-bottom: внутренний нижний отступ
    * @prop --padding-start: внутренний левый отступ
    *
    * @prop --multiple: число увеличения кратности, необходимо для расчета изменения размеров тега.
    *
    * @prop --height: высота тега
    * @prop --width: ширина тега
    */
    @include props-block-model;
    @include props-width-height;
    @include props-colors;

    @include block-model;
    @include width-height;
    @include colors;
    --height-s: var(--height);
    --height-l: var(--height);

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--bazis-multiple-px);
    opacity: var(--opacity);

    font-size: 13px;
    font-weight: 500;

    button {
        outline: none;
        background: transparent;
        border: none;
        padding: 4px;

        bazis-icon {
            color: var(--bazis-text-color-secondary);
        }
    }

    &.bazis-color {
        --background: var(--bazis-color-base);
        --color: var(--bazis-color-contrast);

        bazis-icon {
            color: var(--color);
        }
    }

    &s-group {
    }

    // types
    &_small {
        height: var(--height-s);
    }
    &_large {
        height: var(--height-l);
    }
}
