.bazis-radio {
    /**
     * @prop --background: фон radio
     * @prop --background-checked: фон при установленном radio
     * @prop --background-disabled: фон при отключенном radio
     * @prop --disabled-opacity: прозрачность чекбокса при отключенном radio
     *
     * @prop --color: цвет метки radio
     * @prop --color-checked: цвет метки при установленном radio
     * @prop --color-disabled: цвет метки при отключенном radio
     *
     * @prop --transition: переход
     *
     * @prop --border-radius: радиус скругления
     * @prop --border-width: толщина границы
     * @prop --border-style: стиль границы
     * @prop --border-color: цвет границы
     * @prop --border-color-checked: цвет границы при установленном radio
     * @prop --border-width-checked: толщина границы при установленном radio
     * @prop --border-color-disabled: цвет границы при отключенном radio
     *
     * @prop --size: Размер значка radio.
     */

    --background-checked: var(--bazis-color-primary);
    --border-color-checked: var(--bazis-color-primary);
    --color-checked: var(--color);
    --color-disabled: var(--color);
    --transition: none;
    position: relative;
    user-select: none;
    z-index: 2;

    &.bazis-color {
        --background-checked: var(--bazis-color-base);
        --border-color-checked: var(--bazis-color-base);
    }

    label {
        position: relative;
    }

    &__label {
        position: relative;
        display: flex;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        border: 0;
        background: transparent;
        cursor: pointer;
        appearance: none;
        outline: none;
        align-items: flex-start;

        &::-moz-focus-inner {
            border: 0;
        }

        &::before {
            content: '';
            flex-shrink: 0;
            border-radius: var(--border-radius);
            position: relative;
            width: var(--size);
            height: var(--size);
            margin-left: calc(var(--size) / 2);
            margin-right: calc(var(--size) / 2);
            transition: var(--transition);
            border-width: var(--border-width);
            border-style: var(--border-style);
            border-color: var(--border-color);
            background: var(--background);
            box-sizing: border-box;
        }

        &.bazis-label-hidden {
            margin-right: 0;
        }
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        clip: rect(0 0 0 0);
        opacity: 0;
        overflow: hidden;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:checked + .bazis-radio__label {
            color: var(--color-checked);

            &::before {
                border-color: var(--border-color-checked);
                border-width: var(--border-width-checked);
                background: var(--background-checked);
            }
        }

        &:disabled + .bazis-radio__label {
            pointer-events: none;
            color: var(--color-disabled);

            &::before {
                opacity: var(--disabled-opacity);
            }
        }
    }

    // types
    &_right {
        direction: rtl;
        text-align: left;

        .bazis-radio__label {
            justify-content: flex-end;
        }
    }

    &_tab {
        width: 100%;

        .bazis-radio__label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        input {
            &:checked + .bazis-radio__label {
                background: var(--border-color-checked);
                color: var(--background-checked);
            }
        }

        &:first-child .bazis-radio__label {
            border-radius: var(--bazis-border-radius) 0 0 var(--bazis-border-radius);
        }
        &:last-child .bazis-radio__label {
            border-radius: 0 var(--bazis-border-radius) var(--bazis-border-radius) 0;
        }
    }

    &_block {
        .bazis-radio__label {
            display: block;

            &::before {
                display: block;
                float: left;
            }
        }
    }
}
