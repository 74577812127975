.sl-main-page {
    --header-height: 56px;
    --bazis-background-medium: #f7f7f7;
    background-color: var(--bazis-background-medium);

    .sl-section {
        --margin-top: 0;

        &__content {
            --padding-start: 40px;
            --padding-end: 40px;

            @media (max-width: 1024px) {
                --padding-start: 0;
                --padding-end: 0;
            }

            @media (max-width: 576px) {

                h2 {
                    --bazis-font-size-h2: 24px;
                    --bazis-line-height-h2: 32px;
                }
            }
        }

        // 1 section
        &_banner {
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                height: 90px;
                background: linear-gradient(
                    180deg,
                    #08101c 0%,
                    #08151b 0.01%,
                    rgba(8, 33, 59, 0) 100%
                );
            }

            .bazis-container {
                --dark: #091231;
                --light: #122246;
                --dark-right: #14264a;
                --light-right: #1c3259;
                position: relative;
                background: radial-gradient(
                        50% 100% at 34% bottom,
                        var(--light),
                        var(--dark),
                        transparent
                    ),
                    radial-gradient(
                        50% 655px at bottom right,
                        var(--light-right),
                        var(--dark-right),
                        #091231
                    );

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    right: 100%;
                    background: linear-gradient(15deg, #07151a, #08171e);
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    left: calc(100% - 1px);
                    background: linear-gradient(-15deg, #072240, #072240 274px, #062443);
                }
            }

            video,
            .sl-video-poster {
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: calc(50% - 120px);
                transform: translateX(-50%);
                right: auto;
                width: auto;
                height: 100%;
                max-width: initial;
            }

            .sl-section__content {
                --padding-top: 144px;
                --padding-bottom: 150px;
                --padding-start: 0;

                position: relative;
                z-index: 1;
                max-width: 620px; // 50% - 10px
                color: var(--bazis-text-color-contrast);

                h1 {
                    margin-bottom: 40px;
                    padding-bottom: 0;

                    + p {
                        font-size: 18px;
                        line-height: 28px;
                        margin-bottom: 40px;
                        opacity: 0.7;
                    }
                }
            }

            .sl-updates {
                --margin-bottom-list: 40px;
                align-items: baseline;

                .bazis-item:last-child {
                    display: inline-flex;
                    align-items: baseline;

                    &::before {
                        position: relative;
                        top: -2px;
                    }

                    span {
                        position: relative;
                        top: 1px;
                    }
                }
            }

            bazis-button {
                --height: 48px;
                font-size: 16px;
            }

            @media (max-width: 1280px) and (min-width: 1025px) {
                video,
                .sl-video-poster {
                    left: calc(50% - 250px);
                }
            }

            @media (max-width: 1024px) {
                video,
                .sl-video-poster {
                    display: none;
                }

                .sl-section__content {
                    --padding-top: 128px;
                    --padding-bottom: 80px;
                }
                h1 {
                    font-weight: 400;
                    --bazis-font-size-h1: 45px;
                    --bazis-line-height-h1: 52px;
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-top: 104px;
                    --padding-bottom: 72px;
                    width: 100%;
                }
                .sl-updates {
                    display: block;

                    .bazis-item {
                        --padding-top: 8px;
                        --padding-start: 0;

                        &::after {
                            display: none;
                        }

                        span {
                            top: 0;
                        }
                    }
                }
                h1 {
                    margin-top: 0;
                    --bazis-font-size-h1: 28px;
                    --bazis-line-height-h1: 36px;
                }
                bazis-button {
                    display: block;
                }
            }
        }

        // 2.2 section
        &_about {
            .sl-section__content {
                --padding-top: 104px;
                text-align: center;

                .bazis-block {
                    --margin-bottom: 0;
                    --padding-top: 40px;
                    --padding-bottom: 40px;
                    --border-radius: 12px;
                    height: 100%;

                    .bazis-h6 a {
                        color: currentColor;
                    }
                }

                h2 {
                    padding-bottom: 43px;
                    --bazis-font-size-h2: 40px;
                }

                figure {
                    width: 144px;
                    margin: 0 auto 32px;

                    img {
                        border-radius: 50%;
                    }
                }

                .sl-grid_card {
                    margin: 0 calc(var(--bazis-multiple-px) * -3);

                    > bazis-row > bazis-col {
                        --bazis-grid-column-padding-start: calc(var(--bazis-multiple-px) * 3);
                        --bazis-grid-column-padding-end: calc(var(--bazis-multiple-px) * 3);
                    }
                }
            }

            @media (max-width: 1024px) {
                .sl-section__content {
                    --padding-top: 88px;
                    text-align: left;

                    h2 {
                        text-align: center;
                        padding-bottom: 40px;

                        br {
                            display: none;
                        }
                    }

                    .bazis-block {
                        --padding-top: 32px;
                        --padding-bottom: 32px;
                        --padding-start: 32px;
                        --padding-end: 32px;
                        --margin-end: 16px;
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: space-between;
                        width: 336px;
                        height: auto;

                        &:last-child() {
                            margin-right: 0;
                        }
                    }

                    figure {
                        width: 120px;
                        margin: 16px 0 0;
                        align-self: flex-end;
                    }
                }
            }

            @media (max-width: 576px) {
                .sl-grid_card {
                    display: block !important;
                }

                .sl-section__content {
                    h2 {
                        text-align: left;
                        --bazis-font-size-h2: 32px;

                        br {
                            display: block;
                        }
                    }
                    .bazis-block {
                        width: 100%;
                    }
                }
            }
        }

        // 3 section
        &_resolve {
            position: relative;
            overflow: hidden;
            background: url('/assets/images/bg-home-gal.svg') no-repeat calc(50% + 453px) -136px;

            .sl-section__content {
                --padding-top: 179px;
                --padding-bottom: 260px;
                position: relative;
                text-align: center;
            }

            h2 {
                max-width: 950px;
                margin: 0 auto 24px;
                font-weight: 500;
            }

            p {
                --bazis-font-size-p: 18px;
                --bazis-line-height-p: 24px;
                --bazis-p-margin: 0 auto 48px;
                max-width: 720px;
            }

            bazis-button {
                --height: 48px;
                font-size: 16px;
            }

            .sl-imgs-wrap {
                img {
                    position: absolute;
                    object-fit: cover;
                    border-radius: 8px;

                    &.sl-imgs {
                        &__1 {
                            top: 315px;
                            left: 46px;
                            width: 82px;
                            height: 82px;
                            transform: rotate(-30.7deg);
                        }
                        &__2 {
                            top: 135px;
                            left: 40px;
                            width: 50px;
                            height: 50px;
                            transform: rotate(-15.47deg);
                        }
                        &__3 {
                            top: 65px;
                            left: 218px;
                            width: 71px;
                            height: 71px;
                            transform: rotate(-8.58deg);
                        }
                        &__4 {
                            top: 70px;
                            right: 296px;
                            width: 58px;
                            height: 58px;
                            transform: rotate(18.47deg);
                        }
                        &__5 {
                            top: 79px;
                            right: 28px;
                            width: 84px;
                            height: 84px;
                            transform: rotate(27.63deg);
                        }
                        &__6 {
                            top: 290px;
                            right: 67px;
                            width: 53px;
                            height: 53px;
                            transform: rotate(22.62deg);
                        }
                    }
                }
            }

            @media (max-width: 1280px) {
                .sl-section__content {
                    position: static;
                }
            }

            @media (max-width: 1024px) {
                background: transparent;

                .sl-imgs-wrap {
                    img.sl-imgs {
                        &__1 {
                            left: 65px;
                            transform: rotate(20deg);
                        }
                        &__2 {
                            left: 5px;
                        }
                        &__3 {
                            top: 45px;
                        }
                        &__5 {
                            right: -28px;
                        }
                    }
                }

                bazis-button {
                    width: 336px;
                    margin-bottom: 12px;
                    margin-right: 0;

                    &:last-child {
                        --border-width: 1px;
                        --border-style: solid;
                        --border-color: var(--bazis-border-color-dark);
                        margin-bottom: 0;
                    }
                }
            }

            @media (max-width: 940px) {
                h2 {
                    br {
                        display: none;
                    }
                }

                .sl-imgs-wrap {
                    img.sl-imgs {
                        &__1 {
                            left: -34px;
                        }
                        &__2 {
                            left: -28px;
                        }
                        &__5 {
                            right: -47px;
                        }
                        &__6 {
                            right: -24px;
                        }
                    }
                }
            }

            @media (max-width: 660px) {
                .sl-section__content {
                    --padding-top: 344px;
                    --padding-bottom: 72px;
                    text-align: left;
                }

                .sl-imgs-wrap {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;

                    img.sl-imgs {
                        &__1 {
                            top: 208px;
                            left: calc(50% - 88px / 2 - 116px);
                            transform: none;
                        }
                        &__2 {
                            top: 104px;
                            left: calc(50% - 64px / 2 - 156px);
                            transform: none;
                        }
                        &__3 {
                            top: 56px;
                            left: calc(50% - 96px / 2 + 36px);
                            transform: none;
                        }
                        &__4 {
                            top: 184px;
                            left: calc(50% - 48px / 2 - 16px);
                            right: auto;
                            transform: none;
                        }
                        &__5 {
                            top: 192px;
                            left: calc(50% - 72px / 2 + 112px);
                            right: auto;
                            transform: none;
                        }
                        &__6 {
                            top: 112px;
                            left: calc(50% - 56px / 2 + 160px);
                            right: auto;
                            transform: none;
                        }
                    }
                }

                bazis-button {
                    display: block;
                    width: 100%;
                }
            }

            @media (max-width: 576px) {
                background: var(--bazis-color-white);
            }
        }

        // 4 section
        &_calculator {
            --margin-top: -135px;
            position: relative;

            h2 {
                --bazis-font-size-h2: 32px;
                --bazis-line-height-h2: 40px;
                margin-bottom: 28px;
            }

            figure {
                margin: 0 auto var(--bazis-margin-8x);
                width: 320px;
            }

            .sl-section__content {
                .bazis-block {
                    --margin-bottom: 120px;
                    --padding-top: 48px;
                    --padding-start: 48px;
                    --padding-end: 48px;
                    --padding-bottom: 48px;
                    --border-radius: 12px;

                    position: relative;
                    background-color: var(--bazis-color-white);
                    background-image: url('/assets/images/calc-decor-repeat.png');
                    background-repeat: no-repeat;
                    background-position: calc(100% - 48px) 100%;
                    background-size: 466px 70px;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        background: url('/assets/images/calc-decor.png') no-repeat calc(100% - 48px)
                            48px;
                        background-size: 466px auto;
                    }

                    &__content {
                        width: 57%;
                    }
                }

                .sl-datetime-control {
                    margin: 0;
                }

                .sl-form {
                    p:last-child {
                        margin-top: 48px;
                    }

                    &__submit {
                        --height: 52px;
                        max-width: 265px;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0.5px;

                        &.bazis-btn--disabled {
                            bazis-icon {
                                transition-property: transform;
                                animation-name: rotate;
                                animation-duration: 1.2s;
                                animation-iteration-count: infinite;
                                animation-timing-function: linear;
                            }
                        }
                    }

                    &__footer {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0;
                        margin: 0;
                        border: 0;
                        gap: 16px;
                    }
                }

                td {
                    --padding-start: 12px;
                    --padding-end: 12px;
                    --padding-top: 12px;
                    --padding-bottom: 24px;

                    p.bazis-p_major {
                        --bazis-p-margin-major: 6px 0 4px;
                    }

                    label {
                        display: block;
                        margin: 6px 0;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    &:first-child {
                        --padding-start: 0;
                    }
                    &:last-child {
                        --padding-end: 0;
                    }
                }
            }

            .sl-result-price {
                flex-shrink: 0;
                text-align: right;
            }

            .sl-result {
                margin-bottom: 8px;
                font-size: 20px;
                line-height: 22px;
            }

            .sl-fieldset {
                padding: var(--bazis-padding-4x) 0;
                border-bottom: 1px solid var(--bazis-border-color);

                label {
                    display: block;
                    margin-bottom: var(--bazis-margin-3x);
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            @media (max-width: 1380px) {
                .sl-section__content {
                    .bazis-block {
                        background-position: calc(100% - 24px) 100%;

                        &::before {
                            background-position: calc(100% - 24px) 48px;
                        }
                    }
                }
            }

            @media (max-width: 1320px) {
                .sl-section__content {
                    .bazis-block {
                        background-position: 100% 100%;

                        &::before {
                            background-position: 100% 48px;
                        }
                    }
                }
            }

            @media (max-width: 1235px) {
                .sl-section__content {
                    .bazis-block {
                        background-size: 40% 150px;

                        &::before {
                            background-size: 40% auto;
                        }
                    }
                }
            }

            @media (max-width: 1154px) and (min-width: 1024.98px) {
                .sl-section__content {
                    .bazis-block {
                        background-size: 40% 256px;
                    }
                }
            }

            @media (max-width: 1024px) {
                .sl-section__content {
                    .bazis-block {
                        background: var(--bazis-color-white);
                        --padding-start: 88px;
                        --padding-end: 88px;
                        --margin-bottom: 44px;

                        &::before {
                            display: none;
                        }

                        &__content {
                            width: 100%;
                        }
                    }
                    .sl-form p:last-child {
                        margin-top: var(--bazis-margin-6x);
                    }
                }
                h2 {
                    text-align: center;
                    --bazis-h2-margin: 0 0 var(--bazis-margin-2x);
                }

                .bazis-control__label {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }

            @media (max-width: 768px) {
                .sl-section__content {
                    .bazis-block {
                        --padding-start: 40px;
                        --padding-end: 40px;
                    }
                }
            }

            @media (max-width: 576px) {
                --margin-top: 0;

                h2 {
                    text-align: left;
                }

                .sl-section__content {
                    --padding-top: 72px;

                    .bazis-block {
                        --padding-top: 32px;
                        --padding-bottom: 32px;
                        --padding-start: 20px;
                        --padding-end: 20px;
                    }

                    .sl-form__footer {
                        flex-direction: column-reverse;
                        align-items: flex-start;

                        bazis-button {
                            display: block;
                            max-width: 100%;
                        }
                    }
                }

                .sl-result {
                    font-size: 14px;
                    line-height: 20px;

                    .bh-font-medium {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    &-price {
                        margin-bottom: var(--bazis-margin-6x);
                        text-align: left;
                    }
                }
            }
        }

        // 7 section
        &_faq {
            position: relative;

            .sl-section_faq__bg {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;

                background: url('/assets/images/home-faq-bg.jpg') 50% 0 no-repeat,
                    url('/assets/images/home-faq-repeat.jpg') 50% 0 repeat-y,
                    var(--bazis-background-dark);
                background-size: 1440px 813px, 1440px 1px;
            }

            .bazis-container_margin {
                position: relative;
            }

            .sl-section__content {
                --padding-top: 120px;
                --padding-bottom: 120px;
                --padding-start: 0;
                max-width: 650px;
            }

            h2 {
                margin: 0 0 32px;
                --bazis-font-size-h2: 32px;
                --bazis-line-height-h2: 40px;
            }

            bazis-accordion {
                --margin-bottom: calc(var(--bazis-multiple-px) * 3);
                position: relative;

                &::part(header) {
                    --border-radius: 12px;
                    --border-width: 0;
                    --padding-start: calc(var(--bazis-multiple-px) * 6);
                    --padding-end: calc(var(--bazis-multiple-px) * 6);
                    --padding-top: calc(var(--bazis-multiple-px) * 6);
                    --padding-bottom: calc(var(--bazis-multiple-px) * 6);
                    --background: var(--bazis-background);
                    position: relative;
                    font-size: 18px;
                    line-height: 24px;
                }

                &::part(body) {
                    --border-radius: 0 0 12px 12px;
                    --padding-start: calc(var(--bazis-multiple-px) * 6);
                    --padding-end: calc(var(--bazis-multiple-px) * 6);
                    --padding-bottom: calc(var(--bazis-multiple-px) * 6);
                    --padding-top: 6px;
                    --background: var(--bazis-background);
                    --box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
                    position: absolute;
                    top: calc(100% - 9px);
                    left: 0;
                    right: 0;
                    max-height: 285px;
                    overflow: auto;
                    font-size: 14px;
                    line-height: 20px;
                }

                &.bazis-accordion--opened {
                    z-index: 151;
                    border-radius: 12px;
                    background: var(--bazis-background);

                    &::part(header) {
                        --color: var(--bazis-color-primary);
                        z-index: 1;
                    }
                }
            }

            @media (min-width: 1435px) {
                .sl-section_faq__bg {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        right: calc(100% - (100% - 1360px) / 2 + 40px);
                        background: url('/assets/images/home-faq-repeat-left.jpg') 0 0 repeat-x;
                        background-size: 1px 813px;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        left: calc(100% - (100% - 1360px) / 2 + 40px);
                        background: url('/assets/images/home-faq-repeat-right.jpg') 0 0 repeat-x;
                        background-size: 1px 813px;
                    }
                }
            }

            @media (max-width: 1280px) and (min-width: 1025px) {
                .sl-section_faq__bg {
                    background: url('/assets/images/home-faq-bg.jpg') 50% 0 no-repeat;
                    background-size: cover;
                }
            }

            @media (max-width: 1024px) {
                .sl-section_faq__bg {
                    background: var(--bazis-background-medium);
                }

                .bazis-container_margin {
                    &::before,
                    &::after {
                        display: none;
                    }
                }

                .sl-section__content {
                    --padding-top: 88px;
                    --padding-bottom: 88px;
                    max-width: 100%;
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-top: 64px;
                    --padding-bottom: 64px;
                    --padding-start: 0;
                    --padding-end: 0;
                }
            }
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
