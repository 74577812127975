.bazis-cckeditor {
    --cckeditor-font-size: 14px;
    --cckeditor-line-height: 20px;
    --cckeditor-margin-major: 0 0 var(--bazis-margin-3x);
    --cckeditor-margin-minor: 0 0 var(--bazis-margin-2x);
    --cckeditor-margin-h3: 40px 0 20px;
    --cckeditor-margin-h6: 0 0 16px;

    --bazis-font-size: var(--cckeditor-font-size);
    --bazis-line-height: var(--cckeditor-line-height);

    // для обратной совместимости с SW
    font-size: var(--cckeditor-font-size);
    line-height: var(--cckeditor-line-height);

    b,
    strong {
        font-weight: 500;
    }

    i,
    em {
        font-style: italic;
    }

    ol {
        margin: var(--cckeditor-margin-major);
        padding-left: 20px;

        li {
            margin: 0 0 8px;

            a {
                text-decoration: none;
                font-weight: 500;
                letter-spacing: 0.5px;
            }
        }
    }

    ul {
        margin: var(--cckeditor-margin-major);
        padding-left: 0;
        list-style: none;

        li {
            margin: 0 0 8px;

            a {
                text-decoration: none;
                font-weight: 500;
                letter-spacing: 0.5px;
            }
        }
    }

    p {
        --bazis-font-size-p: var(--cckeditor-font-size);
        --bazis-line-height-p: var(--cckeditor-line-height);
        --bazis-p-margin: var(--cckeditor-margin-major);

        // для обратной совместимости с SW
        margin: var(--cckeditor-margin-major);

        &:last-child {
            margin: 0;
        }
    }

    a {
        text-decoration: underline;
    }

    h3 {
        --bazis-h3-margin: var(--cckeditor-margin-h3);

        // для обратной совместимости с SW
        margin: var(--cckeditor-margin-h3);
    }

    h6 {
        --bazis-h6-margin: var(--cckeditor-margin-h6);

        // для обратной совместимости с SW
        margin: var(--cckeditor-margin-h6);
    }

    table {
        td {
            border: 0;
            padding: 8px;

            p:last-child {
                margin: 0;
            }
        }

        &:not([border]) {
            td:first-child {
                padding-left: 0;
            }
            td:last-child {
                padding-right: 0;
            }
        }

        &[border] {
            border-color: var(--bazis-border-color-dark);

            td {
                border: 1px solid var(--bazis-border-color-dark);
            }
        }
    }
}
