.bazis-percentage-bar {
    --height: 8px;
    --background: var(--bazis-background-dark);
    position: relative;
    margin-top: 8px;

    .bazis-block_secondary & {
        --background: var(--bazis-color-white);
    }

    &__part {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;

        .process & {
            background: var(--bazis-color-success);
        }
        .defuse & {
            background: var(--bazis-color-tertiary);
        }
        .utilize & {
            background: var(--bazis-color-warning);
        }
        .store & {
            background: var(--bazis-color-action);
        }
    }

    &_mini {
        --height: 4px;
        margin-top: 4px;

        .bazis-percentage-block & {
            margin-top: 2px;
            border-radius: 2px;

            .bazis-percentage-bar__part:first-child {
                border-radius: 2px 0 0 2px;
            }
            .bazis-percentage-bar__part_full:first-child {
                border-radius: 2px;
            }
        }

        .bazis-percentage-block--active & {
            --background: rgba(var(--bazis-text-color-contrast-rgb), 0.4);

            .bazis-percentage-bar__part.bazis-color {
                background: var(--bazis-color-contrast);
            }
        }
    }
}

.sl-percentages {
    display: flex;
    justify-content: space-between;

    &__item {
        position: relative;
        width: 100%;
        padding-left: 14px;
        margin-left: 14px;

        &--hidden {
            display: none;
        }

        &:first-child,
        &--hidden:first-child + & {
            padding-left: 0;
            margin-left: 0;
        }

        .bazis-percentage-bar {
            --height: 4px;
            margin-top: 8px;
        }

        &:hover {
            .bazis-tooltip_static {
                display: block;
                top: 38px;
            }
        }
    }

    &_column {
        width: 144px;

        .sl-percentages__item {
            padding: 0;
            margin-left: 0;
        }
    }
}
