.bazis-tab-status {
    @include props-margin;
    @include margin;

    display: inline-flex;
    align-items: flex-end;

    &__item {
        @include props-padding;
        @include props-border;
        --border-color-active: var(--border-color);
        --border-color-hover: var(--border-color);
        --border-color-disabled: var(--border-color);
        --border-color-active-disabled: var(--border-color);

        @include props-colors;
        --background-active: var(--background);
        --color-active: var(--color);
        --background-hover: var(--background);
        --color-hover: var(--color);
        --background-disabled: var(--background);
        --color-disabled: var(--color);
        --background-active-disabled: var(--background);
        --color-active-disabled: var(--color);

        @include padding;
        @include border;
        @include colors;

        margin-right: var(--bazis-multiple-px);
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;

        > * {
            display: inline-block;
            border-bottom: 1px dashed var(--background-active);

            @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                position: relative;
                top: 1px;
            }
        }

        &--active,
        &.active,
        &--disabled,
        &:hover {
            > * {
                border-bottom-color: transparent;
            }
        }

        &--active,
        &.active {
            background: var(--background-active);
            border-color: var(--border-color-active);
            color: var(--color-active);
        }

        &:hover {
            background: var(--background-hover);
            border-color: var(--border-color-hover);
            color: var(--color-hover);
        }

        &--disabled {
            background: var(--background-disabled);
            border-color: var(--border-color-disabled);
            color: var(--color-disabled);
            pointer-events: none;

            &.bazis-tab-status__item--active,
            &.active {
                background: var(--background-active-disabled);
                border-color: var(--border-color-active-disabled);
                color: var(--color-active-disabled);
            }
        }
    }
}
