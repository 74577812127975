.bazis-tag {
    --height: 36px;
    --height-s: calc(var(--height) - var(--bazis-multiple-px));
    --height-l: calc(var(--height) + var(--bazis-multiple-px) * 2);
    --background: var(--bazis-background-medium);
    --border-radius: var(--bazis-border-radius);
    --padding-start: 16px;
    --padding-end: 12px;
    --color: var(--bazis-text-color);

    .bazis-block_accent & {
        --background: var(--bazis-color-white);
    }

    // types
    &_large {
        font-size: 14px;
        letter-spacing: 0.5px;
    }

    .sl-filter & {
        --height: 24px;
        --border-radius: 12px;
        --padding-start: var(--bazis-padding);
        margin-bottom: var(--bazis-margin);
        margin-right: 12px;
        line-height: 2;

        > span {
            @extend .bh-ellipsis;
            max-width: 350px;

            &.sl-text_minor {
                color: rgba(var(--bazis-color-contrast-rgb), 0.7);
            }
        }

        button {
            padding: 0;
            margin-left: 8px;
        }
    }
}
